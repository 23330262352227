import React from "react";
import PlaceholderLoading from "react-placeholder-loading";

const PlaceholderLoadingContent = ({ className }) => (
  <div
    data-testid="placeholder-loading-content"
    className={`${className} d-flex flex-column justify-content-between`}
    style={{ minHeight: 60 }}
  >
    <PlaceholderLoading shape="rect" width={250} height={10} />
    <PlaceholderLoading shape="rect" width={250} height={10} />
    <PlaceholderLoading shape="rect" width={250} height={10} />
    <PlaceholderLoading shape="rect" width={250} height={10} />
  </div>
);

export default PlaceholderLoadingContent;
