import ACTIONS from "../actions/groups.actions";

const groupsReducer = (state, action) => {
  const errors = { ...state.errors };
  switch (action.type) {
    case ACTIONS.UPDATE_GROUP_NAME:
      delete errors[action.payload.id];
      return {
        ...state,
        hasUnsavedChanges: true,
        groups: {
          ...state.groups,
          [action.payload.id]: {
            ...state.groups[action.payload.id],
            name: action.payload.name,
            status: action.payload.status,
          },
        },
        errors,
      };
    case ACTIONS.UPDATE_GROUPS_ORDER:
      return {
        ...state,
        hasUnsavedChanges: true,
        groups: {
          ...state.groups,
          ...action.payload,
        },
      };
    case ACTIONS.ADD_GROUP:
      return {
        ...state,
        hasUnsavedChanges: true,
        groups: {
          ...state.groups,
          [action.payload.id]: action.payload,
        },
      };
    case ACTIONS.DELETE_GROUP:
      delete errors[action.payload.id];
      return {
        ...state,
        hasUnsavedChanges: true,
        groups: action.payload,
        errors,
      };
    case ACTIONS.COPY_GROUP:
      return {
        ...state,
        hasUnsavedChanges: true,
        groups: {
          ...state.groups,
          [action.payload.id]: action.payload,
        },
      };
    default:
      return state;
  }
};

export default groupsReducer;
