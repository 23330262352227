import React, { useState, useEffect, useMemo } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import classnames from "classnames";

import CustomCheckboxWithLabel from "../CustomCheckboxWithLabel";
import Select from "../Select";

const FilterModal = ({
  toggle,
  columnAccessor,
  columnHeader,
  columnValues = [],
  onApply,
  currentFilter, // Receive currentFilter as prop
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [searchText, setSearchText] = useState("");
  const [selectedValues, setSelectedValues] = useState([]);
  const [filteredValues, setFilteredValues] = useState([]);
  const [selectAll, setSelectAll] = useState(true);

  // State for label and value filters
  const [labelCondition, setLabelCondition] = useState("contains");
  const [labelValue, setLabelValue] = useState("");
  const [valueCondition, setValueCondition] = useState("equals");
  const [value, setValue] = useState("");
  const [valueTo, setValueTo] = useState("");

  useEffect(() => {
    if (currentFilter) {
      // Initialize state based on the current filter
      if (currentFilter.mode === "selectItems") {
        setActiveTab("1");
        setSearchText("");
        setFilteredValues(columnValues);
        setSelectedValues(currentFilter.values || []);
        setSelectAll(
          (currentFilter.values || []).length === columnValues.length
        );
      } else if (currentFilter.mode === "labelFilter") {
        setActiveTab("2");
        setLabelCondition(currentFilter.condition || "contains");
        setLabelValue(currentFilter.value || "");
      } else if (currentFilter.mode === "valueFilter") {
        setActiveTab("3");
        setValueCondition(currentFilter.condition || "equals");
        setValue(currentFilter.value || "");
        setValueTo(currentFilter.valueTo || "");
      }
    } else {
      // Initialize state with default values if no current filter
      setActiveTab("1");
      setSearchText("");
      setFilteredValues(columnValues);
      setSelectedValues(columnValues);
      setSelectAll(true);
      setLabelCondition("contains");
      setLabelValue("");
      setValueCondition("equals");
      setValue("");
      setValueTo("");
    }
  }, [columnValues, currentFilter]); // Include currentFilter in dependencies

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleSearch = (e) => {
    const text = e.target.value;
    setSearchText(text);
    const filtered = columnValues.filter((val) =>
      String(val).toLowerCase().includes(text.toLowerCase())
    );
    setFilteredValues(filtered);
  };

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedValues([]);
    } else {
      setSelectedValues(columnValues);
    }
    setSelectAll(!selectAll);
  };

  const toggleSelectValue = (value) => {
    let newSelectedValues;
    if (selectedValues.includes(value)) {
      newSelectedValues = selectedValues.filter((v) => v !== value);
    } else {
      newSelectedValues = [...selectedValues, value];
    }
    setSelectedValues(newSelectedValues);
    setSelectAll(newSelectedValues.length === columnValues.length);
  };

  const handleApply = () => {
    let filterData = null;
    if (activeTab === "1") {
      // Select Items mode
      filterData = {
        mode: "selectItems",
        values: selectedValues,
      };
    } else if (activeTab === "2") {
      // Label Filter mode
      filterData = {
        mode: "labelFilter",
        condition: labelCondition,
        value: labelValue,
      };
    } else if (activeTab === "3") {
      // Value Filter mode
      filterData = {
        mode: "valueFilter",
        condition: valueCondition,
        value: value,
        valueTo: valueTo,
      };
    }
    onApply(columnAccessor, filterData);
    toggle();
  };

  const conditionSelect = useMemo(() => {
    return [
      { label: "Contains", value: "contains" },
      { label: "Does Not Contain", value: "doesNotContain" },
      { label: "Equals", value: "equals" },
      { label: "Not Equal", value: "notEqual" },
      { label: "Starts With", value: "startsWith" },
      { label: "Ends With", value: "endsWith" },
    ];
  }, []);

  const defaultCondition = useMemo(() => {
    return conditionSelect.find((cond) => cond.value === labelCondition);
  }, [labelCondition, conditionSelect]);

  const valueConditionSelect = useMemo(() => {
    return [
      { label: "Equals", value: "equals" },
      { label: "Not Equal", value: "notEqual" },
      { label: "Greater Than", value: "greaterThan" },
      { label: "Less Than", value: "lessThan" },
      { label: "Between", value: "between" },
    ];
  }, []);

  const defaultConditionValue = useMemo(() => {
    return valueConditionSelect.find((cond) => cond.value === valueCondition);
  }, [valueCondition, valueConditionSelect]);

  return (
    <Modal isOpen={true} toggle={toggle}>
      <ModalHeader toggle={toggle}>Filter {columnHeader}</ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames("text-primary", {
                active: activeTab === "1",
              })}
              onClick={() => {
                toggleTab("1");
              }}
            >
              Select Items
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("text-primary", {
                active: activeTab === "2",
              })}
              onClick={() => {
                toggleTab("2");
              }}
            >
              Label Filter
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames("text-primary", {
                active: activeTab === "3",
              })}
              onClick={() => {
                toggleTab("3");
              }}
            >
              Value Filter
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="mt-3">
          {/* Select Items Tab */}
          <TabPane tabId="1">
            <ListGroup className="filter-list small">
              <ListGroupItem className="bg-light d-flex align-items-center justify-content-between">
                <div>
                  <CustomCheckboxWithLabel
                    label="Select All"
                    color="primary"
                    checked={selectAll}
                    onClick={toggleSelectAll}
                    reverse={true}
                  />
                </div>
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0 text-muted me-2">
                    {selectedValues.length} of {columnValues.length} selected
                  </div>
                  <Input
                    type="search"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Search..."
                    style={{ maxWidth: "200px" }}
                  />
                </div>
              </ListGroupItem>
              {filteredValues.map((value, index) => {
                return (
                  <ListGroupItem
                    key={`${value}-${index}`}
                    className="d-flex align-items-center"
                  >
                    <div>
                      <CustomCheckboxWithLabel
                        label={String(value)}
                        color="primary"
                        checked={selectedValues.includes(value)}
                        onClick={() => toggleSelectValue(value)}
                        reverse={true}
                      />
                    </div>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </TabPane>
          {/* Label Filter Tab */}
          <TabPane tabId="2">
            <div className="d-flex">
              <FormGroup className="col-4">
                <Label for="labelCondition" className="small text-muted">
                  Condition
                </Label>
                <Select
                  id="labelCondition"
                  defaultValue={defaultCondition}
                  onChange={(selected) => setLabelCondition(selected.value)}
                  options={conditionSelect}
                />
              </FormGroup>
              <FormGroup className="col-8 ps-2">
                <Label for="labelValue" className="small text-muted">
                  Value
                </Label>
                <Input
                  type="text"
                  id="labelValue"
                  value={labelValue}
                  onChange={(e) => setLabelValue(e.target.value)}
                />
              </FormGroup>
            </div>
          </TabPane>
          {/* Value Filter Tab */}
          <TabPane tabId="3">
            <div className="d-flex">
              <FormGroup className="col-4">
                <Label for="valueCondition" className="small text-muted">
                  Condition
                </Label>
                <Select
                  id="valueCondition"
                  defaultValue={defaultConditionValue}
                  onChange={(selected) => setValueCondition(selected.value)}
                  options={valueConditionSelect}
                />
              </FormGroup>
              <FormGroup className="col-8 ps-2">
                <Label for="value" className="small text-muted">
                  Value
                </Label>
                <Input
                  type="number"
                  id="value"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </FormGroup>
            </div>
            {valueCondition === "between" && (
              <FormGroup>
                <Label for="valueTo" className="small text-muted">
                  And
                </Label>
                <Input
                  type="number"
                  id="valueTo"
                  value={valueTo}
                  onChange={(e) => setValueTo(e.target.value)}
                />
              </FormGroup>
            )}
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleApply}>
          Apply
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FilterModal;
