import React, { useEffect, useMemo, useState } from "react";

import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  ListGroupItem,
  ListGroup,
} from "reactstrap";

import { useCreateUser, useUpdateUser, useGetUsers } from "../api/Users.hooks";
import { useGetEmployees } from "@crewos/employees";
import { useGetAllUserRoles } from "../api/UserRoles.hooks";

import { components, sharedHelper, data } from "@crewos/shared";

const { useAuth } = data;

const { Select, Loader, ConfirmationModal, InformationModal } = components;

const MAX_PAGE_SIZE = Number.MAX_SAFE_INTEGER;

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const UserModal = ({ id, onClose, onSubmit }) => {
  const [authContext] = useAuth();

  const [confirmationModal, setConfirmationModal] = useState();
  const [userData, setUserData] = useState({
    isActive: true,
    isInternal: false,
  });

  const [informationModal, setInformationModal] = useState();
  const {
    data: getUserData,
    isLoading: isLoadingGetUsers,
    get: getUsers,
  } = useGetUsers();

  useEffect(() => {
    if (id) {
      getUsers({ id });
    }
  }, [getUsers, id]);

  useEffect(() => {
    if (getUserData) {
      setUserData(getUserData);
    }
  }, [getUserData, setUserData]);

  const {
    isLoading: isLoadingUpdateUser,
    update: updateUser,
    data: updateUserData,
  } = useUpdateUser();

  const {
    isLoading: isLoadingCreateUser,
    mutate: createUser,
    data: createUserData,
  } = useCreateUser();

  const {
    data: employees,
    isLoading: isLoadingEmployees,
    get: getEmployees,
  } = useGetEmployees();

  const {
    data: userRoles,
    isLoading: isLoadingUserRoles,
    get: getUserRoles,
  } = useGetAllUserRoles();

  useEffect(() => {
    if (updateUserData) {
      sharedHelper.successToast("User saved");
      onSubmit();
    }
  }, [updateUserData, onSubmit]);

  useEffect(() => {
    if (createUserData) {
      sharedHelper.successToast(`User created`);
      onSubmit();
    }
  }, [createUserData, onSubmit]);

  useEffect(() => {
    getEmployees({
      pageSize: MAX_PAGE_SIZE,
    });
  }, [getEmployees]);

  useEffect(() => {
    getUserRoles();
  }, [getUserRoles]);

  const doSubmit = async (e) => {
    e.preventDefault();
    if (id) {
      if (id === authContext.userData.id && !userData.isActive) {
        return sharedHelper.errorToast("You can't deactivate yourself");
      }
      await updateUser(userData);
    } else {
      await createUser(userData);
    }
  };

  const onClearTokens = () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        await updateUser({ ...userData, clearTokens: true });
        setConfirmationModal();
        sharedHelper.successToast("User session terminated");
      },
      onClose: () => {
        setConfirmationModal();
      },
      rawBody: true,
      title: `Terminate user session`,
      body: `<span>The user session will be terminated and will need to relogin.</span><p class="mb-0 mt-2">Are you sure you want to continue?</p>`,
      confirmText: "Terminate",
    });
  };

  const onSendResetDB = (action) => {
    const isReset = action === "appResetDB";
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        await updateUser({ ...userData, [action]: true });
        setConfirmationModal();
        sharedHelper.successToast(`User DB was ${isReset ? "reset" : "sent"}`);
      },
      onClose: () => {
        setConfirmationModal();
      },
      rawBody: true,
      title: `${isReset ? "Reset" : "Send"} User DB`,
      body: `<span>The user DB will be sent by email${
        isReset ? " and then reset" : ""
      }.</span><p class="mb-0 mt-2">Are you sure you want to continue?</p>`,
      confirmText: `${isReset ? "Reset" : "Send"}`,
    });
  };

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  const appUserRoles =
    userRoles
      ?.filter((role) => sharedHelper.isRoleAppUser(role))
      .map((role) => role.id) || [];

  const onPendingSyncItems = () => {
    return setInformationModal({
      title: "Pending Sync Items",
      rawBody: true,
      size: "lg",
      onClose: () => setInformationModal(),
      body: userData.pendingSyncItems.length ? (
        userData.pendingSyncItems.map((item) => (
          <ListGroup className="small">
            <ListGroupItem className="my-2 d-flex justify-content-center align-items-center">
              <code>{JSON.stringify(item)}</code>
            </ListGroupItem>
          </ListGroup>
        ))
      ) : (
        <small className="d-flex justify-content-center align-items-center">
          No pending sync items
        </small>
      ),
    });
  };

  const isLoading = useMemo(
    () => isLoadingGetUsers || isLoadingCreateUser || isLoadingUpdateUser,
    [isLoadingGetUsers, isLoadingCreateUser, isLoadingUpdateUser]
  );

  const roleSelect = useMemo(() => {
    let roles = [];

    userRoles?.forEach((role) => {
      roles.push({ value: role.id, label: role.name });
    });

    if (userData.role && !userData.role.isActive) {
      roles.push({ value: userData.userRoleId, label: userData.role.name });
    }

    return roles;
  }, [userRoles, userData.role, userData.userRoleId]);

  const defaultRole = useMemo(() => {
    return roleSelect.find((role) => role.value === userData.userRoleId);
  }, [roleSelect, userData.userRoleId]);

  const employeeSelect = useMemo(() => {
    let optiones = [];

    employees?.data?.forEach((employee) => {
      optiones.push({
        value: employee.id,
        label: `${employee.firstName} ${employee.lastName}`,
      });
    });

    if (userData.employee && !userData.employee.isActive) {
      optiones.push({
        value: userData.employeeId,
        label: `${userData.employee.firstName} ${userData.employee.lastName}`,
      });
    }

    return optiones;
  }, [employees, userData.employee, userData.employeeId]);

  const defaultEmployee = useMemo(() => {
    return employeeSelect.find(
      (employee) => employee.value === userData.employeeId
    );
  }, [employeeSelect, userData.employeeId]);

  const statusSelect = useMemo(() => {
    return [
      { value: true, label: "Active" },
      { value: false, label: "Inactive" },
    ];
  }, []);

  const defaultStatus = useMemo(() => {
    return statusSelect.find((status) => status.value === userData.isActive);
  }, [userData.isActive, statusSelect]);

  const internalSelect = useMemo(() => {
    return [
      { value: true, label: "Yes" },
      { value: false, label: "No" },
    ];
  }, []);

  const defaultInternal = useMemo(() => {
    return internalSelect.find(
      (internal) => internal.value === userData.isInternal
    );
  }, [userData.isInternal, internalSelect]);

  return informationModal ? (
    <InformationModal {...informationModal} />
  ) : confirmationModal ? (
    <ConfirmationModal {...confirmationModal} />
  ) : (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          {id ? "Edit" : "Create"} User
        </ModalHeader>
        <ModalBody>
          <Row>
            {isLoading ? (
              <Loader size="sm" />
            ) : (
              <Col className="col-12">
                <FormGroup>
                  <Label>
                    <span>First Name</span>
                    <span className="text-danger ms-2">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    disabled={userData.employeeId ? true : false}
                    maxLength="50"
                    type="text"
                    name="name"
                    value={userData.firstName || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        firstName: e.target.value,
                      })
                    }
                    required
                    placeholder="Enter first name..."
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Last Name</span>
                    <span className="text-danger ms-2">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    disabled={userData.employeeId ? true : false}
                    maxLength="50"
                    type="text"
                    name="name"
                    value={userData.lastName || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        lastName: e.target.value,
                      })
                    }
                    required
                    placeholder="Enter last name..."
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Email</span>
                    <span className="text-danger ms-2">*</span>
                  </Label>
                  <input
                    className="form-control-redesign"
                    maxLength="50"
                    type="email"
                    name="email"
                    value={userData.email || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        email: e.target.value.trim(),
                      })
                    }
                    required
                    placeholder="Enter email..."
                  />
                </FormGroup>
                <FormGroup>
                  <Label>
                    <span>Password</span>
                    {!id ? <span className="text-danger ms-2">*</span> : null}
                  </Label>
                  <input
                    className="form-control-redesign"
                    required={!id}
                    autoComplete="new-password"
                    maxLength="50"
                    type="password"
                    name="password"
                    value={userData.password || ""}
                    onChange={(e) =>
                      setUserData({
                        ...userData,
                        password: e.target.value.trim(),
                      })
                    }
                    placeholder="Enter password..."
                  />
                </FormGroup>
                {appUserRoles.includes(userData.userRoleId) ? (
                  <FormGroup>
                    <Label>
                      <span>NFC Token</span>
                    </Label>
                    <input
                      className="form-control-redesign"
                      maxLength="50"
                      type="text"
                      name="NFCToken"
                      value={userData.NFCToken || ""}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          NFCToken: e.target.value,
                        })
                      }
                      placeholder="Enter NFC token..."
                    />
                  </FormGroup>
                ) : null}
                {isLoadingUserRoles ? (
                  <Loader size="sm" />
                ) : (
                  <FormGroup>
                    <Label>
                      <span>Role</span>
                      <span className="text-danger ms-2">*</span>
                    </Label>
                    <Select
                      name="roleSelect"
                      onChange={(selected) =>
                        setUserData({
                          ...userData,
                          userRoleId: selected.value,
                        })
                      }
                      defaultValue={defaultRole}
                      placeholder="Select a role"
                      options={roleSelect}
                      data-testid="role-select"
                      required
                    />
                  </FormGroup>
                )}
                {isLoadingEmployees || isLoadingUserRoles ? (
                  <Loader size="sm" />
                ) : appUserRoles.includes(userData.userRoleId) ? (
                  <FormGroup>
                    <Label>
                      <span>Employee</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <Select
                      name="employeeSelect"
                      data-testid="employee-select"
                      onChange={(selected) => {
                        const employee = employees.data.find(
                          (s) => s.id === selected.value
                        );
                        setUserData({
                          ...userData,
                          employeeId: employee?.id,
                        });
                      }}
                      defaultValue={defaultEmployee}
                      placeholder="Select an employee"
                      options={employeeSelect}
                    />
                  </FormGroup>
                ) : null}
                {id ? (
                  <FormGroup className="mb-0">
                    <Label>
                      <span>Status</span>
                      <span className="text-danger ms-1">*</span>
                    </Label>
                    <Select
                      id="statusSelect"
                      name="statusSelect"
                      onChange={(selected) => {
                        setUserData({
                          ...userData,
                          isActive: selected.value,
                        });
                      }}
                      defaultValue={defaultStatus}
                      options={statusSelect}
                      required
                    />
                  </FormGroup>
                ) : null}
                {isSuperAdmin ? (
                  <FormGroup className="mb-0">
                    <Label>
                      <span>Is Internal</span>
                    </Label>
                    <Select
                      id="internalSelect"
                      name="internalSelect"
                      onChange={(selected) => {
                        setUserData({
                          ...userData,
                          isInternal: selected.value,
                        });
                      }}
                      defaultValue={defaultInternal}
                      options={internalSelect}
                      required
                    />
                  </FormGroup>
                ) : null}
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter className="d-flex flex-column justify-content-center">
          {id && isSuperAdmin ? (
            <div className="d-flex align-items-center justify-content-start mt-n2 w-100">
              <div className="col-4 pr-1 d-none">
                <Button
                  size="sm"
                  color="secondary"
                  className="w-100 min-width-auto"
                  onClick={() => onSendResetDB("appSendDB")}
                >
                  Send APP DB
                </Button>
              </div>
              <div className="col-4 px-1 d-none">
                <Button
                  size="sm"
                  color="secondary"
                  className="w-100 min-width-auto"
                  onClick={() => onSendResetDB("appResetDB")}
                >
                  Reset APP DB
                </Button>
              </div>
              <div className="col-6 px-1">
                <Button
                  size="sm"
                  color="light"
                  className="w-100 min-width-auto"
                  onClick={onPendingSyncItems}
                >
                  Sync Items
                </Button>
              </div>
              <div className="col-6 pl-1">
                <Button
                  className="w-100 min-width-auto"
                  size="sm"
                  color="light"
                  onClick={onClearTokens}
                >
                  Kill Session
                </Button>
              </div>
            </div>
          ) : null}
          <div className="d-flex align-items-center justify-content-between w-100">
            <Button color="secondary" onClick={onClose} className="text-dark">
              Cancel
            </Button>
            <Button color="primary" type="submit">
              Save
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserModal;
