import React, { useState } from "react";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import COLORS from "../assets/colorPalette.json";

const COLOR_GRAY = COLORS[0];

export const getStatusColor = (i, isActive) => ({
  background: COLORS[i + 1]
    ? isActive
      ? COLORS[i + 1].text
      : COLORS[i + 1].background
    : COLOR_GRAY.background,
  color: COLORS[i + 1]
    ? isActive
      ? "white"
      : COLORS[i + 1].text
    : COLOR_GRAY.text,
});

const StatusFilter = ({ statuses, selectedStatus, setSelectedStatus }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const statusIndex = selectedStatus
    ? statuses.findIndex((s) => s.id === selectedStatus.id)
    : -1;

  return statuses.length > 4 ? (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
      <DropdownToggle caret={true} style={getStatusColor(statusIndex, false)}>
        <span className="me-1">
          {selectedStatus ? statuses[statusIndex].name : "Any Status"}
        </span>
      </DropdownToggle>
      <DropdownMenu className={dropdownOpen ? "d-flex flex-column" : null}>
        <DropdownItem
          style={getStatusColor(-1, statusIndex < 0)}
          className="fw-normal mx-2 my-1 w-auto flex-grow-1 border-radius-default"
          onClick={() => setSelectedStatus()}
        >
          Any status
        </DropdownItem>
        {statuses.map((status, i) => {
          const isActive = selectedStatus?.id === status.id;
          return (
            <DropdownItem
              style={getStatusColor(i, isActive)}
              key={status.id}
              className="fw-normal mx-2 my-1 w-auto flex-grow-1 border-radius-default"
              onClick={() => setSelectedStatus(status)}
            >
              {status.name}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  ) : (
    <div className="d-flex gap-3">
      <div
        className="px-3 py-1 rounded border-radius-md cursor-pointer text-truncate"
        style={{
          background: !selectedStatus ? COLOR_GRAY.text : COLOR_GRAY.background,
          color: !selectedStatus ? "white" : COLOR_GRAY.text,
        }}
        onClick={() => setSelectedStatus()}
      >
        All
      </div>
      {statuses.map((status, i) => {
        const isActive = selectedStatus?.id === status.id;
        return (
          <div
            key={status.id}
            className="px-3 py-1 rounded border-radius-md cursor-pointer text-truncate"
            style={getStatusColor(i, isActive)}
            onClick={() => setSelectedStatus(status)}
          >
            {status.name}
          </div>
        );
      })}
    </div>
  );
};

export default StatusFilter;
