import React from "react";

import CurrencyInput from "react-currency-input-field";

const DynamicAttribute = ({ dynamicAttribute, data, setData }) => {
  return dynamicAttribute.type === "TEXT" ? (
    <input
      data-testid={dynamicAttribute.id}
      readOnly={dynamicAttribute.readOnly}
      className="form-control-redesign"
      required={dynamicAttribute.required}
      id={dynamicAttribute.id}
      name={dynamicAttribute.id}
      placeholder={dynamicAttribute.placeholder}
      maxLength={dynamicAttribute.maxLength}
      minLength={dynamicAttribute.minLength || 0}
      value={data.dynamicAttributes[dynamicAttribute.id] || ""}
      type={dynamicAttribute.type}
      onChange={(e) => {
        data.dynamicAttributes[dynamicAttribute.id] = e.target.value;
        setData({
          ...data,
        });
      }}
    />
  ) : dynamicAttribute.type === "NUMBER" ? (
    <input
      data-testid={dynamicAttribute.id}
      readOnly={dynamicAttribute.readOnly}
      className="form-control-redesign"
      required={dynamicAttribute.required}
      id={dynamicAttribute.id}
      name={dynamicAttribute.id}
      placeholder={dynamicAttribute.placeholder}
      max={dynamicAttribute.max}
      min={dynamicAttribute.min || 0}
      value={data.dynamicAttributes[dynamicAttribute.id] || ""}
      step={dynamicAttribute.step}
      type={dynamicAttribute.type}
      onChange={(e) => {
        data.dynamicAttributes[dynamicAttribute.id] = e.target.value;
        setData({
          ...data,
        });
      }}
    />
  ) : dynamicAttribute.type === "CURRENCY" ? (
    <CurrencyInput
      data-testid={dynamicAttribute.id}
      readOnly={dynamicAttribute.readOnly}
      className="form-control-redesign"
      required={dynamicAttribute.required}
      id={dynamicAttribute.id}
      name={dynamicAttribute.id}
      prefix="$"
      decimalsLimit={2}
      max={dynamicAttribute.max}
      allowNegativeValue={dynamicAttribute.allowNegativeValue}
      placeholder={dynamicAttribute.placeholder}
      defaultValue={data.dynamicAttributes[dynamicAttribute.id] || ""}
      onValueChange={(value) => {
        data.dynamicAttributes[dynamicAttribute.id] = parseFloat(value);
        setData({
          ...data,
        });
      }}
    />
  ) : (
    <span>{dynamicAttribute.type} not implemented</span>
  );
};

export default DynamicAttribute;
