import React, { useState } from "react";

import { Row, Card, CardBody, Button, Col } from "reactstrap";

import { data, sharedHelper, components } from "@crewos/shared";

import CrewWorkDayTimes from "./CrewWorkDayTimes";

import { AddWorkTimeModal } from "@crewos/worktimes";

import { AddTravelTimeModal } from "@crewos/traveltimes";

import { useCrewWorkDays } from "../providers/crewWorkDaysProvider";
import { useCrews } from "../providers/crewsProvider";

const { WorkOrderDateSelector } = components;

const { useAuth } = data;

const WorkOrderCrewsTimes = () => {
  const [authContext] = useAuth();
  const [crewsContext] = useCrews();
  const [crewWorkDaysContext] = useCrewWorkDays();

  const [addWorkTimeModal, setAddWorkTimeModal] = useState(false);
  const [addTravelTimeModal, setAddTravelTimeModal] = useState(false);

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 px-0 box-shadow-none mb-3">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2 className="mb-0">Time</h2>
              <div className="d-flex align-items-center">
                {sharedHelper.isPackageEnabled(
                  authContext.userData?.packages,
                  "worktimes"
                ) ? (
                  <Button
                    color="primary"
                    className="ms-2"
                    onClick={() => setAddWorkTimeModal(true)}
                  >
                    Add Time
                  </Button>
                ) : null}
                {sharedHelper.isPackageEnabled(
                  authContext.userData?.packages,
                  "traveltimes"
                ) ? (
                  <Button
                    color="primary"
                    className="ms-2"
                    onClick={() => setAddTravelTimeModal(true)}
                  >
                    Add Travel
                  </Button>
                ) : null}
              </div>
            </div>
            <WorkOrderDateSelector
              crewWorkDays={crewWorkDaysContext.crewWorkDays}
            />
          </CardBody>
        </Card>
        {crewsContext.crews.length ? (
          crewsContext.crews.map((crew) => (
            <CrewWorkDayTimes key={crew.id} crew={crew} />
          ))
        ) : (
          <div className="p-3 small text-muted text-center bg-white border-radius-default">
            Start by creating your first crew
          </div>
        )}
      </Row>
      {addWorkTimeModal ? (
        <AddWorkTimeModal onClose={() => setAddWorkTimeModal(false)} />
      ) : addTravelTimeModal ? (
        <AddTravelTimeModal onClose={() => setAddTravelTimeModal(false)} />
      ) : null}
    </Col>
  );
};

export default WorkOrderCrewsTimes;
