import React from "react";

import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";

const InformationModal = ({
  onClose = null,
  title = "",
  body = "",
  rawBody = false,
  size = "md",
  closeButtonText = "Close",
  footerClass = "",
  bodyClass = "",
}) => {
  return (
    <Modal isOpen={true} onClosed={onClose} size={size}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {title}
      </ModalHeader>
      <ModalBody className={bodyClass}>
        {rawBody ? (
          body
        ) : (
          <div dangerouslySetInnerHTML={{ __html: body }}></div>
        )}
      </ModalBody>
      <ModalFooter className={`justify-content-end ${footerClass}`}>
        <Button color="primary" onClick={onClose}>
          {closeButtonText}
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default InformationModal;
