import React, { useState } from "react";

import FeedbackModal from "../FeedbackModal";
import Icon from "../Icon";
import { useSidebar } from "../../providers/sidebarProvider";
import TooltipItem from "../TooltipItem";

const SidebarFeedback = () => {
  const [sidebarContext] = useSidebar();

  const [feedbackModal, setFeedbackModal] = useState();

  const onFeedback = () => {
    setFeedbackModal(true);
  };

  return (
    <TooltipItem
      id="feedback-tooltip"
      title="Feedback"
      active={!sidebarContext.isOpen}
      position="right"
    >
      <div
        className="sidebar-feedback d-flex justify-content-center align-items-center gap-1 cursor-pointer"
        onClick={onFeedback}
      >
        <Icon name="message-circle" className="text-neutraldarkgray" />
        {sidebarContext.isOpen ? (
          <small className="text-link text-neutraldarkgray">Feedback</small>
        ) : null}
      </div>

      {feedbackModal ? (
        <FeedbackModal onClose={() => setFeedbackModal()} />
      ) : null}
    </TooltipItem>
  );
};

export default SidebarFeedback;
