import { useCallback } from "react";

import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/user-role`;
const userRoleScopesEndpoint = `/api/user-role-scope`;

export const useGetUserRoles = () => {
  return useApiGet(endpoint);
};

export const useGetUserRoleScopes = () => {
  return useApiGet(userRoleScopesEndpoint);
};

export const useCreateUserRole = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateUserRole = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (Role) => {
      await mutate(Role);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteUserRole = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteUserRole = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteUserRole };
};

export const useGetAllUserRoles = () => {
  return useApiGet(`${endpoint}/all`);
};
