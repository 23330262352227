import React, { useEffect } from "react";
import { components, data } from "@crewos/shared";
import { useParams, useNavigate } from "react-router-dom";
import { useGetQuestionTypes, useGetSafetyTemplates } from "../../api/Safety.hooks";

const { FormTemplate, Loader } = components;
const { FormTemplateProvider } = data;

const ViewTemplates = () => {
  const { id: templateId } = useParams();
  const navigate = useNavigate();

  const {
    data: questionTypes,
    get: getQuestionTypes,
    isLoading: isQuestionTypesLoading,
  } = useGetQuestionTypes();
  const {
    data: safetyTemplates,
    get: getSafetyTemplates,
    isLoading: isSafetyTemplatesLoading,
  } = useGetSafetyTemplates();

  useEffect(() => {
    if (safetyTemplates && safetyTemplates.totalSafety > 0) {
      navigate(`/safety/templates/view/${templateId}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [safetyTemplates]);

  useEffect(() => {
    getQuestionTypes();
    if (templateId) {
      getSafetyTemplates({ id: templateId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isQuestionTypesLoading || isSafetyTemplatesLoading) {
    return <Loader />;
  }
  return (
    <FormTemplateProvider>
      <FormTemplate
        isReadOnly
        data={safetyTemplates}
        questionTypes={questionTypes}
        prefix="safety"
      />
    </FormTemplateProvider>
  );
};

export default ViewTemplates;
