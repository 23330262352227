import React, { useCallback, useState } from "react";

import WorkPerformedWorkDay from "./WorkPerformedWorkDay";

import { data, components, sharedHelper } from "@crewos/shared";

import WorkPerformedCards from "./WorkPerformedCards";

import { Button, Card, CardBody } from "reactstrap";

import axios from "axios";

import { useCrews } from "../providers/crewsProvider";
import { useCrewWorkDays } from "../providers/crewWorkDaysProvider";

const { WorkOrderDateSelector } = components;

const { useWorkOrderDetails } = data;

const WorkPerformed = ({ onEdit, onDelete }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext] = useCrewWorkDays();
  const [crewsContext] = useCrews();

  const [isLoading, setIsLoading] = useState();

  const onDownloadPdf = useCallback(async () => {
    try {
      setIsLoading(true);

      sharedHelper.loadingToast();

      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/work-order/${workOrderDetails.workOrder.id}/report`,
        {
          responseType: "blob",
          withCredentials: true,
        }
      );

      const woNumber = workOrderDetails.workOrder.workOrderNumber;
      const customerName = workOrderDetails.workOrder.customer
        ? workOrderDetails.workOrder.customer.customerName
        : "No customer";

      const fileName = `${woNumber}-${customerName}.pdf`
        .replaceAll(" ", "_")
        .toLowerCase();

      sharedHelper.downloadFile(response.data, fileName);
      setIsLoading(false);
    } catch (error) {
      sharedHelper.errorToast(error.message);
    } finally {
      sharedHelper.dismissLoadingToast();
    }
  }, [workOrderDetails]);

  return (
    <>
      <Card className="w-100 px-0 box-shadow-none mb-3">
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="mb-0">Work Performed</h2>
            <div className="d-flex align-items-center">
              <Button
                color="primary"
                onClick={onDownloadPdf}
                disabled={isLoading}
              >
                Download Report
              </Button>
              <Button color="primary" className="ms-2" onClick={onEdit}>
                Edit Info
              </Button>
              <Button color="danger" className="ms-2" onClick={onDelete}>
                Delete
              </Button>
            </div>
          </div>
          <WorkPerformedCards />
          <WorkOrderDateSelector
            crewWorkDays={crewWorkDaysContext.crewWorkDays}
          />
        </CardBody>
      </Card>
      {crewsContext.crews.length ? (
        crewsContext.crews.map((crew) => (
          <WorkPerformedWorkDay key={crew.id} crew={crew} />
        ))
      ) : (
        <div className="p-3 small text-muted text-center bg-white border-radius-default">
          Start by creating your first crew
        </div>
      )}
    </>
  );
};

export default WorkPerformed;
