import React, { useCallback, useMemo, useRef, useState } from "react";

import ReactQuill from "react-quill";
import { Button, Col, Collapse, Row } from "reactstrap";

import { components } from "@crewos/shared";

import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";

const { Icon } = components;

const GuidanceForm = ({ guidance, questionId, isReadOnly }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [text, setText] = useState(guidance.text);
  const [attachment, setAttachment] = useState(guidance.attachment);

  const { updateGuidance } = useInspectionTemplate();

  const fileInputRef = useRef(null);

  const handleCollapse = useCallback(($event) => {
    $event.stopPropagation();
    setIsOpen((status) => !status);
  }, []);

  const handleAttachmentChange = useCallback(
    ($event) => {
      const file = $event.target.files[0];
      const updatedFile = new File([file], `guidance_${Date.now()}`, {
        type: file.type,
      });

      setAttachment(updatedFile);
      updateGuidance(questionId, { text, attachment: updatedFile });
    },
    [questionId, text, updateGuidance]
  );

  const handleRemoveAttachment = useCallback(() => {
    setAttachment(null);
    updateGuidance(questionId, { text, attachment: null });
  }, [questionId, text, updateGuidance]);

  const handleUploadAttachment = useCallback(() => {
    fileInputRef.current.click();
  }, []);

  const handleTextChange = useCallback(
    (value) => {
      setText(value);
      updateGuidance(questionId, { text: value, attachment });
    },
    [questionId, updateGuidance, attachment]
  );

  const attachmentValue = useMemo(() => {
    if (!attachment) return null;
    return attachment instanceof File
      ? URL.createObjectURL(attachment)
      : attachment;
  }, [attachment]);

  return (
    <div className="border border-radius-md p-3 mx-0 my-3" sm={2}>
      <Row onClick={handleCollapse} className="cursor-pointer">
        <Col sm={6}>
          <span className="fw-bold text-lg">Guidance</span>
        </Col>
        <Col className="d-flex justify-content-end" sm={6}>
          <Button className="btn btn-link d-flex align-items-center expand-button text-decoration-none">
            <small className="text-primary">
              View {isOpen ? "less" : "more"}
            </small>
            <Icon
              name="chevron-right"
              className={`ms-1 rotate ${isOpen ? "rotate-90" : ""}`}
            />
          </Button>
        </Col>
      </Row>
      <Collapse isOpen={isOpen}>
        <Row>
          <Col>
            <div className="mx-0 my-3">
              <Icon name="info" />
              <span className="ms-2">Guidance text:</span>
            </div>
            <ReactQuill
              theme="snow"
              value={text}
              isReadOnly={isReadOnly}
              onChange={handleTextChange}
              className="border rounded"
            />
          </Col>
          <Col>
            <div className="mx-0 my-3">
              <Icon name="info" />
              <span className="ms-2">Guidance attachment (jpg, png):</span>
            </div>
            <div>
              {!isReadOnly && (
                <input
                  type="file"
                  value=""
                  onChange={handleAttachmentChange}
                  disabled={isReadOnly}
                  className="d-none"
                  id="guidance-attachment"
                  accept="image/*"
                  name="guidance-attachment"
                  data-testid="guidance-attachment"
                  ref={fileInputRef}
                />
              )}
              {attachment ? (
                <div className="mt-2 d-flex align-items-start gap-2">
                  <img
                    src={attachmentValue}
                    alt="Guidance attachment preview"
                    className="img-fluid rounded"
                    style={{ maxWidth: "64px", maxHeight: "64px" }}
                  />
                  {!isReadOnly && (
                    <Button
                      color="link"
                      className="rounded-circle d-flex custom-rounded-button text-danger border-0"
                      onClick={handleRemoveAttachment}
                    >
                      <Icon name="trash" />
                    </Button>
                  )}
                </div>
              ) : (
                !isReadOnly && (
                  <Button
                    color="primary"
                    size="sm"
                    onClick={handleUploadAttachment}
                  >
                    Upload Image
                  </Button>
                )
              )}
            </div>
          </Col>
        </Row>
      </Collapse>
    </div>
  );
};

export default GuidanceForm;
