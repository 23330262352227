import React, { useCallback } from "react";

import { Button, Col, Row } from "reactstrap";

import { components } from "@crewos/shared";

import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";
import InputEditable from "./InputEditable";

const { Icon } = components;

const OptionItem = ({ option, isReadOnly }) => {
  const { updateOption, removeOption } = useInspectionTemplate();

  const handleUpdateContent = useCallback(
    ($event) => {
      const value = $event.target.value;
      updateOption({ ...option, content: value });
    },
    [updateOption, option]
  );

  const handleRemove = useCallback(() => {
    removeOption(option);
  }, [removeOption, option]);

  return (
    <Row className="option-item align-items-center mb-2">
      <Col md={1}>
        <Icon name="circle" />
      </Col>
      <Col md={5} className="justify-content-start">
        <InputEditable
          value={option.content}
          onChange={handleUpdateContent}
          placeholder="Type an option"
          size="small"
          disabled={isReadOnly}
        />
      </Col>
      {!isReadOnly && (
        <Col md={1}>
          <Button
            size="sm"
            className="rounded-circle d-flex custom-rounded-button remove-btn border-0 font-size-1 py-1 px-2"
            color="white"
            onClick={handleRemove}
          >
            <Icon name="x" />
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default OptionItem;
