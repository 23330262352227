import React, { useState, useCallback, useEffect } from "react";

import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import AssignTemplateWOForm from "./AssignTemplateWOForm";
import { useCreateTemplateWorkOrder } from "../api/Inspections.hooks";
import { sharedHelper } from "@crewos/shared";

const AssignTemplateWOModal = ({ isOpen, onClose, onAssign, workOrder }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [error, setError] = useState({});

  const {
    mutate: createTemplateWO,
    isLoading: isLoadingTemplateWO,
    data: dataTemplateWO,
  } = useCreateTemplateWorkOrder();

  const handleSelectTemplate = useCallback((template) => {
    setSelectedTemplate(template);
    setError({});
  }, []);

  const handleAssign = useCallback(
    async ($event) => {
      $event.preventDefault();
      let withError = false;
      if (!selectedTemplate) {
        setError((prev) => {
          return {
            ...prev,
            template: ["Please select a template"],
          };
        });
        withError = true;
      }
      if (withError) {
        return;
      }

      const formData = new FormData($event.target);
      formData.set("inspectionTemplateId", selectedTemplate?.value);
      formData.set("workOrderId", workOrder.id);
      const data = Object.fromEntries(formData);
      await createTemplateWO(data);
    },
    [selectedTemplate, createTemplateWO, workOrder]
  );

  useEffect(() => {
    if (dataTemplateWO) {
      sharedHelper.successToast("Template assigned");
      onAssign();
    }
  }, [dataTemplateWO, onAssign]);

  return (
    <Modal isOpen={isOpen} size="sm">
      <Form onSubmit={handleAssign}>
        <ModalHeader toggle={onClose}>Assign Inspection Template</ModalHeader>
        <ModalBody>
          <AssignTemplateWOForm
            onSelectTemplate={handleSelectTemplate}
            error={error}
          />
        </ModalBody>
        <ModalFooter className="d-flex flex-row flex-nowrap">
          <Button
            style={{ width: "100%" }}
            color="secondary"
            onClick={onClose}
            disabled={isLoadingTemplateWO}
          >
            Cancel
          </Button>
          <Button
            style={{ width: "100%" }}
            color="primary"
            type="submit"
            disabled={isLoadingTemplateWO}
          >
            Assign
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AssignTemplateWOModal;
