import React, { useCallback } from "react";
import { Button, Col, Row } from "reactstrap";

import { components } from "@crewos/shared";

import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";
import OptionItem from "./OptionItem";

const { Icon } = components;

const Options = ({ options, questionId, isReadOnly }) => {
  const { addOption } = useInspectionTemplate();

  const handleAddOption = useCallback(() => {
    addOption(questionId);
  }, [addOption, questionId]);

  return (
    <div className="options-list">
      {options.map((option) => (
        <OptionItem
          option={option}
          onChange={() => null}
          key={option.id}
          isReadOnly={isReadOnly}
        />
      ))}
      {!isReadOnly && (
        <Row className="add-btn-container m-0">
          <Col className="d-grid p-0" md={7}>
            <Button
              size="sm"
              color="white"
              className="btn-dashed py-2 px-4 justify-content-center font-size-1 rounded-4"
              onClick={handleAddOption}
            >
              <Icon name="plus-circle" className="fs-4" /> Add Option
            </Button>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default Options;
