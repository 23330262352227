import React, { useState } from "react";

import { Badge, Button } from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";

import EditTravelTimeModal from "./EditTravelTimeModal";

const { Icon, TooltipItem } = components;

const { useAuth, useWorkOrderDetails } = data;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const CREWS_PACKAGE = "crews";

const START_END_TRAVEL_TIME_SETTING = "START_END_TRAVEL_TIME_SETTING";
const TRAVEL_TIMES_PACKAGE = "traveltimes";

const JOB_DETAILS_TAB = "JOB_DETAILS_TAB";

const EmployeeCrewRow = ({ employeeCrew, travelTimes, onRefresh }) => {
  const [authContext] = useAuth();
  const [workOrderDetails] = useWorkOrderDetails();

  const [editModal, setEditModal] = useState();

  const isReadOnly = workOrderDetails.activeTab === JOB_DETAILS_TAB;

  const startEndTimeSettingEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    TRAVEL_TIMES_PACKAGE,
    START_END_TRAVEL_TIME_SETTING
  );

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const accumulatedValues = {
    hours: travelTimes.reduce((acc, curr) => acc + curr.hours, 0),
    mileage: travelTimes.reduce((acc, curr) => acc + curr.mileage, 0),
  };

  const times = (
    <div
      className={`d-flex justify-content-${
        isReadOnly ? "end" : "center"
      } align-items-center flex-shrink-0`}
    >
      <span>
        {`${sharedHelper.formatDecimal(
          accumulatedValues.hours
        )} / ${sharedHelper.formatMileage(accumulatedValues.mileage, 1)}`}
      </span>
      {startEndTimeSettingEnabled ? (
        <TooltipItem
          id={`${employeeCrew.id}-tooltip`}
          title={sharedHelper.timeEntryToolTipContent(
            travelTimes,
            false,
            startEndTimeSettingEnabled
          )}
          opacity={1}
        >
          <Icon
            data-testid="info-circle"
            name="info"
            className="text-muted ms-1"
            style={{ paddingBottom: "1px" }}
          />
        </TooltipItem>
      ) : null}
    </div>
  );

  return (
    <div className="d-flex align-items-center justify-content-between p-3 border-radius-default box-shadow-card my-3">
      <div className="col-4 d-flex align-items-center pe-3">
        <span>{`${employeeCrew.employee.firstName} ${employeeCrew.employee.lastName}`}</span>
        <div className="text-muted d-flex align-items-center ms-2">
          {!directAssignationEnabled && employeeCrew.isLead ? (
            <Badge color="primary" className="me-2">
              Crew Lead
            </Badge>
          ) : null}
          <Badge color="primarylight" className="text-primary">
            {employeeCrew.role.name}
          </Badge>
        </div>
      </div>
      {travelTimes.length ? (
        <>
          <div className={`col-6 text-${isReadOnly ? "end" : "center"}`}>
            {times}
          </div>
          {!isReadOnly ? (
            <div className="col-2 d-flex justify-content-end">
              <Button
                size="sm"
                color="none"
                className="mx-2 text-primary"
                onClick={() => setEditModal(true)}
              >
                Edit
              </Button>
            </div>
          ) : null}
        </>
      ) : (
        <>
          <div
            className={`col-6 text-${isReadOnly ? "end" : "center"} text-muted`}
          >
            No time
          </div>
          {!isReadOnly ? (
            <div className="col-2 d-flex justify-content-end"></div>
          ) : null}
        </>
      )}
      {editModal ? (
        <EditTravelTimeModal
          travelTimes={travelTimes}
          employee={employeeCrew.employee}
          onClose={() => {
            setEditModal();
          }}
          onSubmit={() => {
            setEditModal();
            onRefresh();
          }}
        />
      ) : null}
    </div>
  );
};

export default EmployeeCrewRow;
