import axios from "axios";
import { sharedHelper } from "../helpers/sharedHelper";

export const HttpMethod = {
  GET: "get",
  PATCH: "patch",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export const apiHelper = async (method, path, params = {}, headers = {}) => {
  let requestOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
    method,
    headers,
  };

  if (
    params.id &&
    path.indexOf("bulk") < 0 &&
    (method === HttpMethod.PATCH ||
      method === HttpMethod.PUT ||
      method === HttpMethod.DELETE ||
      method === HttpMethod.GET)
  ) {
    path = `${path}/${params.id}`;
    delete params.id;
  }

  if (
    method === HttpMethod.GET ||
    (method === HttpMethod.DELETE && path.indexOf("bulk") > 0)
  ) {
    const query = sharedHelper.buildQueryString(params);
    path = `${path}?${query}`;
  }

  if (
    method === HttpMethod.PATCH ||
    method === HttpMethod.POST ||
    method === HttpMethod.PUT
  ) {
    requestOptions = {
      ...requestOptions,
      data: params,
    };
  }

  const response = await axios(path, requestOptions);
  return response;
};

export const Api = {
  async get(path, params) {
    return apiHelper(HttpMethod.GET, path, params);
  },
  async post(path, params) {
    return apiHelper(HttpMethod.POST, path, params);
  },
  async put(path, params) {
    return apiHelper(HttpMethod.PUT, path, params);
  },
  async delete(path, params) {
    return apiHelper(HttpMethod.DELETE, path, params);
  },
};
