import React, { useCallback, useEffect, useState } from "react";

import { DebounceInput } from "react-debounce-input";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import moment from "moment";

import { PayrollDateDetail } from "@crewos/reports";
import { components, data } from "@crewos/shared";

import { useGetWorkTimeCollisions } from "../../api/WorkTimes.hooks";

const { useAuth } = data;

const { AdvanceTable, AdvanceTablePagination, Icon, CustomCheckboxWithLabel } =
  components;

const replaceDatesWithFormattedTime = (str) => {
  const dateRegex = /(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2})/g;
  return str.replace(dateRegex, (match) => {
    return moment.utc(match).local().format("h:mm a");
  });
};

const columns = (onSeeDetails) => [
  {
    accessor: "employee",
    header: "Employee Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.employee;
    },
  },
  {
    accessor: "date",
    header: "Date",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.date;
    },
  },
  {
    accessor: "intersectingRanges",
    header: "Intersecting Ranges",
    disableFilter: true,
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return replaceDatesWithFormattedTime(rowData.row.intersectingRanges);
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    disableSortBy: true,
    cellProps: {
      className: "text-end",
    },
    Cell: (rowData) => (
      <Button
        size="sm"
        color="primary"
        onClick={() => onSeeDetails(rowData.row)}
      >
        View Details
      </Button>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const WorkTimeCollisions = () => {
  const [authContext] = useAuth();

  const [data, setData] = useState({});
  const [refresh, setRefresh] = useState();
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("employee");
  const [direction, setDirection] = useState("asc");
  const [createdToday, setCreatedToday] = useState();
  const [dateDetailsModal, setDateDetailsModal] = useState();

  const {
    data: getWorkTimeCollisionsData,
    isLoading,
    get: getWorkTimeCollisions,
  } = useGetWorkTimeCollisions();

  useEffect(() => {
    getWorkTimeCollisions({
      createdToday,
      search,
      page,
      pageSize: sizePerPage,
      sortBy,
      direction,
    });
  }, [
    getWorkTimeCollisions,
    createdToday,
    refresh,
    sortBy,
    direction,
    sizePerPage,
    page,
    search,
  ]);

  useEffect(() => {
    if (getWorkTimeCollisionsData) {
      setData(getWorkTimeCollisionsData);
    }
  }, [getWorkTimeCollisionsData, setData]);

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        if (data.sortBy === sortBy && data.direction === direction) {
          return;
        }
        setSortBy(data.sortBy);
        setDirection(data.direction);
        setPage(INIT_PAGE);
      } else {
        setSortBy("employee");
        setDirection("asc");
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  const onSeeDetails = (data) => {
    setDateDetailsModal({
      employeeId: data.employeeId,
      date: data.date,
    });
  };

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex flex-column">
            <div className="d-flex align-items-center">
              <h2 className="mb-0 ">Work Time Collisions</h2>
              <small className="text-muted ms-2 pt-1">({data.count})</small>
            </div>
            {authContext && (
              <small className="text-muted">
                {
                  authContext.userData.notifications[
                    "worktimes/work-time-collisions"
                  ]
                }{" "}
                collisions created today
              </small>
            )}
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <CustomCheckboxWithLabel
                onClick={() => setCreatedToday(!createdToday)}
                checked={createdToday}
                label="Created Today"
              />
            </div>
            <div className="me-3">
              <DebounceInput
                className="search border-0 form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search for an employee.."
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              data-testid="refresh-button" // Added test id for easier selection
              onClick={() => setRefresh((prev) => !prev)}
            >
              <Icon name="refresh-cw" />
            </Button>
            <div className="table-export-container me-3">
              <div id="table-export" />
            </div>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              isLoading={isLoading}
              headerClassName="text-muted small"
              columns={columns(onSeeDetails)}
              data={data.data || []}
              pageSize={sizePerPage}
              sortable
              onSort={onSort}
              exportable
              exportName="work-time-collisions.csv"
              defaultSort={{
                sortBy,
                direction,
              }}
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={data.count}
            pageCount={data.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {dateDetailsModal ? (
        <PayrollDateDetail
          employeeId={dateDetailsModal.employeeId}
          date={dateDetailsModal.date}
          onClose={(touched) => {
            setDateDetailsModal();
            if (touched) {
              setRefresh(!refresh);
            }
          }}
        />
      ) : null}
    </Container>
  );
};

export default WorkTimeCollisions;
