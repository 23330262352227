import { api } from "@crewos/shared";

const { useApiGet } = api;

const expenseTypeEndpoint = `/api/expense-type`;

export const useGetExpenseTypes = (data) => {
  return useApiGet(
    data?.id ? `${expenseTypeEndpoint}/${data.id}` : expenseTypeEndpoint
  );
};

export const useGetAllExpenseTypes = () => {
  return useApiGet(`${expenseTypeEndpoint}/all`);
};
