import { useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";

const contactEndpoint = `/api/contact`;

export const useSendSupport = () => {
  return useApiMutation(HttpMethod.POST, `${contactEndpoint}/support`);
};

export const useSendFeedback = () => {
  return useApiMutation(HttpMethod.POST, `${contactEndpoint}/feedback`);
};
