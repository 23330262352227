import React, { useMemo, useState, useEffect } from "react";

import { Button, Card, CardBody, Row } from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";

import { useGetNotes } from "../api/Notes.hooks";

import Note from "./Note";
import NoteModal from "./NoteModal";

import { useCrewWorkDays, CREW_WORK_DAY_ACTIONS } from "@crewos/crews";

const { Loader, Icon } = components;
const { useAuth, useWorkOrderDetails } = data;

const CrewWorkDayNotes = ({ crew }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();

  const [refresh, setRefresh] = useState();
  const [notes, setNotes] = useState([]);

  const [createModal, setCreateModal] = useState(false);

  //only one crew work day per date/crew
  const crewWorkDay = useMemo(
    () =>
      crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate]?.find(
        (cwd) => cwd.crewId === crew.id
      ),
    [workOrderDetails.selectedDate, crewWorkDaysContext.crewWorkDays, crew]
  );

  const {
    data: getNotesData,
    isLoading: isLoadingGetNotes,
    get: getNotes,
  } = useGetNotes();

  useEffect(() => {
    if (crewWorkDay) {
      getNotes({
        crewWorkDayId: crewWorkDay.id,
      });
    }
  }, [getNotes, refresh, crewWorkDay]);

  useEffect(() => {
    if (getNotesData) {
      setNotes(getNotesData);
    }
  }, [getNotesData]);

  const onRefresh = () => {
    if (!crewWorkDay) {
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.REFRESH,
      });
    } else {
      setRefresh((prev) => !prev);
    }
  };

  return (
    <Card className="w-100 px-0 box-shadow-none mb-3">
      <CardBody>
        <div className="mb-n1 d-flex align-items-center justify-content-between">
          <h3>Notes</h3>
          <div className="d-flex align-items-center">
            <Button
              size="sm"
              color="primary"
              onClick={() => setCreateModal(true)}
            >
              Add
            </Button>
            <Button
              size="sm"
              className="ms-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={onRefresh}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
          </div>
        </div>
        {isLoadingGetNotes ? (
          <Loader size="sm" className="my-3" />
        ) : notes.length ? (
          <Row className="mt-3 d-flex flex-wrap">
            {notes.map((note) => (
              <div className="col-6" key={note.id}>
                <Note note={note} onRefresh={onRefresh} />
              </div>
            ))}
          </Row>
        ) : (
          <div className="p-2 small text-muted text-center">No notes</div>
        )}
      </CardBody>
      {createModal && (
        <NoteModal
          crewId={crew.id}
          onSubmit={() => {
            setCreateModal(false);
            onRefresh();
          }}
          onClose={() => setCreateModal(false)}
        />
      )}
    </Card>
  );
};

const CrewWorkDayNotesWrapper = (props) => {
  const [authContext] = useAuth();

  if (!sharedHelper.isPackageEnabled(authContext.userData?.packages, "notes")) {
    return null;
  }

  return <CrewWorkDayNotes {...props} />;
};

export default CrewWorkDayNotesWrapper;
