import { useCallback } from "react";
import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

export const useGetPayroll = () => {
  return useApiGet(`/api/base-report/payroll`);
};

export const useGenerateSmartReport = () => {
  return useApiGet(`/api/smart-report/generate`);
};

export const useGetSmartReports = () => {
  return useApiGet(`/api/smart-report`);
};

export const useCreateSmartReport = () => {
  return useApiMutation(HttpMethod.POST, `/api/smart-report`);
};

export const useUpdateSmartReport = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `/api/smart-report`
  );

  const update = useCallback(
    async (report) => {
      await mutate(report);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteSmartReport = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `/api/smart-report`
  );

  const deleteReport = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteReport };
};

export const useGetLaborReport = () => {
  return useApiGet(`/api/base-report/labor-cost`);
};
