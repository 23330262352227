import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { LoginForm } from "../../../components/LoginForm";
import { data } from "@crewos/shared";

const { useAuth } = data;

const redirectTo = (location) => {
  if (location.search) {
    const params = new URLSearchParams(location.search);
    return <Navigate to={params.get("returnURL")} />;
  }
  return <Navigate to={`/workorders`} />;
};

const SignIn = () => {
  const location = useLocation();
  const [authContext] = useAuth();

  if (!authContext.userData) {
    return <LoginForm />;
  }

  return redirectTo(location, authContext.userData.rol);
};

export default SignIn;
