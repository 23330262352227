import React, { useState } from "react";
import { Button, Row, Card, CardBody, Col } from "reactstrap";

import CrewWorkDay from "./CrewWorkDay";

import AddEquipmentModal from "./AddEquipmentModal";

import { useCrews, useCrewWorkDays } from "@crewos/crews";

import { components, data } from "@crewos/shared";

const { WorkOrderDateSelector } = components;
const { useWorkOrderDetails } = data;

const CrewsEquipments = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewsContext] = useCrews();
  const [crewWorkDaysContext] = useCrewWorkDays();

  const [addEquipmentModal, setAddEquipmentModal] = useState();

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 px-0 box-shadow-none mb-3">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2 className="mb-0">Equipment</h2>
              <Button
                color="primary"
                onClick={() => setAddEquipmentModal(true)}
              >
                Assign Equipment
              </Button>
            </div>
            <WorkOrderDateSelector
              crewWorkDays={crewWorkDaysContext.crewWorkDays}
            />
          </CardBody>
        </Card>
        {crewsContext.crews.length ? (
          crewsContext.crews.map((crew) => (
            <CrewWorkDay
              key={crew.id}
              crew={crew}
              isReadOnly={
                workOrderDetails.workOrder.workOrderStatus.isWoReadOnly
              }
            />
          ))
        ) : (
          <div className="p-3 small text-muted text-center bg-white border-radius-default">
            Start by creating your first crew
          </div>
        )}
      </Row>
      {addEquipmentModal ? (
        <AddEquipmentModal onClose={() => setAddEquipmentModal()} />
      ) : null}
    </Col>
  );
};

export default CrewsEquipments;
