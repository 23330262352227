import { useContext, useCallback } from "react";
import { v1 as uuidv1 } from "uuid";
import { ELEMENT_STATUS, FormTemplateContext } from "../index";
import ACTIONS from "../actions";
import useQuestions from "./questions.hook";

const useGroups = () => {
  const context = useContext(FormTemplateContext);
  const { state, dispatch, getGroups, getQuestions } = context;
  const { copyQuestion, deleteQuestions, addQuestion } = useQuestions();

  const addGroup = useCallback(
    (sectionId) => {
      const groups = getGroups(sectionId);
      const group = {
        id: uuidv1(),
        name: "",
        status: ELEMENT_STATUS.CREATE,
        order: groups.length + 1,
        sectionId,
      };
      dispatch({ type: ACTIONS.ADD_GROUP, payload: group });
      addQuestion(group.id);
    },
    [dispatch, getGroups, addQuestion]
  );

  const updateGroupName = useCallback(
    (id, name) => {
      const isNewGroup =
        state.groups[id] && state.groups[id].status === ELEMENT_STATUS.CREATE;
      const status = isNewGroup ? ELEMENT_STATUS.CREATE : ELEMENT_STATUS.UPDATE;
      if (name === state.groups[id].name) {
        return;
      }
      dispatch({
        type: ACTIONS.UPDATE_GROUP_NAME,
        payload: { id, name, status },
      });
    },
    [dispatch, state.groups]
  );

  const updateGroupsOrder = useCallback(
    (groups) => {
      const groupsReduced = groups.reduce((acc, group, index) => {
        acc[group.id] = {
          ...group,
          order: index + 1,
          status:
            group.status !== ELEMENT_STATUS.CREATE
              ? ELEMENT_STATUS.UPDATE
              : group.status,
        };
        return acc;
      }, {});
      dispatch({ type: ACTIONS.UPDATE_GROUPS_ORDER, payload: groupsReduced });
    },
    [dispatch]
  );

  const deleteGroup = useCallback(
    (id) => {
      const groups = { ...state.groups };
      const group = groups[id];
      const sectionId = group.sectionId;
      const isNewGroup =
        groups[id] && groups[id].status === ELEMENT_STATUS.CREATE;
      if (isNewGroup) {
        delete groups[id];
      } else {
        groups[id].status = ELEMENT_STATUS.DELETE;
      }

      const reorderedGroups = Object.values(groups)
        .filter(
          (group) =>
            group.sectionId === sectionId &&
            group.status !== ELEMENT_STATUS.DELETE
        )
        .sort((a, b) => a.order - b.order)
        .reduce((acc, group, index) => {
          acc[group.id] = {
            ...group,
            order: index + 1,
            status:
              group.status !== ELEMENT_STATUS.CREATE
                ? ELEMENT_STATUS.UPDATE
                : group.status,
          };
          return acc;
        }, {});
      deleteQuestions(getQuestions(id));
      dispatch({
        type: ACTIONS.DELETE_GROUP,
        payload: { ...groups, ...reorderedGroups },
      });
    },
    [dispatch, state.groups, deleteQuestions, getQuestions]
  );

  const deleteGroups = useCallback(
    (groups) => {
      const groupsMap = { ...state.groups };
      const questions = groups.map((group) => getQuestions(group.id)).flat();
      deleteQuestions(questions);
      groups.forEach((group) => {
        const isNewGroup =
          groupsMap[group.id] &&
          groupsMap[group.id].status === ELEMENT_STATUS.CREATE;
        if (isNewGroup) {
          delete groupsMap[group.id];
        } else {
          groupsMap[group.id].status = ELEMENT_STATUS.DELETE;
        }
      });
      dispatch({ type: ACTIONS.DELETE_GROUP, payload: { ...groupsMap } });
    },
    [dispatch, state.groups, deleteQuestions, getQuestions]
  );

  const copyGroup = useCallback(
    (group, { keepOrder = false } = {}) => {
      const groups = getGroups(group.sectionId);
      const newGroup = {
        ...group,
        id: uuidv1(),
        status: ELEMENT_STATUS.CREATE,
        order: keepOrder ? group.order : groups.length + 1,
      };
      getQuestions(group.id).map((question) =>
        copyQuestion({ ...question, groupId: newGroup.id }, { keepOrder: true })
      );
      dispatch({ type: ACTIONS.COPY_GROUP, payload: newGroup });
    },
    [dispatch, copyQuestion, getGroups, getQuestions]
  );

  return {
    ...context,
    addGroup,
    updateGroupName,
    updateGroupsOrder,
    deleteGroup,
    deleteGroups,
    copyGroup,
  };
};

export default useGroups;
