import React, { useEffect, useState } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Label,
  Form,
  FormGroup,
} from "reactstrap";

import { useCreateEmployeeNote } from "../api/EmployeesNotes.hooks";

import { components, sharedHelper } from "@crewos/shared";

const { Loader } = components;

const AddEmployeeNoteModal = ({ onClose, onSubmit, employeeId }) => {
  const [content, setContent] = useState("");

  const {
    isLoading: isLoadingCreateEmployee,
    mutate: createEmployeeNote,
    data: createEmployeeNoteData,
  } = useCreateEmployeeNote();

  useEffect(() => {
    if (createEmployeeNoteData) {
      sharedHelper.successToast(`Note added`);
      onSubmit();
    }
  }, [createEmployeeNoteData, onSubmit]);

  const doSubmit = async (evt) => {
    evt.preventDefault();
    createEmployeeNote({
      employeeId,
      content,
    });
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Employee Notes
      </ModalHeader>
      <Form onSubmit={doSubmit} data-testid="note-form">
        <ModalBody className="text-center">
          {isLoadingCreateEmployee ? (
            <Loader size="sm" />
          ) : (
            <Row>
              <FormGroup className="col-12 text-start">
                <Label htmlFor="noteContent">
                  <span>Note</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <textarea
                  id="noteContent"
                  className="form-control-redesign"
                  required={true}
                  rows={4}
                  placeholder="Enter the note.."
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                />
              </FormGroup>
            </Row>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose}>
            Discard
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddEmployeeNoteModal;
