import { useCallback } from "react";

import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const customerEndpoint = `/api/customer`;
const customerLocationEndpoint = `/api/customer-location`;
const customerContactEndpoint = `/api/customer-contact`;

// Customer
export const useGetCustomers = () => {
  return useApiGet(customerEndpoint);
};

export const useCreateCustomer = () => {
  return useApiMutation(HttpMethod.POST, customerEndpoint);
};

export const useUpdateCustomer = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, customerEndpoint);

  const update = useCallback(
    async (customer) => {
      await mutate(customer);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteCustomer = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    customerEndpoint
  );

  const deleteCustomer = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteCustomer };
};

// Customer Contacts
export const useUpdateLocationCustomerContacts = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    customerContactEndpoint
  );

  const update = useCallback(
    async (customerLocation) => {
      await mutate(customerLocation);
    },
    [mutate]
  );

  return { ...rest, update };
};

// Customer Locations
export const useGetCustomerLocations = () => {
  return useApiGet(customerLocationEndpoint);
};

export const useCreateCustomerLocation = () => {
  return useApiMutation(HttpMethod.POST, customerLocationEndpoint);
};

export const useUpdateCustomerLocation = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    customerLocationEndpoint
  );

  const update = useCallback(
    async (customer) => {
      await mutate(customer);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteCustomerLocation = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    customerLocationEndpoint
  );

  const deleteCustomerLocation = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteCustomerLocation };
};
