import React from "react";

import Select from "./Select";

const style = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
    minWidth: "125px",
    backgroundColor: "transparent",
  }),
};

export const TYPE_WEEKLY = "TYPE_WEEKLY";
export const TYPE_CUSTOM_PERIOD = "TYPE_CUSTOM_PERIOD";

const PeriodTypeSelector = ({ periodType, setPeriodType, className = "" }) => {
  const options = [
    {
      label: "Date Range",
      value: TYPE_CUSTOM_PERIOD,
    },
    {
      label: "Weekly",
      value: TYPE_WEEKLY,
    },
  ];

  return (
    <div className="d-flex me-3">
      <Select
        styles={style}
        className={className}
        options={options}
        closeMenuOnSelect={true}
        value={options.find((option) => option.value === periodType.value)}
        onChange={(data) => setPeriodType(data)}
      />
    </div>
  );
};

export default PeriodTypeSelector;
