import React, { useMemo, useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { components } from "@crewos/shared";

const { Icon, Select, TooltipItem } = components;

const NewSmartReportModal = ({ onClose, onSubmit, context }) => {
  const [data, setData] = useState("");
  const [from, setFrom] = useState("prompt");

  const doSubmit = (evt) => {
    evt.preventDefault();
    onSubmit(data, from);
  };

  const typeSelect = useMemo(() => {
    return [
      { value: "prompt", label: "From Prompt" },
      { value: "query", label: "From Query" },
    ];
  }, []);

  const defaultType = useMemo(() => {
    return typeSelect.find((type) => type.value === from);
  }, [from, typeSelect]);

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          <div className="d-flex align-items-center">
            <span>New Smart Report</span>
            <TooltipItem
              className="font-size-regular fw-regular"
              id="context-tooltip"
              title={`${context} Context`}
            >
              <Icon
                name="info"
                className="text-primary text-warning ms-2"
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Select
              name="typeSelect"
              onChange={(selected) => setFrom(selected.value)}
              defaultValue={defaultType}
              options={typeSelect}
              required
            />
          </FormGroup>
          <FormGroup>
            <textarea
              className={from === "prompt" ? "form-control" : "code"}
              type="text"
              wrap="soft"
              maxLength="1000"
              style={{ height: 100 }}
              placeholder={
                from === "prompt"
                  ? "Describe the data you need"
                  : "Enter a MySQL query"
              }
              value={data}
              onChange={(e) => setData(e.target.value)}
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Generate
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default NewSmartReportModal;
