import { BaseRoutes, settingsRoutes } from "./routes/Routes";

import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import Page401 from "./pages/auth/Page401";

import { useGetAllPackages } from "./api/Packages.hooks";
import { data, sharedHelper, helpers } from "@crewos/shared";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const setCoreSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const packageEnabled = sharedHelper.isPackageEnabled(packages, "workorders");

  const settingsSidebarRoutes = settingsRoutes.filter(
    (settingRoute) => settingRoute.sidebar
  );

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: settingsSidebarRoutes },
    });
  }
};

const Routes = createRoutes(settingsRoutes);

export {
  Routes as CoreRoutes,
  BaseRoutes,
  setCoreSidebarRoutes,
  useGetAllPackages,
  Page404,
  Page500,
  Page401,
};
