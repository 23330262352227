import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import InspectionDetail from "./InspectionDetail";

const InspectionDetailModal = ({ isOpen, inspection, onClose, status }) => {
  return (
    <Modal isOpen={isOpen} contentClassName="p-3 border-radius-md" size="lg">
      <ModalHeader toggle={onClose} className="z-1" />
      <ModalBody>
        <InspectionDetail inspection={inspection} status={status} />
      </ModalBody>
    </Modal>
  );
};

export default InspectionDetailModal;
