import React from "react";
import { useAuth } from "../providers/authProvider";
import { Navigate } from "react-router-dom";
import { sharedHelper } from "../helpers/sharedHelper";
import Loader from "./Loader";

const ScopeFilter = ({ route, children }) => {
  const [authContext] = useAuth();

  if (!authContext || !authContext.userData) {
    return <Loader />;
  }

  const packages = authContext.userData.packages || [];
  const packageEnabled = sharedHelper.isPackageEnabled(packages, route.package);

  const routeMeetsRole = sharedHelper.routeMeetsRole(
    route,
    authContext.userData?.role.userRoleScopes
  );

  if (!routeMeetsRole || !packageEnabled) {
    return <Navigate to={`/error/401`} />;
  }

  return children;
};

export default ScopeFilter;
