import React, { useMemo, useCallback, useEffect, useState } from "react";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { useLocation, useParams } from "react-router";

import { useNavigate } from "react-router-dom";

import { useGetWorkOrders } from "../../../workorders/src/api/WorkOrders.hooks";
import WorkOrderModal from "../../../workorders/src/components/WorkOrderModal";

import { components, data, sharedHelper } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { useAuth } = data;

const { AdvanceTable, AdvanceTablePagination, Icon } = components;

const columns = [
  {
    accessor: "workOrderNumber",
    header: "Work Order #",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { workOrderNumber } = rowData.row;
      return workOrderNumber || "-";
    },
  },
  {
    accessor: "customer.customerName",
    header: "Customer",
    headerProps: { className: "text-truncate", style: { width: 250 } },
    cellProps: {
      className: "text-truncate",
      style: { width: 350 },
    },
    Cell: (rowData) => {
      const { customer } = rowData.row;
      return customer?.customerName || "-";
    },
  },
  {
    accessor: "customerLocation.shipToAddress",
    header: "Location",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    disableSortBy: true,
    Cell: (rowData) => {
      const { customerLocation } = rowData.row;
      return customerLocation?.shipToAddress || "-";
    },
  },
  {
    accessor: "crews",
    header: "Lead",
    headerProps: { className: "text-truncate", style: { width: 250 } },
    cellProps: {
      className: "text-truncate",
      style: { width: 250 },
    },
    disableSortBy: true,
    Cell: (rowData) => {
      const { crews } = rowData.row;
      const leads = crews.flatMap((crew) =>
        crew.employeeCrews.flatMap((employeeCrew) => employeeCrew.employee)
      );
      return leads.length
        ? leads.map((lead) => `${lead.firstName} ${lead.lastName}`).join(", ")
        : "-";
    },
  },
  {
    accessor: "workOrderStatus.name",
    header: "Status",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    disableSortBy: true,
    Cell: (rowData) => {
      const { workOrderStatus } = rowData.row;
      return workOrderStatus.name;
    },
  },
  {
    accessor: "startDate",
    header: "Dates",
    headerProps: { className: "text-truncate", style: { width: 250 } },
    Cell: (rowData) => {
      const { startDate, endDate } = rowData.row;
      return `${sharedHelper.formatDate(
        startDate,
        "YYYY/MM/DD"
      )} - ${sharedHelper.formatDate(endDate, "YYYY/MM/DD")}`;
    },
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const WorkOrders = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [authContext] = useAuth();

  const [workOrders, setWorkOrders] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("workOrderNumber");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const { workOrderStatusId } = useParams();

  const [createModal, setCreateModal] = useState();

  const {
    data: workOrdersData,
    isLoading: isLoadingWorkOrders,
    get: getWorkOrders,
  } = useGetWorkOrders();

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        if (data.sortBy === sortBy && data.direction === direction) {
          return;
        }
        setSortBy(data.sortBy);
        setDirection(data.direction);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    if (!workOrderStatusId && location.pathname !== "/workorders/all") {
      navigate("/workorders/all");
    }
  }, [workOrderStatusId, location.pathname, navigate]);

  useEffect(() => {
    getWorkOrders({
      search,
      page,
      pageSize: sizePerPage,
      sortBy,
      direction,
      workOrderStatusId,
    });
  }, [
    workOrderStatusId,
    getWorkOrders,
    sortBy,
    direction,
    sizePerPage,
    page,
    search,
    refresh,
  ]);

  useEffect(() => {
    if (workOrdersData) {
      setWorkOrders(workOrdersData);
    }
  }, [workOrdersData, setWorkOrders]);

  const onSeeDetails = (entry) => navigate(`/workorders/details/${entry.id}`);

  const status = useMemo(
    () =>
      authContext.userData.workOrderStatus?.find(
        (s) => s.id === workOrderStatusId
      ),
    [authContext.userData, workOrderStatusId]
  );

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="d-flex flex-column align-items-start">
            <div className="d-flex align-items-center">
              <h2 className="mb-0">{status?.name || "All"} Work Orders</h2>
              <span className="text-muted ms-2 pt-1">({workOrders.count})</span>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <DebounceInput
                className="search border-0 form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search for.."
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            <div className="table-export-container me-3">
              <div id="table-export" />
            </div>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              exportable
              onRowClick={onSeeDetails}
              exportName="WorkOrders.csv"
              columns={columns}
              data={workOrders.data || []}
              pageSize={sizePerPage}
              sortable
              onSort={onSort}
              defaultSort={{
                sortBy,
                direction,
              }}
              isLoading={isLoadingWorkOrders}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={workOrders.count}
            pageCount={workOrders.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {createModal ? (
        <WorkOrderModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : null}
    </Container>
  );
};

export default WorkOrders;
