import React from "react";
import { Tooltip } from "react-tooltip";

const TooltipItem = (props) => {
  const {
    position = "top",
    id,
    className = "",
    cursor = "pointer",
    active = true,
    title,
    opacity,
    children,
  } = props;

  if (!active) {
    return children;
  }

  return (
    <span className={className}>
      <span
        className={`d-flex align-items-center cursor-${cursor}`}
        data-tooltip-html={title}
        data-tooltip-id={"tooltip-" + id}
        data-tooltip-place={position}
      >
        {children}
      </span>
      <Tooltip
        id={"tooltip-" + id}
        effect="solid"
        className="bg-primarylight text-dark border"
        opacity={opacity || 1}
      />
    </span>
  );
};

export default TooltipItem;
