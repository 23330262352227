import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  userData: null,
};

const ACTIONS = {
  SET_USER_DATA: "SET_USER_DATA",
  REFRESH: "REFRESH",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.SET_USER_DATA: {
      const { payload } = data;
      const { userData } = payload;

      return {
        ...state,
        userData,
      };
    }

    case ACTIONS.REFRESH: {
      return {
        ...state,
        userData: null,
      };
    }

    default:
      throw new Error();
  }
};

const AuthContext = createContext(initialState);

const AuthProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <AuthContext.Provider value={stateAndDispatch}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export { AuthContext, AuthProvider, ACTIONS };
