import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Badge,
} from "reactstrap";

import EmployeeSelector from "./EmployeeSelector";

import { useCreateCrew, useUpdateCrew } from "../api/Crews.hooks";
import { crewHelper } from "../helpers/crewHelper";

import { components, data, sharedHelper } from "@crewos/shared";

const { useWorkOrderDetails, useAuth } = data;

const { Loader } = components;

const SELECTOR_MODE_LEAD = 1;
const SELECTOR_MODE_DEFAULT = 2;

const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";
const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const CREWS_PACKAGE = "crews";

const CrewModal = ({ defaultCrew, onClose, onSubmit }) => {
  const [authContext] = useAuth();

  const crewSizeSetting = crewHelper.getCrewSizeSetting(
    authContext.userData?.packages
  );

  const [workOrderDetails] = useWorkOrderDetails();

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const {
    isLoading: isLoadingUpdateCrew,
    update: updateCrew,
    data: updateCrewData,
  } = useUpdateCrew();

  const {
    isLoading: isLoadingCreateCrew,
    mutate: createCrew,
    data: createCrewData,
  } = useCreateCrew();

  useEffect(() => {
    if (defaultCrew) {
      const selectedEmployees = crewHelper
        .filterCrew(defaultCrew, employeeCrewPastDatesEnabled)
        .map((employeeCrew) => ({
          ...employeeCrew.employee,
          isLead: employeeCrew.isLead,
          disabledAt: employeeCrew.disabledAt,
          role: employeeCrew.role,
        }));
      setSelectedEmployees(selectedEmployees);
    }
  }, [defaultCrew, setSelectedEmployees, employeeCrewPastDatesEnabled]);

  useEffect(() => {
    if (updateCrewData || createCrewData) {
      sharedHelper.successToast(`Crew ${createCrewData ? "created" : "saved"}`);
      onSubmit();
    }
  }, [createCrewData, updateCrewData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    const employees = selectedEmployees
      .filter((employee) => !employee.disabledAt)
      .map((employee) => ({
        employeeId: employee.id,
        employeeRoleId: employee.role.id,
        isLead: employee.isLead,
      }));
    if (defaultCrew) {
      await updateCrew({
        id: defaultCrew.id,
        workOrderId: defaultCrew.workOrderId,
        employees,
      });
    } else {
      await createCrew({
        workOrderId: workOrderDetails.workOrder.id,
        employees,
      });
    }
  };

  const title = `${defaultCrew ? "Edit" : "Add"}${
    directAssignationEnabled ? " Assignment" : " Crew"
  }`;

  return (
    <Modal
      isOpen={true}
      onClosed={onClose}
      size={directAssignationEnabled ? "sm" : "lg"}
    >
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          {title}
          {directAssignationEnabled && !crewSizeSetting.status ? (
            <Badge color="warning">Max crew size {crewSizeSetting.value}</Badge>
          ) : null}
        </div>
      </ModalHeader>
      <ModalBody className="text-center d-flex flex-wrap">
        <EmployeeSelector
          directAssignationEnabled={directAssignationEnabled}
          mode={SELECTOR_MODE_LEAD}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          selectedEmployee={selectedEmployees.find(
            (employee) => !employee.disabledAt && employee.isLead
          )}
        />
        {selectedEmployees
          .filter((employee) => !employee.disabledAt && !employee.isLead)
          .map((employee, index) => (
            <EmployeeSelector
              key={index}
              selectedEmployees={selectedEmployees}
              setSelectedEmployees={setSelectedEmployees}
              selectedEmployee={employee}
              mode={SELECTOR_MODE_DEFAULT}
            />
          ))}
        {!crewSizeSetting.status ||
        (!directAssignationEnabled &&
          crewSizeSetting.status &&
          crewSizeSetting.value > 1 &&
          selectedEmployees.length < crewSizeSetting.value) ? (
          <EmployeeSelector
            selectedEmployees={selectedEmployees}
            setSelectedEmployees={setSelectedEmployees}
          />
        ) : null}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          className="d-flex align-items-center text-dark justify-content-center"
          color="secondary"
          onClick={onClose}
        >
          <span>Discard</span>
        </Button>
        {isLoadingUpdateCrew || isLoadingCreateCrew ? (
          <div className="min-width-50">
            <Loader size="sm" />
          </div>
        ) : (
          <Button
            className="d-flex align-items-center justify-content-center"
            color="primary"
            onClick={doSubmit}
          >
            <span>Save</span>
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default CrewModal;
