import React, { useEffect, useMemo, useState } from "react";

import { Row, FormGroup, Label } from "reactstrap";

import { useGetCustomers } from "@crewos/customers";

import { components, data, sharedHelper } from "@crewos/shared";

const { useAuth } = data;

const { Select, DatesSelector, DynamicAttribute } = components;

const PAGE_SIZE_CUSTOMERS = 30;

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const WorkOrderForm = ({ workOrderData, setWorkOrderData }) => {
  const [authContext] = useAuth();
  const [customerSearch, setCustomerSearch] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();

  const [customerLocationSearch, setCustomerLocationSearch] = useState();
  const [selectedCustomerLocation, setSelectedCustomerLocation] = useState();
  const [customerLocationsOptions, setCustomerLocationsOptions] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState();

  const {
    data: customers,
    isLoading: isLoadingCustomers,
    get: getCustomers,
  } = useGetCustomers();

  useEffect(() => {
    if (
      authContext.userData.workOrderStatus &&
      workOrderData?.workOrderStatusId
    ) {
      const selectedStatus = authContext.userData.workOrderStatus?.find(
        (s) => s.id === workOrderData?.workOrderStatusId
      );
      if (selectedStatus) {
        setSelectedStatus({
          value: selectedStatus?.id,
          label: selectedStatus?.name,
        });
      } else {
        setSelectedStatus();
      }
    }
  }, [authContext.userData.workOrderStatus, workOrderData]);

  useEffect(() => {
    if (customers) {
      const selectedCustomer = customers.data.find(
        (s) => s.id === workOrderData?.customerId
      );
      if (selectedCustomer) {
        setSelectedCustomer({
          value: selectedCustomer.id,
          label: selectedCustomer.customerName,
        });
      } else {
        setSelectedCustomer();
      }
    }
  }, [customers, workOrderData]);

  useEffect(() => {
    if (customers) {
      const selectedCustomer = customers.data.find(
        (s) => s.id === workOrderData?.customerId
      );
      if (selectedCustomer) {
        const selectedLocation = selectedCustomer.locations.find(
          (s) => s.id === workOrderData?.customerLocationId
        );
        const customerLocationsOptions =
          selectedCustomer?.locations.map((option) => ({
            label: sharedHelper.getAddress(option),
            value: option.id,
          })) || [];
        setCustomerLocationsOptions(customerLocationsOptions);
        if (selectedLocation) {
          setSelectedCustomerLocation({
            value: selectedLocation.id,
            label: sharedHelper.getAddress(selectedLocation),
          });
        } else {
          setSelectedCustomerLocation();
        }
      } else {
        setSelectedCustomerLocation();
      }
    }
  }, [customers, workOrderData]);

  useEffect(() => {
    getCustomers({
      search: customerSearch,
      pageSize: PAGE_SIZE_CUSTOMERS,
    });
  }, [getCustomers, customerSearch]);

  const customersOptions = (customers?.data || []).map((option) => ({
    label: option.customerName,
    value: option.id,
  }));

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  const statusSelect = useMemo(() => {
    return authContext.userData.workOrderStatus?.map((status) => ({
      value: status.id,
      label: status.name,
    }));
  }, [authContext.userData.workOrderStatus]);

  const internalSelect = useMemo(() => {
    return [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];
  }, []);

  const defaultInternal = useMemo(() => {
    return internalSelect.find(
      (option) => option.value === workOrderData.isInternal
    );
  }, [workOrderData.isInternal, internalSelect]);

  const dynamicAttributes = useMemo(
    () =>
      sharedHelper.getSettingValue(
        authContext.userData?.packages,
        "workorders",
        "WO_DYNAMIC_ATTRIBUTES"
      ) || [],
    [authContext.userData]
  );

  return (
    <Row className="px-0">
      <FormGroup className="col-6 px-3">
        <Label>
          <span>Work Order #</span>
          <span className="text-danger ms-1">*</span>
        </Label>
        <input
          maxLength="100"
          type="text"
          className="form-control-redesign"
          placeholder="Enter the WO#"
          name="workOrderNumber"
          value={workOrderData.workOrderNumber || ""}
          onChange={(e) =>
            setWorkOrderData({
              ...workOrderData,
              workOrderNumber: e.target.value,
            })
          }
          required
        />
      </FormGroup>
      <FormGroup className="col-6 px-3">
        <Label>
          <span>Status</span>
          <span className="text-danger ms-1">*</span>
        </Label>
        <Select
          placeholder="Select a status.."
          options={statusSelect}
          value={selectedStatus}
          onChange={(selected) => {
            setWorkOrderData({
              ...workOrderData,
              workOrderStatusId: selected?.value,
            });
          }}
        />
      </FormGroup>
      {workOrderData.isInternal ? null : (
        <>
          <FormGroup className="col-6 px-3">
            <Label>
              <span>Customer</span>
            </Label>
            <Select
              placeholder="Search for a customer.."
              data-testid="customer-select"
              noOptionsMessage={() => "No customers found"}
              options={customersOptions}
              isSearchable
              isClearable
              inputValue={customerSearch}
              value={selectedCustomer}
              onInputChange={setCustomerSearch}
              isLoading={isLoadingCustomers}
              onChange={(selected) => {
                setWorkOrderData({
                  ...workOrderData,
                  customerId: selected?.value,
                });
              }}
            />
          </FormGroup>
          <FormGroup className="col-6 px-3">
            {selectedCustomer ? (
              <>
                <Label>
                  <span>Customer Location</span>
                </Label>
                <Select
                  placeholder="Search for a location.."
                  data-testid="customer-location-select"
                  noOptionsMessage={() => "No locations found"}
                  options={customerLocationsOptions}
                  isSearchable
                  isClearable
                  inputValue={customerLocationSearch}
                  value={selectedCustomerLocation}
                  onInputChange={setCustomerLocationSearch}
                  isLoading={isLoadingCustomers}
                  onChange={(selected) => {
                    setWorkOrderData({
                      ...workOrderData,
                      customerLocationId: selected?.value,
                    });
                  }}
                />
              </>
            ) : null}
          </FormGroup>
        </>
      )}
      <FormGroup className="col-6 px-3" noMargin={true}>
        <DatesSelector
          defaultStartDate={workOrderData.startDate}
          defaultEndDate={workOrderData.endDate}
          onSubmit={(startDate, endDate) => {
            setWorkOrderData({
              ...workOrderData,
              startDate,
              endDate,
            });
          }}
        />
      </FormGroup>
      {isSuperAdmin ? (
        <FormGroup className="col-6 px-3">
          <Label>
            <span>Is Internal</span>
          </Label>
          <Select
            id="internalSelect"
            name="internalSelect"
            onChange={(selected) => {
              const isInternal = selected.value;
              const data = {
                ...workOrderData,
                isInternal,
              };
              if (isInternal) {
                workOrderData.customerId = null;
                workOrderData.customerLocationId = null;
              }
              setWorkOrderData(data);
            }}
            defaultValue={defaultInternal}
            options={internalSelect}
            required
          />
        </FormGroup>
      ) : null}
      {dynamicAttributes.map((dynamicAttribute) => (
        <FormGroup className="col-6 px-3" key={dynamicAttribute.id}>
          <Label>
            <span>{dynamicAttribute.label}</span>
            {dynamicAttribute.required ? (
              <span className="text-danger ms-1">*</span>
            ) : null}
          </Label>
          <DynamicAttribute
            dynamicAttribute={dynamicAttribute}
            data={workOrderData}
            setData={setWorkOrderData}
          />
        </FormGroup>
      ))}
    </Row>
  );
};

export default WorkOrderForm;
