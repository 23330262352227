import React from "react";

import { useNavigate } from "react-router";

import { ListGroup, ListGroupItem, Table } from "reactstrap";

const CustomerWorkOrders = ({ customer }) => {
  const navigate = useNavigate();

  return (
    <div className="col-12 col-lg-6 px-2">
      <ListGroup flush className="col-12 px-0">
        <ListGroupItem
          className="py-2 bg-graylight border-0 border-radius-default fw-bold text-center"
          tag="div"
          key="0"
        >
          Work Orders
        </ListGroupItem>
        {!customer.workOrders.length ? (
          <ListGroupItem
            className="text-muted small border-bottom py-2 px-0 mx-3 d-flex justify-content-center align-items-center"
            tag="div"
            key="1"
          >
            No work orders
          </ListGroupItem>
        ) : (
          <Table className="col-12 mb-0 overflow-hidden mt-2" striped>
            <thead className="small text-muted">
              <tr className="bg-graylight">
                <th>WO #</th>
                <th>Start Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              {customer.workOrders.map((element) => (
                <tr
                  onClick={() => navigate(`/workorders/details/${element.id}`)}
                  key={element.id}
                  className="cursor-pointer"
                >
                  <td>
                    {element.workOrderNumber
                      ? `#${element.workOrderNumber}`
                      : `Without WO# (${element.id})`}
                  </td>
                  <td>{element.startDate}</td>
                  <td>{element.endDate}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </ListGroup>
    </div>
  );
};

export default CustomerWorkOrders;
