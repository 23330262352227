import React, { useState } from "react";

import { Row, Col, Card, CardBody, Button } from "reactstrap";

import { data } from "@crewos/shared";
import JobDescriptionModal from "./JobDescriptionModal";

const { useWorkOrderDetails } = data;

const WorkOrderJobDescription = () => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [jobDescriptionModal, setJobDescriptionModal] = useState();

  const onEdit = () => setJobDescriptionModal(true);

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 px-0 box-shadow-none mb-3">
          <CardBody className="d-flex justify-content-between align-items-center">
            <h2 className="mb-0">Job Description</h2>
            <div className="d-flex align-items-center">
              <Button color="primary" onClick={onEdit}>
                Edit
              </Button>
            </div>
          </CardBody>
        </Card>
        <Card className="w-100 px-0 box-shadow-none mb-3">
          <CardBody className="d-flex justify-content-between align-items-center">
            <div className="w-100 px-0">
              {workOrderDetails.workOrder.description ? (
                <div className="mb-3 py-3 px-3 d-flex justify-content-between align-items-center bg-white col-12 border-radius-default">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: workOrderDetails.workOrder.description,
                    }}
                  />
                </div>
              ) : (
                <div className="text-center text-muted py-1 small">
                  No data to display
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </Row>
      {jobDescriptionModal ? (
        <JobDescriptionModal onClose={() => setJobDescriptionModal()} />
      ) : null}
    </Col>
  );
};

export default WorkOrderJobDescription;
