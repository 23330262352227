import React, { useMemo, useEffect } from "react";

import { Sidebar } from "react-pro-sidebar-crewos";

import {
  useSidebar,
  ACTIONS as SIDEBAR_ACTIONS,
} from "../../providers/sidebarProvider";

import SidebarUser from "./SidebarUser";
import SidebarFeedback from "./SidebarFeedback";
import SidebarPoweredBy from "./SidebarPoweredBy";
import SidebarBrand from "./SidebarBrand";

import { useAuth } from "../../providers/authProvider";
import { MenuRoutes } from "./MenuRoutes";

import { getRoutes } from "../../helpers/sidebar";

const SidebarComponent = () => {
  const [authContext] = useAuth();
  const [sidebarContext, setSidebarContext] = useSidebar();

  useEffect(() => {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.RESET_ROUTES,
    });
  }, [authContext.userData, setSidebarContext]);

  const routes = useMemo(() => {
    if (!authContext.userData) {
      return [];
    }
    return getRoutes(sidebarContext.routes, authContext.userData);
  }, [sidebarContext.routes, authContext.userData]);

  return (
    <Sidebar collapsed={!sidebarContext.isOpen} backgroundColor="white">
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <SidebarBrand />
        <SidebarUser />
        <div className="d-flex flex-column justify-content-between flex-grow-1 bg-white">
          <div style={{ margin: ".5rem 1rem" }}>
            <MenuRoutes routes={routes} />
          </div>
          <div className="d-flex flex-column align-items-center gap-2 pb-4">
            <SidebarFeedback />
            <SidebarPoweredBy />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default SidebarComponent;
