import { useEffect, useState, useRef } from "react";

const useElementHeight = (className) => {
  const [height, setHeight] = useState(null);
  const resizeObserverRef = useRef(null);
  const mutationObserverRef = useRef(null);
  const observedElementRef = useRef(null);

  useEffect(() => {
    const observeElement = (element) => {
      if (!element) return;

      // Si ya estamos observando otro elemento, lo dejamos de observar
      if (
        resizeObserverRef.current &&
        observedElementRef.current &&
        observedElementRef.current !== element
      ) {
        resizeObserverRef.current.unobserve(observedElementRef.current);
      }

      // Crear un ResizeObserver si no existe
      if (!resizeObserverRef.current) {
        resizeObserverRef.current = new ResizeObserver((entries) => {
          for (let entry of entries) {
            // Verificar si el elemento sigue conectado al DOM
            if (entry.target.isConnected) {
              setHeight(entry.target.offsetHeight);
            } else {
              // No actualizar la altura si el elemento ha sido desconectado
              // Puedes decidir si quieres establecer height en null aquí
            }
          }
        });
      }

      resizeObserverRef.current.observe(element);
      observedElementRef.current = element;

      // Actualizar la altura inicialmente
      setHeight(element.offsetHeight);
    };

    const checkForElement = () => {
      const element = document.querySelector(`.${className}`);

      if (element !== observedElementRef.current) {
        if (element) {
          observeElement(element);
        } else {
          // Verificar si el elemento previamente observado sigue conectado
          if (
            observedElementRef.current &&
            observedElementRef.current.isConnected
          ) {
            // El elemento sigue en el DOM, no hacemos nada
          } else {
            // El elemento realmente ha sido eliminado
            if (resizeObserverRef.current && observedElementRef.current) {
              resizeObserverRef.current.unobserve(observedElementRef.current);
              observedElementRef.current = null;
            }
            setHeight(null);
          }
        }
      }
    };

    // Crear y configurar el MutationObserver
    if (!mutationObserverRef.current) {
      mutationObserverRef.current = new MutationObserver(() => {
        checkForElement();
      });
    }

    mutationObserverRef.current.observe(document.body, {
      childList: true,
      subtree: true,
    });

    // Comprobación inicial
    checkForElement();

    return () => {
      if (resizeObserverRef.current && observedElementRef.current) {
        resizeObserverRef.current.unobserve(observedElementRef.current);
      }
      if (mutationObserverRef.current) {
        mutationObserverRef.current.disconnect();
      }
    };
  }, [className]);

  return height;
};

export default useElementHeight;
