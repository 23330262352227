import React, { useState, useEffect } from "react";

import { Row, Col } from "reactstrap";

import WorkOrderModal from "../WorkOrderModal";

import { WorkPerformed } from "@crewos/crews";

import { data, components, sharedHelper } from "@crewos/shared";

import { useNavigate } from "react-router-dom";

import { useDeleteWorkOrder } from "../../api/WorkOrders.hooks";

const { ConfirmationModal } = components;

const { useWorkOrderDetails, WORK_ORDER_DETAILS_ACTIONS } = data;

const WorkOrderJobDetails = () => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const { data: deleteWorkOrderData, deleteWorkOrder } = useDeleteWorkOrder();

  const navigate = useNavigate();

  const [editModal, setEditModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  useEffect(() => {
    if (deleteWorkOrderData) {
      sharedHelper.successToast(`Work order deleted`);
      navigate(-1);
    }
  }, [deleteWorkOrderData, navigate]);

  const onDelete = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteWorkOrder(workOrderDetails.workOrder.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Work Order",
      body: `Are you sure you want to delete ${workOrderDetails.workOrder.workOrderNumber}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  const onEdit = () => setEditModal(true);

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <WorkPerformed onEdit={onEdit} onDelete={onDelete} />
      </Row>
      {editModal ? (
        <WorkOrderModal
          workOrder={workOrderDetails.workOrder}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setWorkOrderDetails({
              action: WORK_ORDER_DETAILS_ACTIONS.REFRESH,
            });
          }}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Col>
  );
};

export default WorkOrderJobDetails;
