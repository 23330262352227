import { sharedHelper } from "@crewos/shared";
import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  crewWorkDays: {},
  workOrderId: null,
  refreshCrewSection: {},
  refresh: false,
};

const ACTIONS = {
  REFRESH_CREW_SECTION: "REFRESH_CREW_SECTION",
  REFRESH_CREW_SECTION_SUCCESS: "REFRESH_CREW_SECTION_SUCCESS",
  GET_CREW_WORK_DAYS_SUCCESS: "GET_CREW_WORK_DAYS_SUCCESS",
  REFRESH: "REFRESH",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_CREW_WORK_DAYS_SUCCESS: {
      const { payload } = data;
      const { crewWorkDays } = payload;

      const workDaysByDate =
        sharedHelper.getWorkDaysGroupedByDate(crewWorkDays);

      return {
        ...state,
        crewWorkDays: workDaysByDate,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    case ACTIONS.REFRESH_CREW_SECTION: {
      const { payload } = data;
      const { crewId, section } = payload;

      return {
        ...state,
        refreshCrewSection: { crewId, section },
      };
    }

    case ACTIONS.REFRESH_CREW_SECTION_SUCCESS: {
      return {
        ...state,
        refreshCrewSection: {},
      };
    }

    default:
      throw new Error();
  }
};

const CrewWorkDaysContext = createContext(initialState);

const CrewWorkDaysProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <CrewWorkDaysContext.Provider value={stateAndDispatch}>
      {children}
    </CrewWorkDaysContext.Provider>
  );
};

export const useCrewWorkDays = () => useContext(CrewWorkDaysContext);

export { CrewWorkDaysContext, CrewWorkDaysProvider, ACTIONS };
