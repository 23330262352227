import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  crews: [],
};

const ACTIONS = {
  REFRESH: "REFRESH",
  GET_CREWS_SUCCESS: "GET_CREWS_SUCCESS",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.GET_CREWS_SUCCESS: {
      const { payload } = data;
      const { crews } = payload;

      return {
        ...state,
        crews,
      };
    }

    case ACTIONS.REFRESH:
      return {
        ...state,
        refresh: !state.refresh,
      };

    default:
      throw new Error();
  }
};

const CrewsContext = createContext(initialState);

const CrewsProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <CrewsContext.Provider value={stateAndDispatch}>
      {children}
    </CrewsContext.Provider>
  );
};

const useCrews = () => useContext(CrewsContext);

export { CrewsContext, CrewsProvider, ACTIONS, useCrews };
