import { useCallback } from "react";
import { useApiGet, useApiMutation } from "./Api.hooks";
import { HttpMethod } from "./Api";

const settingEndpoint = `/api/setting`;

export const useGetSettings = () => {
  return useApiGet(settingEndpoint);
};

export const useUpdateSetting = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, settingEndpoint);

  const update = useCallback(
    async (setting) => {
      await mutate(setting);
    },
    [mutate]
  );

  return { ...rest, update };
};
