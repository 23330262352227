import React, { useState } from "react";

import { Button, Col, Row, Card, CardBody } from "reactstrap";

import { components } from "@crewos/shared";

import CrewWorkDay from "./CrewWorkDay";
import AddExpenseModal from "./AddExpenseModal";

import { useCrewWorkDays, useCrews } from "@crewos/crews";
import ExpensesCards from "./ExpensesCards";

const { WorkOrderDateSelector } = components;

const CrewsExpenses = () => {
  const [crewsContext] = useCrews();
  const [crewWorkDaysContext] = useCrewWorkDays();

  const [addExpenseModal, setAddExpenseModal] = useState(false);

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 px-0 mb-3 box-shadow-none">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="mb-0">Expenses</h2>
              <Button color="primary" onClick={() => setAddExpenseModal(true)}>
                Add Expense
              </Button>
            </div>
            <ExpensesCards />
            <WorkOrderDateSelector
              crewWorkDays={crewWorkDaysContext.crewWorkDays}
            />
          </CardBody>
        </Card>
        {crewsContext.crews.length ? (
          crewsContext.crews.map((crew) => (
            <CrewWorkDay key={crew.id} crew={crew} />
          ))
        ) : (
          <div className="p-3 small text-muted text-center bg-white border-radius-default">
            Start by creating your first crew
          </div>
        )}
      </Row>
      {addExpenseModal ? (
        <AddExpenseModal onClose={() => setAddExpenseModal(false)} />
      ) : null}
    </Col>
  );
};

export default CrewsExpenses;
