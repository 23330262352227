import React from "react";

import { ListGroup, ListGroupItem } from "reactstrap";

const CustomerContactInfo = ({ customer }) => {
  return (
    <div className="col-12 col-lg-6 px-2 mt-4 mt-lg-0">
      <ListGroup flush className="col-12 px-0">
        <ListGroupItem
          className="py-2 bg-graylight border-0 border-radius-default fw-bold text-center"
          tag="div"
          key="0"
        >
          Contact Info
        </ListGroupItem>
        <ListGroupItem
          className="border-bottom py-2 px-0 mx-3 d-flex justify-content-between align-items-center"
          tag="div"
        >
          <span>Address:</span>
          <span>{customer.addressLine1 || "-"}</span>
        </ListGroupItem>
        <ListGroupItem
          className="border-bottom py-2 px-0 mx-3 d-flex justify-content-between align-items-center"
          tag="div"
        >
          <span>City:</span>
          <span>{customer.city || "-"}</span>
        </ListGroupItem>
        <ListGroupItem
          className="border-bottom py-2 px-0 mx-3 d-flex justify-content-between align-items-center"
          tag="div"
        >
          <span>Country:</span>
          <span>{customer.countryCode || "-"}</span>
        </ListGroupItem>
        <ListGroupItem
          className="border-bottom py-2 px-0 mx-3 d-flex justify-content-between align-items-center"
          tag="div"
        >
          <span>Telephone:</span>
          <span>{customer.phone || "-"}</span>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default CustomerContactInfo;
