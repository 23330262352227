import { useCallback } from "react";

import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const noteEndpoint = `/api/note`;

export const useGetNotes = () => {
  return useApiGet(noteEndpoint);
};

export const useCreateNote = () => {
  return useApiMutation(HttpMethod.POST, noteEndpoint);
};

export const useUpdateNote = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, noteEndpoint);

  const update = useCallback(
    async (note) => {
      await mutate(note);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteNote = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, noteEndpoint);

  const deleteNote = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteNote };
};
