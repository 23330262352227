import moment from "moment";

export const relevantDiffDates = (startTime, endTime) => {
  const startTimeMoment = moment.utc(startTime);
  const endTimeMoment = moment.utc(endTime);

  const diffYears = endTimeMoment.diff(startTimeMoment, "years");
  if (diffYears > 0) {
    return `${diffYears} year${diffYears > 1 ? "s" : ""}`;
  }
  const diffMonths = endTimeMoment.diff(startTimeMoment, "months");
  if (diffMonths > 0) {
    return `${diffMonths} month${diffMonths > 1 ? "s" : ""}`;
  }
  const diffDays = endTimeMoment.diff(startTimeMoment, "days");
  if (diffDays > 0) {
    return `${diffDays} day${diffDays > 1 ? "s" : ""}`;
  }
  const diffHours = endTimeMoment.diff(startTimeMoment, "hours");
  if (diffHours > 0) {
    return `${diffHours} hour${diffHours > 1 ? "s" : ""}`;
  }
  const diffMinutes = endTimeMoment.diff(startTimeMoment, "minutes");
  if (diffMinutes > 0) {
    return `${diffMinutes} minute${diffMinutes > 1 ? "s" : ""}`;
  }
  const diffSeconds = endTimeMoment.diff(startTimeMoment, "seconds");
  if (diffSeconds > 0) {
    return `${diffSeconds} second${diffSeconds > 1 ? "s" : ""}`;
  }
  return "few seconds";
};
