export const capitalizeFirst = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const buildBasePropertiesFromMetadata = (metadata) => {
  const properties = {};
  for (const fieldName in metadata) {
    const fieldType = metadata[fieldName];
    let propSchema = {};
    const title = capitalizeFirst(fieldName.replace(/([A-Z])/g, " $1").trim());

    if (Array.isArray(fieldType)) {
      // Enum type
      propSchema = {
        type: "string",
        title: title,
        enum: fieldType,
      };
    } else {
      // Single type
      switch (fieldType) {
        case "STRING":
          propSchema = { type: "string", title };
          break;
        case "NUMBER":
          propSchema = { type: "number", title };
          break;
        case "BOOLEAN":
          propSchema = { type: "boolean", title };
          break;
        default:
          // Fallback to string if unknown
          propSchema = { type: "string", title };
      }
    }

    properties[fieldName] = propSchema;
  }
  return properties;
};

export const attributeSchema = (metadata) => {
  // Build base properties
  const properties = buildBasePropertiesFromMetadata(metadata);

  // Required top-level fields
  const requiredFields = ["id", "label", "type"].filter((f) => f in metadata);

  // Define which fields appear for each type
  const textFields = [
    "required",
    "placeholder",
    "maxLength",
    "minLength",
  ].filter((f) => f in metadata);
  const numberFields = ["required", "placeholder", "max", "min", "step"].filter(
    (f) => f in metadata
  );
  const currencyFields = [
    "required",
    "placeholder",
    "max",
    "min",
    "step",
    "allowNegativeValue",
  ].filter((f) => f in metadata);

  const oneOf = [];

  if (textFields.length > 0) {
    oneOf.push({
      properties: {
        type: { enum: ["TEXT"] },
        ...textFields.reduce((acc, f) => {
          acc[f] = properties[f];
          return acc;
        }, {}),
      },
    });
  }

  if (numberFields.length > 0) {
    oneOf.push({
      properties: {
        type: { enum: ["NUMBER"] },
        ...numberFields.reduce((acc, f) => {
          acc[f] = properties[f];
          return acc;
        }, {}),
      },
    });
  }

  if (currencyFields.length > 0) {
    oneOf.push({
      properties: {
        type: { enum: ["CURRENCY"] },
        ...currencyFields.reduce((acc, f) => {
          acc[f] = properties[f];
          return acc;
        }, {}),
      },
    });
  }

  const schema = {
    type: "object",
    required: requiredFields,
    properties: {
      ...properties,
    },
    dependencies: {
      type: {
        oneOf,
      },
    },
  };

  // Set a default for type if possible
  if (
    schema.properties.type &&
    schema.properties.type.enum &&
    schema.properties.type.enum.includes("TEXT")
  ) {
    schema.properties.type.default = "TEXT";
  }

  return schema;
};

export const attributeUiSchema = (metadata) => {
  const order = [
    "id",
    "label",
    "type",
    "required",
    "placeholder",
    "maxLength",
    "minLength",
    "max",
    "min",
    "step",
    "allowNegativeValue",
  ].filter((f) => f in metadata);

  const uiSchema = {
    "ui:order": order,
    "ui:submitButtonOptions": {
      norender: true, // Hide the default submit button
    },
    // Add padding around the form container for better spacing
    "ui:classNames": "p-3",
  };

  for (const fieldName in metadata) {
    const fieldType = metadata[fieldName];
    if (fieldType === "STRING") {
      uiSchema[fieldName] = {
        "ui:placeholder": `Enter a ${fieldName}...`,
      };
    }

    if (Array.isArray(fieldType)) {
      uiSchema[fieldName] = {
        ...(uiSchema[fieldName] || {}),
        "ui:widget": "select",
      };
    }
  }

  // Make the "id" field read-only
  if ("id" in metadata) {
    uiSchema["id"] = {
      ...(uiSchema["id"] || {}),
      "ui:disabled": true,
    };
  }

  return uiSchema;
};
