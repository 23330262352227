import { useCallback } from "react";
import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/non-job-time-type`;

export const useGetAllNonJobTimeTypes = () => {
  return useApiGet(`${endpoint}/all`);
};

export const useGetNonJobTimeTypes = () => {
  return useApiGet(endpoint);
};

export const useRegenerateNonJobTimeType = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, `${endpoint}/re`);

  const regenerate = useCallback(
    async (nonJobTimeType) => {
      await mutate(nonJobTimeType);
    },
    [mutate]
  );

  return { ...rest, regenerate };
};
export const useUpdateNonJobTimeType = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (nonJobTimeType) => {
      await mutate(nonJobTimeType);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useCreateNonJobTimeType = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useDeleteNonJobTimeType = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteNonJobTimeType = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteNonJobTimeType };
};
