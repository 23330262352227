/* eslint-disable react/prop-types */
import React, { useState } from "react";

import ReactPaginate from "react-paginate";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import Icon from "../Icon";

export const AdvanceTablePagination = ({
  pageCount,
  currentPage,
  onPageChange,
  pageSize,
  onPageSizeChange,
  totalCount,
  rowsPerPageOptions = [5, 10, 15],
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="d-flex justify-content-between align-items-center position-relative small">
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        direction="down"
        size="sm"
      >
        <DropdownToggle caret={true} className="text-dark">
          {pageSize === totalCount ? "All" : pageSize}
        </DropdownToggle>
        <DropdownMenu container="body">
          {rowsPerPageOptions.map((value) => (
            <DropdownItem
              key={value}
              className="fw-normal"
              onClick={() => onPageSizeChange(Number(value))}
            >
              {value}
            </DropdownItem>
          ))}
          <DropdownItem
            className="fw-normal"
            onClick={() => onPageSizeChange(totalCount)}
          >
            All
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <ReactPaginate
        forcePage={currentPage}
        previousLabel={<Icon name="chevron-left" data-testid="page-back" />}
        nextLabel={<Icon name="chevron-right" data-testid="page-next" />}
        breakLabel=".."
        pageCount={pageCount || currentPage + 1}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={(event) => onPageChange(event.selected + 1)}
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="cursor-pointer pagination d-flex justify-content-center align-items-center mb-0"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
      />
    </div>
  );
};

export default AdvanceTablePagination;
