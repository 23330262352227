import { useCallback } from "react";
import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/equipment-option`;

export const useGetEquipmentOptions = () => {
  return useApiGet(endpoint);
};

export const useCreateEquipmentOption = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateEquipmentOption = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (equipmentOption) => {
      await mutate(equipmentOption);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteEquipmentOption = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteEquipmentOption = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteEquipmentOption };
};
