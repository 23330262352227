import React from "react";

import { Col, Row, FormGroup, Label } from "reactstrap";

const CustomerLocationForm = ({
  customerLocationData,
  setCustomerLocationData,
  width = 100,
}) => {
  return (
    <Col className={`w-${width} px-0`}>
      <Row>
        <FormGroup className="col-6">
          <Label>
            <span>Ship To Name</span>
            <span className="text-danger ms-1">*</span>
          </Label>
          <input
            required={true}
            className="form-control-redesign"
            maxLength="50"
            type="text"
            placeholder="Enter the Ship To Name"
            name="shipToName"
            value={customerLocationData.shipToName || ""}
            onChange={(e) =>
              setCustomerLocationData({
                ...customerLocationData,
                shipToName: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup className="col-6">
          <Label>
            <span>Ship To Code</span>
          </Label>
          <input
            className="form-control-redesign"
            maxLength="50"
            type="text"
            placeholder="Enter the Ship To Code"
            name="shipToCode"
            value={customerLocationData.shipToCode || ""}
            onChange={(e) =>
              setCustomerLocationData({
                ...customerLocationData,
                shipToCode: e.target.value,
              })
            }
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col-6">
          <Label>
            <span>Ship To Address</span>
          </Label>
          <input
            className="form-control-redesign"
            maxLength="50"
            type="text"
            placeholder="Enter the Ship To Address"
            name="shipToAddres"
            value={customerLocationData.shipToAddress || ""}
            onChange={(e) =>
              setCustomerLocationData({
                ...customerLocationData,
                shipToAddress: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup className="col-6">
          <Label>
            <span>Ship To City</span>
          </Label>
          <input
            className="form-control-redesign"
            maxLength="50"
            type="text"
            placeholder="Enter the Ship To City"
            name="shipToCity"
            value={customerLocationData.shipToCity || ""}
            onChange={(e) =>
              setCustomerLocationData({
                ...customerLocationData,
                shipToCity: e.target.value,
              })
            }
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col-6">
          <Label>
            <span>Ship To State</span>
          </Label>
          <input
            className="form-control-redesign"
            maxLength="25"
            type="text"
            placeholder="Enter the Ship To State"
            name="shipToState"
            value={customerLocationData.shipToState || ""}
            onChange={(e) =>
              setCustomerLocationData({
                ...customerLocationData,
                shipToState: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup className="col-6">
          <Label>
            <span>Ship To Zip Code</span>
          </Label>
          <input
            className="form-control-redesign"
            maxLength="25"
            type="text"
            placeholder="Enter the Ship To Zip Code"
            name="shipToZipCode"
            value={customerLocationData.shipToZipCode || ""}
            onChange={(e) =>
              setCustomerLocationData({
                ...customerLocationData,
                shipToZipCode: e.target.value,
              })
            }
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col-6">
          <Label>
            <span>Ship To Country Code</span>
          </Label>
          <input
            className="form-control-redesign"
            maxLength="10"
            type="text"
            placeholder="Enter the Ship To Country Code"
            name="shipToCountryCode"
            value={customerLocationData.shipToCountryCode || ""}
            onChange={(e) =>
              setCustomerLocationData({
                ...customerLocationData,
                shipToCountryCode: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup className="col-6">
          <Label>
            <span>Phone</span>
          </Label>
          <input
            className="form-control-redesign"
            maxLength="25"
            type="text"
            placeholder="Enter the Phone"
            name="phone"
            value={customerLocationData.phone || ""}
            onChange={(e) =>
              setCustomerLocationData({
                ...customerLocationData,
                phone: e.target.value,
              })
            }
          />
        </FormGroup>
      </Row>
    </Col>
  );
};

export default CustomerLocationForm;
