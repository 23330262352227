import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  UncontrolledAlert,
} from "reactstrap";

import { api, components, data, sharedHelper } from "@crewos/shared";

import poweredCrewOS from "../assets/img/logo_powered.svg";
import brand from "../assets/img/logoPrimary.png";
import powered from "../assets/img/powered.svg";

const { useAuth, AUTH_ACTIONS } = data;
const { authApi } = api;
const { Loader, Icon } = components;

export const LoginForm = () => {
  const [, setAuthContext] = useAuth();

  const [loginLogo, setLoginLogo] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const logo = await authApi.loginLogo();
        setLoginLogo(logo || brand);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        sharedHelper.errorToast(err);
      }
    };
    fetchData();
  }, []);

  const onDismiss = () => setError(false);

  const doLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userData = await authApi.login({
        email: email.toLowerCase(),
        password,
      });
      setLoading(false);
      if (!userData) {
        return null;
      }
      setAuthContext({
        action: AUTH_ACTIONS.SET_USER_DATA,
        payload: { userData },
      });
    } catch (err) {
      setLoading(false);
      sharedHelper.errorToast(err);
    }
  };

  return (
    <div className="d-flex flex-column h-100 justify-content-between">
      <Card className="login-box mx-auto bg-white">
        <CardBody className="form-container mx-auto d-flex flex-column align-items-center">
          <div
            style={{ minHeight: 250 }}
            className="d-flex align-items-center justify-content-around flex-column flex-nowrap"
          >
            {loginLogo ? (
              <img
                src={loginLogo}
                className="my-3"
                style={{ maxHeight: 200, width: "auto", maxWidth: "100%" }}
                alt={"CrewOS"}
              />
            ) : (
              <div
                style={{ minHeight: 50 }}
                className="d-flex align-items-center"
              >
                <Loader size="sm" />
              </div>
            )}
            <h1 className="mt-3 mb-5 text-primary">Welcome Back!</h1>
          </div>
          <Form onSubmit={doLogin} className="flex-grow-1 d-flex flex-column">
            <FormGroup className="d-flex flex-column">
              <Label className="fw-bold" for="emailUser">
                Email
              </Label>
              <input
                className="mb-2 login-input"
                id="emailUser"
                data-test-id="email-input"
                maxLength="50"
                required={true}
                name="email"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value.trim())}
              />
            </FormGroup>
            <FormGroup className="d-flex flex-column">
              <Label className="fw-bold" for="passwordUser">
                Password
              </Label>
              <input
                className="login-input"
                id="passwordUser"
                required={true}
                maxLength="50"
                type="password"
                name="password"
                placeholder="Enter your password"
                value={password}
                onChange={(event) => setPassword(event.currentTarget.value)}
              />
            </FormGroup>
            <div className="text-center mt-3 flex-grow-1 d-flex flex-column justify-content-center">
              <UncontrolledAlert
                isOpen={!!error}
                toggle={onDismiss}
                color="warning"
              >
                <div className="alert-icon">
                  <Icon name="bell" />
                </div>
                <div className="alert-message text-start">
                  <span className="ms-2">{error}</span>
                </div>
              </UncontrolledAlert>
              {loading ? (
                <Loader size="sm" />
              ) : (
                <Button
                  className="w-100"
                  id="signIn"
                  color="primary"
                  type="submit"
                  size="lg"
                >
                  LOGIN
                </Button>
              )}
              <small className="mt-4 d-none">
                <Link to="/auth/reset">Forgot your password?</Link>
              </small>
            </div>
          </Form>
        </CardBody>
      </Card>
      <div className="d-flex flex-column mx-auto pb-4 align-items-end">
        <img alt={"CrewOS"} className="powered" src={powered} />
        <img alt={"CrewOS"} src={poweredCrewOS} />
      </div>
    </div>
  );
};
