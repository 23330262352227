import { useCallback } from "react";

import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const packagesEndpoint = `/api/package`;

export const useGetAllPackages = () => {
  return useApiGet(`${packagesEndpoint}/all`);
};

export const useGetPackages = () => {
  return useApiGet(packagesEndpoint);
};

export const useUpdatePackage = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, packagesEndpoint);

  const update = useCallback(
    async (pkg) => {
      await mutate(pkg);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeletePackage = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    packagesEndpoint
  );

  const deletePackage = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deletePackage };
};
