import moment from "moment";

import { sharedHelper } from "@crewos/shared";

const MAX_CREW_SIZE = "MAX_CREW_SIZE";

export const crewHelper = {
  removeDuplicatesCrew: (employeeCrews) => {
    /*
      Single Active Lead Rule: 
        There can only be one lead in the list. 
        This lead must either be the active one (i.e., disabledAt is null), or if no active lead exists, it should be the one most recently deactivated (the one with the most recent disabledAt timestamp).
      Unique Employee Rule: 
        An employee can only appear once in the resulting list. 
        If the same employee appears multiple times in the source list, retain only the active entry (i.e., disabledAt is null).
        If there is no active entry, keep the one that was most recently deactivated (the one with the most recent disabledAt timestamp).
    */
    const byEmployee = employeeCrews.reduce((map, r) => {
      (map[r.employeeId] = map[r.employeeId] || []).push(r);
      return map;
    }, {});

    const selectRecord = (arr) => {
      const active = arr.filter((r) => r.disabledAt === null);
      if (active.length > 0) return active[0];

      return arr.reduce(
        (latest, r) =>
          !latest || moment(r.disabledAt).isAfter(moment(latest.disabledAt))
            ? r
            : latest,
        null
      );
    };

    // One record per employee
    const filtered = Object.values(byEmployee).map(selectRecord);

    // Choose the lead
    const leads = filtered.filter((r) => r.isLead);
    if (leads.length === 0) return filtered;

    const activeLeads = leads.filter((l) => l.disabledAt === null);
    const chosenLead =
      activeLeads.length > 0
        ? activeLeads[0]
        : leads.reduce(
            (latest, l) =>
              !latest || moment(l.disabledAt).isAfter(moment(latest.disabledAt))
                ? l
                : latest,
            null
          );

    return filtered.filter((r) => !r.isLead || r.id === chosenLead.id);
  },
  isEmployeeCrewActiveOnDate: (
    employeeCrewPastDatesEnabled,
    employeeCrew,
    date = moment()
  ) => {
    //if employeeCrewPastDatesEnabled is true, shows employees that were added after date
    const isCreatedBefore =
      employeeCrewPastDatesEnabled ||
      moment(employeeCrew.createdAt).isSameOrBefore(moment(date), "date");
    const isDisabledAfter =
      !employeeCrew.disabledAt ||
      moment(employeeCrew.disabledAt).isAfter(moment(date), "date");
    const isEmployeeCrewActiveOnDate = isCreatedBefore && isDisabledAfter;
    return isEmployeeCrewActiveOnDate;
  },
  filterCrew: (crew, employeeCrewPastDatesEnabled, date = moment()) =>
    crewHelper
      .removeDuplicatesCrew(crew.employeeCrews)
      .filter((ec) =>
        crewHelper.isEmployeeCrewActiveOnDate(
          employeeCrewPastDatesEnabled,
          ec,
          date
        )
      )
      .sort(sharedHelper.sortEmployeeCrew),
  getCrewLead: (crew, employeeCrewPastDatesEnabled, date) =>
    crewHelper
      .filterCrew(crew, employeeCrewPastDatesEnabled, date)
      .find((ec) => ec.isLead),
  getCrewSizeSetting: (packages) => {
    const crewPackage = packages.find((p) => p.path === "crews");
    const crewSizeSetting = crewPackage?.settings.find(
      (s) => s.name === MAX_CREW_SIZE
    );
    return crewSizeSetting;
  },
};
