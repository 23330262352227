import React from "react";

import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { data } from "@crewos/shared";

const { useAuth } = data;

const Page404 = () => {
  const [authContext] = useAuth();
  const navigate = useNavigate();

  return (
    <div className="text-center h-100 d-flex flex-column align-items-center mt-5">
      <h1 className="display-1 fw-bold">404</h1>
      <p className="h1">Page not found.</p>
      <p className="h2 fw-normal mt-3 mb-4">
        The page you are looking for might have been removed.
      </p>
      <Button
        onClick={() =>
          authContext.userData
            ? navigate("/workorders")
            : navigate("/auth/sign-in")
        }
        color="primary"
        size="lg"
      >
        Return to website
      </Button>
    </div>
  );
};

export default Page404;
