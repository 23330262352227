import React from "react";

import { Label, ListGroup, ListGroupItem } from "reactstrap";

import moment from "moment";

import { data, sharedHelper } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { useAuth } = data;

const START_END_WORK_TIME_SETTING = "START_END_WORK_TIME_SETTING";
const WORK_TIMES_PACKAGE = "worktimes";

const EditWorkTimeHours = ({ workTime = {}, onWorkTimeChanged }) => {
  const [authContext] = useAuth();

  const startEndTimeSettingEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    WORK_TIMES_PACKAGE,
    START_END_WORK_TIME_SETTING
  );

  const startTime = workTime.startTime
    ? moment(workTime.startTime).format("HH:mm")
    : null;

  const endTime = workTime.endTime
    ? moment(workTime.endTime).format("HH:mm")
    : null;

  let hours = workTime.hours;
  if (startEndTimeSettingEnabled) {
    hours = (
      moment(workTime.endTime).diff(moment(workTime.startTime), "minutes") / 60
    ).toFixed(2);
  }

  return (
    <div className="w-100">
      {startEndTimeSettingEnabled ? (
        <>
          <div
            className="d-flex align-items-start flex-column py-2 col-12 px-0"
            tag="div"
          >
            <Label>
              <small>Start Time</small>
              <small className="ms-1 text-danger">*</small>
            </Label>
            <DebounceInput
              debounceTimeout={900}
              className="form-control-redesign"
              required={true}
              step="60"
              placeholder="Start Time"
              data-testid="startTime"
              type="time"
              value={startTime || ""}
              onChange={(event) => {
                const mStartTime = moment(event.target.value, "HH:mm");
                if (workTime.endTime) {
                  const mEndTime = moment(
                    moment(workTime.endTime).format("HH:mm"),
                    "HH:mm"
                  );
                  const endTime = moment(workTime.crewWorkDay.date)
                    .set("hours", mEndTime.get("hours"))
                    .set("minutes", mEndTime.get("minutes"));
                  if (mEndTime.isSameOrBefore(mStartTime)) {
                    endTime.add(1, "day");
                  }
                  workTime = onWorkTimeChanged(
                    endTime.toISOString(),
                    "endTime",
                    workTime
                  );
                }
                const startTime = moment(workTime.crewWorkDay.date)
                  .set("hours", mStartTime.get("hours"))
                  .set("minutes", mStartTime.get("minutes"))
                  .toISOString();
                onWorkTimeChanged(startTime, "startTime", workTime);
              }}
            />
          </div>
          <div
            className="d-flex align-items-start flex-column py-2 col-12 px-0"
            tag="div"
          >
            <Label className="d-flex align-items-start">
              <small>End Time</small>
              {sharedHelper.plusIndicatorEndTime(startTime, endTime)}
            </Label>
            <DebounceInput
              debounceTimeout={900}
              className="form-control-redesign"
              step="60"
              placeholder="End Time"
              data-testid="endTime"
              type="time"
              value={endTime || ""}
              onChange={(event) => {
                if (!event.target.value.length) {
                  return onWorkTimeChanged(null, "endTime", workTime);
                }
                const mEndTime = moment(event.target.value, "HH:mm");
                const endTime = moment(workTime.crewWorkDay.date)
                  .set("hours", mEndTime.get("hours"))
                  .set("minutes", mEndTime.get("minutes"));
                if (workTime.startTime) {
                  const mStartTime = moment(
                    moment(workTime.startTime).format("HH:mm"),
                    "HH:mm"
                  );
                  if (mEndTime.isSameOrBefore(mStartTime)) {
                    endTime.add(1, "day");
                  }
                }
                onWorkTimeChanged(endTime.toISOString(), "endTime", workTime);
              }}
            />
          </div>
          {startTime && endTime ? (
            <div
              className="d-flex align-items-start flex-column py-2 col-12 px-0"
              tag="div"
            >
              <Label for="hours">
                <small>Hours</small>
                <small className="ms-1 text-danger">*</small>
              </Label>
              <DebounceInput
                debounceTimeout={900}
                data-testid="hours"
                className="form-control-redesign"
                placeholder="Hours..."
                value={hours}
                disabled={true}
              />
            </div>
          ) : null}
        </>
      ) : (
        <div
          className="d-flex align-items-start flex-column py-2 col-12 px-0"
          tag="div"
        >
          <Label for="hours">
            <small>Hours</small>
            <small className="ms-1 text-danger">*</small>
          </Label>
          <DebounceInput
            debounceTimeout={900}
            data-testid="hours"
            className="form-control-redesign"
            type="number"
            placeholder="Enter the hours"
            required={true}
            max={24}
            min={0.1}
            step={0.1}
            value={hours}
            onChange={(event) =>
              onWorkTimeChanged(event.target.value, "hours", workTime)
            }
          />
        </div>
      )}
      <ListGroup className="small mt-3">
        <ListGroupItem className="d-flex justify-content-center align-items-center fw-bold bg-graylight">
          Exceptions
        </ListGroupItem>
        {workTime.exceptions.length ? (
          workTime.exceptions.map((exception) => {
            const action =
              exception.action === "ADD"
                ? "+"
                : exception.action === "SUBTRACT"
                ? "-"
                : "";
            return (
              <ListGroupItem
                key={exception.id}
                className="d-flex justify-content-between align-items-center"
              >
                <span>
                  {action}
                  {exception.amount} hour
                  {exception.amount > 1 ? "s" : ""} of {exception.name}
                </span>
              </ListGroupItem>
            );
          })
        ) : (
          <ListGroupItem className="d-flex justify-content-between align-items-center">
            None
          </ListGroupItem>
        )}
      </ListGroup>
    </div>
  );
};

export default EditWorkTimeHours;
