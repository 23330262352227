import { data, helpers, sharedHelper } from "@crewos/shared";

import { useGetEmployeeRoles } from "./api/EmployeeRoles.hooks";
import { useGetEmployees } from "./api/Employees.hooks";
import { isCrewLeadRole } from "./helpers/employeeRole";

import Employees from "./pages/Employees";

import EmployeeRoles from "./pages/settings/EmployeeRoles";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const EMPLOYEES = "EMPLOYEES";

// Routes
const routes = [
  {
    path: "/employees",
    name: "Employees",
    package: "employees",
    icon: "user",
    sidebar: true,
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    component: Employees,
  },
  {
    sidebar: true,
    icon: null,
    name: "Employee Roles",
    path: `/employees/employee-roles`,
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    package: "employees",
    category: "Employees",
    isSetting: true,
    component: EmployeeRoles,
    order: 1,
  },
];

const Routes = createRoutes(routes);

const setEmployeeSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "employees");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: EMPLOYEES, order: 6 },
    });
  }
};

export {
  Routes as EmployeeRoutes,
  isCrewLeadRole,
  setEmployeeSidebarRoutes,
  useGetEmployeeRoles,
  useGetEmployees,
};
