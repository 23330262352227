import { useCallback } from "react";

import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const roleEndpoint = `/api/employee-role`;
const userRoleScopesEndpoint = `/api/employee-role-scope`;

export const useGetEmployeeRoles = () => {
  return useApiGet(roleEndpoint);
};

export const useGetEmployeeRoleScopes = () => {
  return useApiGet(userRoleScopesEndpoint);
};

export const useCreateEmployeeRole = () => {
  return useApiMutation(HttpMethod.POST, roleEndpoint);
};

export const useUpdateEmployeeRole = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, roleEndpoint);

  const update = useCallback(
    async (Role) => {
      await mutate(Role);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteEmployeeRole = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, roleEndpoint);

  const deleteEmployeeRole = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteEmployeeRole };
};
