import { useCallback } from "react";
import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/travel-time`;

export const useGetTravelTimes = () => {
  return useApiGet(endpoint);
};

export const useCreateTravelTime = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateTravelTime = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (travelTime) => {
      await mutate(travelTime);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useBulkUpdateTravelTime = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/bulk`
  );

  const bulkUpdate = useCallback(
    async (travelTimes) => {
      await mutate(travelTimes);
    },
    [mutate]
  );

  return { ...rest, bulkUpdate };
};

export const useDeleteTravelTime = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteTravelTime = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteTravelTime };
};

export const useBulkDeleteTravelTime = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/bulk`
  );

  const bulkDeleteTravelTimes = useCallback(
    async (travelTimes) => {
      await mutate(travelTimes);
    },
    [mutate]
  );

  return { ...rest, bulkDeleteTravelTimes };
};
