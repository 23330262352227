import { useCallback } from "react";

import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const employeeNoteEndpoint = `/api/employee-note`;

export const useGetEmployeeNotes = (data) => {
  return useApiGet(employeeNoteEndpoint);
};

export const useCreateEmployeeNote = () => {
  return useApiMutation(HttpMethod.POST, employeeNoteEndpoint);
};

export const useDeleteEmployeeNote = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    employeeNoteEndpoint
  );

  const deleteEmployeeNote = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteEmployeeNote };
};
