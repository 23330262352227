import React from "react";
import { useParams } from "react-router-dom";
import { ORIGIN_PAGE } from "../../providers/inspectionTemplateProvider";
import FormTemplate from "../../components/FormTemplate";

const ViewTemplate = () => {
  const { id } = useParams();
  return <FormTemplate id={id} origin={ORIGIN_PAGE.VIEW} isReadOnly />;
};

export default ViewTemplate;
