import React, { useState, useEffect } from "react";
import PlaceholderLoading from "react-placeholder-loading";

const MODE_REGULAR = "MODE_REGULAR";

const isTest = typeof jest !== "undefined";

const ImageContainer = ({
  className,
  src,
  alt,
  width,
  height,
  mode = MODE_REGULAR,
}) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (mode !== MODE_REGULAR) {
      if (isTest) {
        setLoaded(true);
      } else {
        const img = new Image();
        img.onload = () => {
          setLoaded(true);
        };
        img.onerror = () => {
          setLoaded(true);
        };
        img.src = src;
      }
    }
  }, [mode, src]);

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  const style = {};

  if (width) {
    style.width = width;
  }

  if (height) {
    style.height = height;
  }

  return (
    <>
      {!loaded && (
        <div className={`overflow-hidden ${className}`}>
          <PlaceholderLoading
            shape="rect"
            width={width || "100%"}
            height={height || "100%"}
          />
        </div>
      )}
      {mode === MODE_REGULAR ? (
        <img
          alt={alt}
          src={src}
          className={className}
          style={{ ...style, display: loaded ? "block" : "none" }}
          onLoad={handleImageLoaded}
        />
      ) : (
        <div
          data-testid="background-div"
          className={className}
          style={{
            backgroundPosition: "center",
            backgroundImage: `url(${src})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            ...style,
            display: loaded ? "block" : "none",
          }}
        ></div>
      )}
    </>
  );
};

export default ImageContainer;
