import React from "react";
import { useParams } from "react-router-dom";
import { ORIGIN_PAGE } from "../../providers/inspectionTemplateProvider";
import FormTemplate from "../../components/FormTemplate";

const EditTemplates = () => {
  const { id } = useParams();
  return <FormTemplate id={id} origin={ORIGIN_PAGE.UPDATE} />;
};

export default EditTemplates;
