import React, { useCallback, useEffect, useState } from "react";

import { CSVLink } from "react-csv";
import { Table } from "reactstrap";

import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import moment from "moment";

import { sharedHelper } from "../../helpers/sharedHelper";
import Icon from "../Icon";
import FilterModal from "./FilterModal";

let exportClassName;

const AdvanceTable = ({
  onRowClick,
  columns = [],
  data = [],
  isLoading,
  onSort,
  defaultSort = {},
  headerClassName = "",
  rowClassName = "",
  bodyClassName = "",
  tableProps = {},
  exportable = false,
  sortable = false,
  exportName = "export.csv",
  currentColumnOrder = null,
  onColumnSort = () => {},
  isColumnSortable = false,
}) => {
  const [currentSort, setCurrentSort] = useState(defaultSort);
  const [selectedFilterColumn, setSelectedFilterColumn] = useState(null);
  const [filters, setFilters] = useState({});
  const [columnOrder, setColumnOrder] = useState(
    currentColumnOrder || columns.map((col) => col.accessor)
  );

  useEffect(() => {
    if (currentColumnOrder) {
      setColumnOrder(currentColumnOrder);
    }
  }, [currentColumnOrder]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    })
  );

  const getCellValue = useCallback(
    (row, accessor, rowIndex, isFilter) => {
      const column = columns.find((col) => col.accessor === accessor);

      if (column && column.Cell) {
        const value = column.Cell({ row, rowIndex });
        if (isFilter) {
          if (value && value.props && value.props["data-value"]) {
            return value.props["data-value"];
          }
        }
        return value;
      }

      const isNested = accessor.indexOf(".") > -1;
      const value = isNested
        ? accessor.split(".").reduce((acc, key) => acc && acc[key], row)
        : row[accessor];

      return value !== undefined && value !== null ? value : "";
    },
    [columns]
  );

  const getExportData = useCallback(() => {
    const exportHeaders = columns
      .map((column) => {
        return !column.disableExport && column.accessor !== "id"
          ? {
              label: column.header,
              key: column.accessor,
            }
          : null;
      })
      .filter(Boolean);

    const exportData = data.map((row, rowIndex) => {
      const formattedRow = {};
      columns
        .filter((column) =>
          exportHeaders.find(({ key }) => key === column.accessor)
        )
        .forEach((column) => {
          formattedRow[column.accessor] = getCellValue(
            row,
            column.accessor,
            rowIndex
          );
        });
      return formattedRow;
    });

    return { exportHeaders, exportData };
  }, [columns, data, getCellValue]);

  useEffect(() => {
    if (exportable) {
      const { exportHeaders, exportData } = getExportData();

      let element;
      try {
        element = document.getElementById("table-export");
      } catch (err) {}

      if (element) {
        exportClassName = exportClassName || element.getAttribute("data-class");
        element.replaceWith(
          sharedHelper.renderComponentToNode(CSVLink, {
            id: "table-export",
            data: exportData,
            headers: exportHeaders,
            filename: `${exportName}_${moment().format("YYYY-MM-DD HH:mm:ss")}`,
            className: `btn btn-white text-primary btn-sm rounded-circle d-flex custom-rounded-button py-2 ${
              exportClassName || ""
            }`,
            children: <Icon name="download" data-testid="fa-icon-download" />,
          })
        );
      }
    }
  }, [exportable, columns, data, exportName, getExportData]);

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      const oldIndex = columnOrder.indexOf(active.id);
      const newIndex = columnOrder.indexOf(over.id);

      const newColumnOrder = arrayMove(columnOrder, oldIndex, newIndex);
      setColumnOrder(newColumnOrder);
      onColumnSort(newColumnOrder);
    }
  };

  const handleSort = (column) => {
    if (!sortable) return;
    if (!column.disableSortBy) {
      let newSort = { sortBy: column.accessor, direction: "asc" };

      if (currentSort.sortBy === column.accessor) {
        if (currentSort.direction === "asc") {
          newSort = { sortBy: column.accessor, direction: "desc" };
        } else if (currentSort.direction === "desc") {
          newSort = { sortBy: null, direction: null };
        }
      }

      setCurrentSort(newSort);
      onSort([newSort]);
    }
  };

  const filteredData = data.filter((row, rowIndex) => {
    return Object.keys(filters).every((accessor) => {
      const filter = filters[accessor];
      const cellValue = getCellValue(row, accessor, rowIndex, true);

      if (!filter) return true;

      if (filter.mode === "selectItems") {
        if (filter.values && filter.values.length > 0) {
          return filter.values.includes(cellValue);
        }
        return false; //none selected
      } else if (filter.mode === "labelFilter") {
        const valueStr = String(cellValue).toLowerCase();
        const filterValue = filter.value.toLowerCase();

        switch (filter.condition) {
          case "contains":
            return valueStr.includes(filterValue);
          case "doesNotContain":
            return !valueStr.includes(filterValue);
          case "equals":
            return valueStr === filterValue;
          case "notEqual":
            return valueStr !== filterValue;
          case "startsWith":
            return valueStr.startsWith(filterValue);
          case "endsWith":
            return valueStr.endsWith(filterValue);
          default:
            return true;
        }
      } else if (filter.mode === "valueFilter") {
        const valueNum = parseFloat(cellValue);
        const filterValue = parseFloat(filter.value);
        const filterValueTo = parseFloat(filter.valueTo);

        switch (filter.condition) {
          case "equals":
            return valueNum === filterValue;
          case "notEqual":
            return valueNum !== filterValue;
          case "greaterThan":
            return valueNum > filterValue;
          case "lessThan":
            return valueNum < filterValue;
          case "between":
            return valueNum >= filterValue && valueNum <= filterValueTo;
          default:
            return true;
        }
      }
      return true;
    });
  });

  const applyFilter = (columnAccessor, filterData) => {
    setFilters({
      ...filters,
      [columnAccessor]: filterData,
    });
  };

  const SortableHeaderCell = ({ id, column }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      cursor: isColumnSortable && !column.disableSortBy ? "move" : "default",
      backgroundColor: isDragging ? "rgba(0,0,0,0.05)" : undefined,
      opacity: isDragging ? 0.8 : 1,
      ...(column.headerProps?.style || {}),
    };

    const className = `draggable ${column.headerProps?.className || ""} ${
      isDragging ? "is-dragging" : ""
    }`;

    return (
      <th
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={className}
        onClick={() => handleSort(column)}
        tabIndex={0}
        role="columnheader"
        aria-grabbed={isDragging}
      >
        <div className="d-flex align-items-center">
          {!column.disableFilter ? (
            <Icon
              size="xs"
              name="settings"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedFilterColumn(column);
              }}
              style={{ cursor: "pointer" }}
              data-testid={`fa-icon-gear-${column.accessor}`}
            />
          ) : null}
          <span className="mx-1">{column.header}</span>
          {sortable && !column.disableSortBy ? (
            <span
              className={`d-flex sort ${
                currentSort.sortBy === column.accessor
                  ? currentSort.direction
                  : ""
              }`}
            >
              {currentSort.sortBy === column.accessor ? (
                currentSort.direction === "desc" ? (
                  <Icon size="xs" name="arrow-down" className="text-dark" />
                ) : (
                  <Icon size="xs" name="arrow-up" className="text-dark" />
                )
              ) : (
                <Icon name="arrow" className="text-dark" />
              )}
            </span>
          ) : null}
        </div>
      </th>
    );
  };

  return (
    <>
      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <Table
          {...tableProps}
          className={`overflow-hidden advance-table ${
            tableProps.className || ""
          }`}
        >
          <thead className={headerClassName}>
            {isColumnSortable ? (
              <SortableContext
                items={columnOrder}
                strategy={horizontalListSortingStrategy}
              >
                <tr>
                  {columnOrder.map((accessor) => {
                    const column = columns.find(
                      (col) => col.accessor === accessor
                    );
                    return column ? (
                      <SortableHeaderCell
                        key={column.accessor}
                        id={column.accessor}
                        column={column}
                      />
                    ) : null;
                  })}
                </tr>
              </SortableContext>
            ) : (
              <tr>
                {columnOrder.map((accessor) => {
                  const column = columns.find(
                    (col) => col.accessor === accessor
                  );
                  return column ? (
                    <th
                      key={column.accessor}
                      onClick={() => handleSort(column)}
                      className={column.headerProps?.className}
                      style={{
                        cursor: column.disableSortBy ? "default" : "pointer",
                        ...(column.headerProps?.style || {}),
                      }}
                    >
                      <div className="d-flex align-items-center">
                        {!column.disableFilter ? (
                          <Icon
                            size="xs"
                            name="settings"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedFilterColumn(column);
                            }}
                            style={{ cursor: "pointer" }}
                            data-testid={`fa-icon-gear-${column.accessor}`}
                          />
                        ) : null}
                        <span className="mx-1">{column.header}</span>
                        {sortable && !column.disableSortBy ? (
                          <span
                            className={`d-flex sort ${
                              currentSort.sortBy === column.accessor
                                ? currentSort.direction
                                : ""
                            }`}
                          >
                            {currentSort.sortBy === column.accessor ? (
                              currentSort.direction === "desc" ? (
                                <Icon name="arrow-down" />
                              ) : (
                                <Icon name="arrow-up" />
                              )
                            ) : (
                              <Icon name="arrow" />
                            )}
                          </span>
                        ) : null}
                      </div>
                    </th>
                  ) : null;
                })}
              </tr>
            )}
          </thead>
          <tbody className={`condensed ${bodyClassName}`}>
            {isLoading ? (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  Loading...
                </td>
              </tr>
            ) : filteredData.length === 0 ? (
              <tr>
                <td
                  colSpan={columns.length}
                  className="text-center small text-muted"
                >
                  No data to display
                </td>
              </tr>
            ) : (
              filteredData.map((row, rowIndex) => (
                <tr
                  data-testid={`table-row-${row.id}`}
                  key={rowIndex}
                  className={`${rowClassName || ""} ${
                    onRowClick ? "cursor-pointer" : ""
                  }`}
                  onClick={onRowClick ? () => onRowClick(row) : null}
                >
                  {columnOrder.map((accessor) => {
                    const column = columns.find(
                      (col) => col.accessor === accessor
                    );
                    return column ? (
                      <td
                        key={column.accessor}
                        className={column.cellProps?.className}
                        style={{
                          ...(column.cellProps?.style || {}),
                        }}
                      >
                        {getCellValue(row, column.accessor, rowIndex)}
                      </td>
                    ) : null;
                  })}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </DndContext>
      {selectedFilterColumn ? (
        <FilterModal
          toggle={() => setSelectedFilterColumn(null)}
          columnAccessor={selectedFilterColumn.accessor}
          columnHeader={selectedFilterColumn.header}
          columnValues={[
            ...new Set(
              data
                .map((row, rowIndex) =>
                  getCellValue(
                    row,
                    selectedFilterColumn.accessor,
                    rowIndex,
                    true
                  )
                )
                .filter((v) => v != null)
            ),
          ]}
          onApply={applyFilter}
          currentFilter={filters[selectedFilterColumn.accessor]} // Pass the current filter
        />
      ) : null}
    </>
  );
};

export default AdvanceTable;
