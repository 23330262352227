import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";
import "../../assets/scss/smartReport.scss";
import {
  useDeleteSmartReport,
  useGetSmartReports,
  useUpdateSmartReport,
} from "../api/Reports.hooks";
import SmartReportDetailModal from "../components/SmartReportDetailModal";
import { getColumns, isSmartReportsReadOnly } from "../helpers/smartReports";

const {
  AdvanceTable,
  AdvanceTablePagination,
  Icon,
  Loader,
  ConfirmationModal,
} = components;

const { useAuth, AUTH_ACTIONS } = data;

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const SmartReport = ({ workOrderId, reportId, showBack, onDelete }) => {
  const [authContext, setAuthContext] = useAuth();

  const { id: paramId } = useParams();
  const [searchParams] = useSearchParams();

  workOrderId = searchParams.get("workOrderId") || workOrderId;

  showBack = searchParams.get("back") || showBack;

  const navigate = useNavigate();

  const id = reportId || paramId;

  const [report, setReport] = useState({ data: [] });
  const [smartReportDetailModal, setSmartReportDetailModal] = useState();

  const [refresh, setRefresh] = useState();
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [confirmationModal, setConfirmationModal] = useState();

  const [columnsTouched, setColumnsTouched] = useState();

  const { data: updateReportData, update: updateReport } =
    useUpdateSmartReport();

  const { data: deleteReportData, deleteReport } = useDeleteSmartReport();

  const {
    data: reportData,
    isLoading: isLoadingGetReport,
    get: getReport,
  } = useGetSmartReports();

  useEffect(() => {
    if (columnsTouched && report.id) {
      updateReport(report);
    }
  }, [columnsTouched, report, updateReport]);

  const onColumnSort = (columnOrder) => {
    const columns = report.columns.map((column) => ({
      ...column,
      order: columnOrder.findIndex((c) => c === column.accessor) + 1,
    }));
    setReport({ ...report, columns });
    setColumnsTouched(true);
  };

  const location = useLocation();

  useEffect(() => {
    setPage(INIT_PAGE);
    setSizePerPage(INIT_PAGE_SIZE);
  }, [location.pathname]);

  useEffect(() => {
    getReport({ id, workOrderId, page, pageSize: sizePerPage });
  }, [getReport, refresh, id, reportId, workOrderId, page, sizePerPage]);

  useEffect(() => {
    if (reportData) {
      setReport(reportData);
    }
  }, [setReport, reportData]);

  useEffect(() => {
    if (deleteReportData) {
      sharedHelper.successToast(`Report deleted`);
    }
  }, [deleteReportData, authContext.userData, report.id, setAuthContext]);

  useEffect(() => {
    if (updateReportData) {
      sharedHelper.successToast(`Report saved`);
      setColumnsTouched();
      setRefresh((prev) => !prev);
    }
  }, [updateReportData]);

  const doDelete = () => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "danger",
      onSubmit: async () => {
        await deleteReport(report.id);
        setConfirmationModal();
        const userData = { ...authContext.userData };
        const index = userData.smartReports.findIndex(
          (r) => r.id === report.id
        );
        if (index > -1) {
          userData.smartReports.splice(index, 1);
        }
        setAuthContext({
          action: AUTH_ACTIONS.SET_USER_DATA,
          payload: { userData },
        });
        if (onDelete) {
          onDelete();
        } else {
          navigate("/reports/smart");
        }
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Delete Smart Report",
      body: `Are you sure you want to delete this report?`,
      confirmText: "Delete",
    });
  };

  const isReadOnly = useMemo(
    () => isSmartReportsReadOnly(authContext.userData),
    [authContext.userData]
  );

  const goBack = () => {
    try {
      navigate(-1);
    } catch (err) {
      navigate("/workorders");
    }
  };

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader
          className={`section-header ${
            workOrderId ? "border-radius-default" : ""
          }`}
        >
          <div className="text-dark flex-grow-1 d-flex">
            <div className="d-flex align-items-center">
              {showBack ? (
                <div
                  className="d-flex flex-column justify-content-center cursor-pointer"
                  onClick={goBack}
                  data-testid="back-button"
                >
                  <Icon
                    name="chevron-left"
                    className="me-2 text-muted rounded-circle back-arrow bg-white fs-4"
                  />
                </div>
              ) : null}
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center">
                  <h2 className="mb-0">{report.name}</h2>
                  {isLoadingGetReport ? (
                    <div className="min-width-50">
                      <Loader size="sm" />
                    </div>
                  ) : (
                    <span className="text-muted ms-2">({report.count})</span>
                  )}
                </div>
                {!isReadOnly ? (
                  <span
                    className="text-link"
                    onClick={() => setSmartReportDetailModal(true)}
                  >
                    See details
                  </span>
                ) : null}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end flex-shrink-0">
            {!isReadOnly ? (
              <>
                <Button
                  className="me-3 d-none"
                  color="primary"
                  onClick={() => updateReport(report)}
                >
                  Update Report
                </Button>
                <Button className="me-3" color="danger" onClick={doDelete}>
                  Delete Report
                </Button>
              </>
            ) : null}
            <Button
              disabled={isLoadingGetReport}
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              data-testid="refresh-button"
              onClick={() => setRefresh((prev) => !prev)}
            >
              <Icon name="refresh-cw" />
            </Button>
            <div className="table-export-container me-3">
              <div id="table-export" />
            </div>
          </div>
        </CardHeader>
        <CardBody className={`section-body ${workOrderId ? "mx-0" : ""}`}>
          {isLoadingGetReport ? (
            <Loader />
          ) : report.count ? (
            <div className="d-flex flex-column" key={refresh}>
              <div className="overflow-x-auto">
                <AdvanceTable
                  columnsSortable={true}
                  columns={getColumns(report)}
                  data={report.data}
                  pageSize={sizePerPage}
                  exportable
                  exportName={report.name || "custom.csv"}
                  headerClassName="text-muted small"
                  tableProps={{
                    striped: true,
                    className: "smart-report table-layout-auto",
                  }}
                  onColumnSort={onColumnSort}
                  isColumnSortable={!isReadOnly}
                  currentColumnOrder={report.columns
                    .sort((x, y) => x.order - y.order)
                    .map((col) => col.accessor)} // Pass columnOrder here
                />
              </div>
              <AdvanceTablePagination
                totalCount={report.count}
                pageCount={report.totalPages}
                currentPage={page}
                onPageChange={(page) => setPage(page - 1)}
                pageSize={sizePerPage}
                onPageSizeChange={(sizePerPage) => {
                  setSizePerPage(sizePerPage);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
          ) : (
            <div className="text-center text-muted small">
              No data available in the report
            </div>
          )}
        </CardBody>
      </Card>
      {smartReportDetailModal ? (
        <SmartReportDetailModal
          isReadOnly={isReadOnly}
          report={report}
          onSubmit={() => {
            setSmartReportDetailModal();
            setRefresh(!refresh);
          }}
          onClose={() => setSmartReportDetailModal()}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default SmartReport;
