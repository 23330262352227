import React from "react";
import classNames from "classnames";

const Icon = React.forwardRef(
  ({ name, className, size = "sm", ...attrs }, ref) => {
    return (
      <i
        ref={ref}
        className={classNames(
          "icon",
          `icon-${name}`,
          `icon-${size}`,
          className
        )}
        {...attrs}
      />
    );
  }
);

export default Icon;
