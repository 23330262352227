import React from "react";

import { Card, CardBody, Row, Col } from "reactstrap";

import CrewWorkDay from "./CrewWorkDay";

import { useCrews, useCrewWorkDays } from "@crewos/crews";

import { components } from "@crewos/shared";

const { WorkOrderDateSelector } = components;

const CrewsPerDiems = () => {
  const [crewsContext] = useCrews();
  const [crewWorkDaysContext] = useCrewWorkDays();

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 px-0 box-shadow-none mb-3">
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h2 className="mb-0">Per Diems</h2>
            </div>
            <WorkOrderDateSelector
              crewWorkDays={crewWorkDaysContext.crewWorkDays}
            />
          </CardBody>
        </Card>
        {crewsContext.crews.length ? (
          crewsContext.crews.map((crew) => (
            <CrewWorkDay key={crew.id} crew={crew} />
          ))
        ) : (
          <div className="p-3 small text-muted text-center bg-white border-radius-default">
            Start by creating your first crew
          </div>
        )}
      </Row>
    </Col>
  );
};

export default CrewsPerDiems;
