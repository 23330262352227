import { api } from "@crewos/shared";

const { useApiGet, useApiMutation, HttpMethod } = api;

const safetyApi = "/api/safety";

export const useGetSafetyTemplates = () => {
  return useApiGet(`${safetyApi}-template`);
};

export const useGetQuestionTypes = () => {
  return useApiGet(`${safetyApi}-question-type`);
};

export const useUpdateOrCreateTemplateFull = (id) => {
  const method = !id ? HttpMethod.POST : HttpMethod.PUT;
  return useApiMutation(method, `${safetyApi}-template/full`);
};

export const useDuplicateTemplate = () => {
  return useApiMutation(
    HttpMethod.POST,
    `${safetyApi}-template/{templateId}/duplicate`
  );
};

export const useDeleteTemplate = () => {
  return useApiMutation(HttpMethod.DELETE, `${safetyApi}-template`);
};
