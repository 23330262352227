import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
} from "reactstrap";

import { api, components, data, sharedHelper } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { useGetSettings, useUpdateSetting } = api;

const { AdvanceTable, Icon, TooltipItem, ConfirmationModal } = components;

const { useAuth, AUTH_ACTIONS } = data;

const isTest = typeof jest !== "undefined";

const columns = (
  onChangeSettingStatus,
  onChangeSettingValue,
  workOrderStatus = []
) => [
  {
    accessor: "name",
    header: "Setting",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const value = rowData.row.name;
      return (
        <div data-value={value} className="d-flex align-items-center">
          {value}
          {rowData.row.description ? (
            <div className="ms-2">
              <TooltipItem
                id={`${rowData.row.id}-tooltip`}
                title={rowData.row.description}
              >
                <Icon
                  name="info"
                  className="text-primary"
                  style={{ paddingBottom: "1px" }}
                />
              </TooltipItem>
            </div>
          ) : null}
        </div>
      );
    },
  },
  {
    accessor: "status",
    header: "Status",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) =>
      !rowData.row.valueOnly ? (
        <Input
          checked={rowData.row.status}
          onChange={(event) =>
            onChangeSettingStatus(rowData.row, event.currentTarget.checked)
          }
          type="switch"
          data-testid="status-switch"
          id={rowData.row.id}
          name={rowData.row.id}
          label={rowData.row.status ? "Enabled" : "Disabled"}
          className="cursor-pointer d-flex align-items-center small setting-switch"
        />
      ) : null,
  },
  {
    accessor: "value",
    header: "Value",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { value, status } = rowData.row;
      if (!rowData.row.statusOnly) {
        switch (rowData.row.valueType) {
          case "WORK_ORDER_STATUS":
            return (
              <select
                className="form-control-redesign px-0"
                type="select"
                name="statusSelect"
                id="statusSelect"
                data-testid="status-select"
                value={value || ""}
                onChange={(e) =>
                  onChangeSettingValue(rowData.row, e.target.value)
                }
              >
                <option value={""}>Select status</option>
                {workOrderStatus.map((status) => {
                  return (
                    <option key={status.id} value={status.id}>
                      {status.name}
                    </option>
                  );
                })}
              </select>
            );
          case "COMBO_BOX":
            return (
              <select
                className="form-control-redesign px-0"
                type="select"
                name="comboBoxSelect"
                id="comboBoxSelect"
                data-testid="combo-box-select"
                value={value || ""}
                onChange={(e) =>
                  onChangeSettingValue(rowData.row, e.target.value)
                }
              >
                {rowData.row.valueOptions.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </select>
            );
          default:
            return (
              <DebounceInput
                debounceTimeout={900}
                disabled={rowData.row.valueReadOnly}
                placeholder={`${rowData.row.valuePlaceholder}...`}
                className="form-control"
                data-testid={`setting-value-${rowData.row.id}`}
                maxLength="50"
                min={0}
                type={rowData.row.valueType.toLowerCase()}
                value={value}
                onChange={(e) =>
                  onChangeSettingValue(rowData.row, e.target.value)
                }
              />
            );
        }
      }
      return status ? value || "-" : "-";
    },
  },
];

const Settings = () => {
  const [authContext, setAuthContext] = useAuth();

  const [settings, setSettings] = useState([]);
  const [refresh, setRefresh] = useState();
  const [confirmationModal, setConfirmationModal] = useState();

  const safetyPackage = authContext.userData?.packages.find(
    (pkg) => pkg.name === "Safety"
  );

  const {
    data: settingsData,
    isLoading: isLoadingSettings,
    get: getSettings,
  } = useGetSettings();

  useEffect(() => {
    if (safetyPackage) {
      getSettings({
        packageId: safetyPackage.id,
      });
    }
  }, [getSettings, safetyPackage, refresh]);

  useEffect(() => {
    if (settingsData) {
      setSettings(settingsData);
    }
  }, [settingsData, setSettings]);

  const {
    data: updateSettingData,
    isLoading: isLoadingUpdateSetting,
    update: updateSettings,
  } = useUpdateSetting();

  useEffect(() => {
    if (updateSettingData) {
      if (!isTest) {
        setAuthContext({
          action: AUTH_ACTIONS.REFRESH,
        });
      }
      sharedHelper.successToast(`Setting saved`);
    }
  }, [updateSettingData, setAuthContext]);

  const onChangeSettingStatus = (setting, status) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        await updateSettings({
          ...setting,
          status,
        });
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Update setting status",
      body: `Are you sure you want to change this setting status?`,
      confirmText: "Update",
    });
  };

  const onChangeSettingValue = (setting, value) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        await updateSettings({
          ...setting,
          value,
        });
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Update setting value",
      body: `Are you sure you want to change this setting value?`,
      confirmText: "Update",
    });
  };

  const nonInternalSettings = settings.filter((setting) => !setting.isInternal);

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Work Orders Settings</h2>
            <small className="text-muted ms-2 pt-1">({settings.length})</small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <AdvanceTable
            columns={columns(
              onChangeSettingStatus,
              onChangeSettingValue,
              authContext.userData.workOrderStatus
            )}
            data={nonInternalSettings}
            pageSize={nonInternalSettings.length || 1}
            isLoading={isLoadingSettings || isLoadingUpdateSetting}
            headerClassName="text-muted small"
            tableProps={{
              striped: true,
            }}
          />
        </CardBody>
      </Card>
      {confirmationModal ? <ConfirmationModal {...confirmationModal} /> : null}
    </Container>
  );
};

export default Settings;
