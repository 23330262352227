import { useGetAllNonJobTimeTypes } from "./api/NonJobTimeTypes.hooks";

import { data, sharedHelper, helpers } from "@crewos/shared";
import NonJobTimeTypes from "./pages/settings/NonJobTimeTypes";
import EditNonJobTimeModal from "./components/EditNonJobTimeModal";
import { useRecalculateNonJobTime } from "./api/NonJobTimes.hooks";

const { createRoutes } = helpers;

const { SIDEBAR_ACTIONS } = data;

const NONJOBTIMES = "NONJOBTIMES";

const routes = [
  {
    sidebar: true,
    icon: null,
    name: "Non Job Time Types",
    path: `non-job-times/non-job-time-types`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "nonjobtimes",
    isSetting: true,
    category: "Non Job Time",
    component: NonJobTimeTypes,
    order: 1,
  },
];

const Routes = createRoutes(routes);

const setNonJobTimesSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "nonjobtimes");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: NONJOBTIMES, order: 6 },
    });
  }
};

export {
  Routes as NonJobTimeRoutes,
  setNonJobTimesSidebarRoutes,
  useGetAllNonJobTimeTypes,
  useRecalculateNonJobTime,
  EditNonJobTimeModal,
};
