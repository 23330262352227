import React from "react";

import classNames from "classnames";

import Icon from "./Icon";

const CustomCheckbox = ({
  onClick,
  onChange,
  checked,
  disabled,
  className,
  color = "primary",
  small,
  ...attrs
}) => {
  const onClickHandler = () => {
    onClick?.();
    onChange?.(!checked);
  };

  return (
    <div
      role="checkbox"
      aria-checked={checked}
      className={classNames(
        `bg-${color} border border-${color} rounded cursor-pointer`,
        { "pe-none": disabled },
        { "p-1": !small, "p-0": small },
        `d-flex ${className}`
      )}
      onClick={onClickHandler}
      {...attrs}
    >
      {checked ? (
        <Icon name="check" className="text-white" data-testid="checked-icon" />
      ) : (
        <Icon
          name="check"
          className={`text-${color}`}
          data-testid="unchecked-icon"
        />
      )}
    </div>
  );
};

export default CustomCheckbox;
