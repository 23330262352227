import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import {
  useCreateUserRole,
  useUpdateUserRole,
  useGetUserRoleScopes,
} from "../api/UserRoles.hooks";

import { components, sharedHelper, data } from "@crewos/shared";

const { useAuth } = data;

const { Loader, CustomCheckbox } = components;

const IS_ADMIN_USER = "IS_ADMIN_USER";
const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";
const IS_WEB_USER = "IS_WEB_USER";

const UserRoleModal = ({
  userRole = { name: "", userRoleScopes: [] },
  onClose,
  onSubmit,
}) => {
  const [authContext] = useAuth();
  const [userRoleData, setUserRoleData] = useState(userRole);
  const [userRolePermissions, setUserRolePermissions] = useState(
    userRole.userRoleScopes
  );

  const [availableRoleScopes, setAvailableRoleScopes] = useState([]);

  const {
    isLoading: isLoadingUpdateUserRole,
    update: updateUserRole,
    data: updateUserRoleData,
  } = useUpdateUserRole();

  const {
    isLoading: isLoadingCreateUserRole,
    mutate: createUserRole,
    data: createUserRoleData,
  } = useCreateUserRole();

  const {
    data: getUserRoleScopesData,
    isLoading: isLoadingGetUserRoleScopes,
    get: getUserRoleScopes,
  } = useGetUserRoleScopes();

  useEffect(() => {
    getUserRoleScopes();
  }, [getUserRoleScopes]);

  useEffect(() => {
    if (getUserRoleScopesData) {
      setAvailableRoleScopes(getUserRoleScopesData);
    }
  }, [getUserRoleScopesData, setAvailableRoleScopes]);

  useEffect(() => {
    if (updateUserRoleData) {
      sharedHelper.successToast(`User role saved`);
      onSubmit();
    }
  }, [updateUserRoleData, onSubmit]);

  useEffect(() => {
    if (createUserRoleData) {
      sharedHelper.successToast(`User role created`);
      onSubmit();
    }
  }, [createUserRoleData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    const data = { ...userRoleData };
    const isAdmin = sharedHelper.userMeetsRole(authContext.userData, [
      IS_ADMIN_USER,
    ]);
    if (isAdmin) {
      data.userRoleScopes = userRolePermissions;
    } else {
      delete data.userRoleScopes;
    }
    if (userRoleData.id) {
      await updateUserRole(data);
    } else {
      await createUserRole(data);
    }
  };

  const onScopeClick = (roleScope, isChecked) => {
    if (isChecked) {
      if (
        (userRolePermissions.find((item) => item.name === IS_ADMIN_USER) ||
          userRolePermissions.find(
            (item) => item.name === IS_SUPER_ADMIN_USER
          )) &&
        roleScope.name === IS_WEB_USER
      ) {
        return sharedHelper.errorToast("Admins must be web users");
      }
      const updatedUserRolePermissions = [...userRolePermissions];
      updatedUserRolePermissions.splice(
        updatedUserRolePermissions.findIndex((p) => p.id === roleScope.id),
        1
      );
      setUserRolePermissions(updatedUserRolePermissions);
    } else {
      if (
        (roleScope.name === IS_ADMIN_USER ||
          roleScope.name === IS_SUPER_ADMIN_USER) &&
        !userRolePermissions.find((item) => item.name === IS_WEB_USER)
      ) {
        const webRoleScope = availableRoleScopes.find(
          (item) => item.name === IS_WEB_USER
        );
        setUserRolePermissions([
          ...userRolePermissions,
          roleScope,
          webRoleScope,
        ]);
      } else {
        setUserRolePermissions([...userRolePermissions, roleScope]);
      }
    }
  };

  const isAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_ADMIN_USER,
  ]);

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          {userRoleData.id ? "Edit" : "Create"} User Role
        </ModalHeader>
        <ModalBody>
          {isLoadingGetUserRoleScopes ||
          isLoadingCreateUserRole ||
          isLoadingUpdateUserRole ? (
            <Loader size="sm" />
          ) : (
            <>
              <FormGroup className={!isAdmin ? "mb-0" : ""}>
                <Label>
                  <span>Name</span>
                  <span className="text-danger ms-1">*</span>
                </Label>
                <input
                  className="form-control-redesign"
                  maxLength="50"
                  type="text"
                  placeholder="Enter the name"
                  name="name"
                  value={userRoleData.name || ""}
                  onChange={(e) =>
                    setUserRoleData({
                      ...userRoleData,
                      name: e.target.value,
                    })
                  }
                  required
                />
              </FormGroup>
              {isAdmin ? (
                <FormGroup>
                  <ListGroup>
                    <ListGroupItem className="d-flex justify-content-center align-items-center fw-bold bg-graylight">
                      Permissions
                    </ListGroupItem>
                    {availableRoleScopes.map((roleScope) => {
                      const isChecked = Boolean(
                        userRolePermissions.find(
                          (scope) => scope.id === roleScope.id
                        )
                      );
                      return (
                        <ListGroupItem
                          key={roleScope.id}
                          className="d-flex justify-content-between align-items-center cursor-pointer"
                          onClick={() => onScopeClick(roleScope, isChecked)}
                        >
                          <div className="flex-shrink-0">{roleScope.name}</div>
                          <div className="min-width-50 d-flex justify-content-end">
                            <CustomCheckbox checked={isChecked} />
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </FormGroup>
              ) : null}
            </>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UserRoleModal;
