import React, { useReducer, createContext, useContext } from "react";

const initialState = {
  routes: {},
  isOpen: true,
  isTouched: false,
};

const ACTIONS = {
  SET_ROUTES: "SET_ROUTES",
  RESET_ROUTES: "RESET_ROUTES",
  TOGGLE: "TOGGLE",
};

const reducer = (state, data) => {
  switch (data.action) {
    case ACTIONS.SET_ROUTES: {
      const { payload } = data;
      const { name, routes, order } = payload;

      const newRoutes = { ...state.routes, [name]: { routes, order } };

      return {
        ...state,
        routes: newRoutes,
      };
    }

    case ACTIONS.RESET_ROUTES: {
      return {
        ...state,
        routes: {},
        settingsRoutes: [],
      };
    }

    case ACTIONS.TOGGLE: {
      return {
        ...state,
        isOpen: !state.isOpen,
        isTouched: true,
      };
    }

    default: {
      throw new Error();
    }
  }
};

const SidebarContext = createContext(initialState);

const SidebarProvider = ({ children }) => {
  const stateAndDispatch = useReducer(reducer, initialState);
  return (
    <SidebarContext.Provider value={stateAndDispatch}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebar = () => useContext(SidebarContext);

export { SidebarContext, SidebarProvider, ACTIONS };
