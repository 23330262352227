import React, { useEffect, useState } from "react";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, data } from "@crewos/shared";

import {
  useDeleteWorkOrderStatus,
  useGetWorkOrderStatus,
} from "../../api/WorkOrderStatus.hooks";
import WorkOrderStatusModal from "../../components/WorkOrderStatusModal";

const { useAuth, AUTH_ACTIONS } = data;

const { AdvanceTable, AdvanceTablePagination, ConfirmationModal, Icon } =
  components;

const columns = (onDelete, onEdit) => [
  {
    accessor: "name",
    header: "Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.name;
    },
  },
  {
    accessor: "order",
    header: "Order",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    type: "number",
    Cell: (rowData) => {
      return rowData.row.order || "-";
    },
  },
  {
    accessor: "workOrderCategoryId",
    header: "Work Order Category",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.workOrderCategory?.name || "-";
    },
  },
  {
    accessor: "isAppList",
    header: "Listed in APP",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.isAppList ? "Yes" : "No";
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    cellProps: {
      className: "text-end",
    },
    headerProps: {
      className: "text-truncate text-end",
      style: { width: 300 },
    },
    Cell: (rowData) => (
      <>
        <Button
          size="sm"
          color="none"
          className="mx-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        <Button
          size="sm"
          className="text-danger"
          color="none"
          onClick={() => onDelete(rowData.row)}
        >
          Delete
        </Button>
      </>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const WorkOrderStatus = () => {
  const [, setAuthContext] = useAuth();
  const [workOrderStatus, setWorkOrderStatus] = useState({});
  const [refresh, setRefresh] = useState();
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);

  const [createModal, setCreateModal] = useState();
  const [updateModal, setUpdateModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: getWorkOrderStatusData,
    isLoading: isLoadingGetWorkOrderStatus,
    get: getWorkOrderStatus,
  } = useGetWorkOrderStatus();

  useEffect(() => {
    getWorkOrderStatus({
      page,
      pageSize: sizePerPage,
    });
  }, [getWorkOrderStatus, refresh, page, sizePerPage]);

  useEffect(() => {
    if (getWorkOrderStatusData) {
      setWorkOrderStatus(getWorkOrderStatusData);
    }
  }, [getWorkOrderStatusData, setWorkOrderStatus]);

  const { data: deleteWorkOrderStatusData, deleteWorkOrderStatus } =
    useDeleteWorkOrderStatus();

  useEffect(() => {
    if (deleteWorkOrderStatusData) {
      setAuthContext({
        action: AUTH_ACTIONS.REFRESH,
      });
    }
  }, [deleteWorkOrderStatusData, setAuthContext]);

  const onEdit = (workOrderStatus) => {
    setUpdateModal(workOrderStatus);
  };

  const onDelete = (workOrderStatus) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteWorkOrderStatus(workOrderStatus.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setAuthContext({
          action: AUTH_ACTIONS.REFRESH,
        });
      },
      title: "Delete Work Order Status",
      body: `Are you sure you want to delete ${workOrderStatus.name}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Work Order Status</h2>
            <small className="text-muted ms-2 pt-1">
              ({workOrderStatus.count})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(onDelete, onEdit)}
              data={workOrderStatus.data || []}
              pageSize={sizePerPage}
              isLoading={isLoadingGetWorkOrderStatus}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={workOrderStatus.count}
            pageCount={workOrderStatus.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : createModal ? (
        <WorkOrderStatusModal
          onClose={() => setCreateModal()}
          onSubmit={() => {
            setCreateModal();
            setAuthContext({
              action: AUTH_ACTIONS.REFRESH,
            });
          }}
        />
      ) : updateModal ? (
        <WorkOrderStatusModal
          workOrderStatus={updateModal}
          onClose={() => setUpdateModal()}
          onSubmit={() => {
            setUpdateModal();
            setAuthContext({
              action: AUTH_ACTIONS.REFRESH,
            });
          }}
        />
      ) : null}
    </Container>
  );
};

export default WorkOrderStatus;
