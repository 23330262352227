import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from "reactstrap";
import { useUpdateWorkOrder } from "../../api/WorkOrders.hooks";
import { sharedHelper, data } from "@crewos/shared";

const { useWorkOrderDetails, WORK_ORDER_DETAILS_ACTIONS } = data;

const JobDescriptionModal = ({ onClose }) => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const [description, setDescription] = useState(
    workOrderDetails.workOrder.description
  );

  const {
    isLoading: isLoadingUpdateWorkOrder,
    update: updateWorkOrder,
    data: updateWorkOrderData,
  } = useUpdateWorkOrder();

  useEffect(() => {
    if (updateWorkOrderData) {
      sharedHelper.successToast(`Work order saved`);
      setWorkOrderDetails({
        action: WORK_ORDER_DETAILS_ACTIONS.REFRESH,
      });
      onClose();
    }
  }, [updateWorkOrderData, onClose, setWorkOrderDetails]);

  const doSubmit = () =>
    updateWorkOrder({ ...workOrderDetails.workOrder, description });

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Job Description
      </ModalHeader>
      <ModalBody>
        <ReactQuill
          theme="snow"
          value={description}
          onChange={setDescription}
          disabled={isLoadingUpdateWorkOrder}
          className="border rounded"
        />
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button
          color="secondary"
          onClick={onClose}
          disabled={isLoadingUpdateWorkOrder}
        >
          Discard
        </Button>
        <Button
          color="primary"
          onClick={doSubmit}
          disabled={isLoadingUpdateWorkOrder}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default JobDescriptionModal;
