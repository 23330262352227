import { data, sharedHelper } from "@crewos/shared";
import { routes, Routes } from "./routes";
import CrewsInspections from "./components/CrewsInspections";
import CrewsInspectionsTemplates from "./components/CrewsInspectionsTemplates";

import "./assets/scss/inspections.scss";

const { SIDEBAR_ACTIONS } = data;

const setInspectionsSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "inspections");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: "INSPECTIONS", order: 6 },
    });
  }
};

export {
  Routes as InspectionsRoutes,
  setInspectionsSidebarRoutes,
  CrewsInspections,
  CrewsInspectionsTemplates,
};
