import React, { useMemo, useEffect, useState, useCallback } from "react";

import { Button, Card, CardBody } from "reactstrap";

import {
  useCreateAttachment,
  useGetAttachments,
} from "../api/Attachments.hooks";

import { components, sharedHelper, data } from "@crewos/shared";

import Photos from "./Photos";
import { useCrewWorkDays, CREW_WORK_DAY_ACTIONS } from "@crewos/crews";

const { Loader, UploadPhotosModal, Icon } = components;

const { useAuth, useWorkOrderDetails } = data;

const CrewWorkDaysPhotos = ({ crew }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();

  const [attachments, setAttachments] = useState([]);
  const [refresh, setRefresh] = useState();

  const [createModal, setCreateModal] = useState(false);
  const {
    data: getAttachmentsData,
    isLoading: isLoadingGetAttachments,
    get: getAttachments,
  } = useGetAttachments();

  //only one crew work day per date/crew
  const crewWorkDay = useMemo(
    () =>
      crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate]?.find(
        (cwd) => cwd.crewId === crew.id
      ),
    [workOrderDetails.selectedDate, crewWorkDaysContext.crewWorkDays, crew]
  );

  const onRefresh = useCallback(() => {
    if (!crewWorkDay) {
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.REFRESH,
      });
    } else {
      setRefresh((prev) => !prev);
    }
  }, [crewWorkDay, setCrewWorkDaysContext]);

  useEffect(() => {
    if (crewWorkDay) {
      getAttachments({
        crewWorkDayId: crewWorkDay.id,
      });
    }
  }, [getAttachments, refresh, crewWorkDay]);

  useEffect(() => {
    if (getAttachmentsData) {
      setAttachments(getAttachmentsData);
    }
  }, [getAttachmentsData, setAttachments]);

  const {
    isLoading: isLoadingCreateAttachment,
    mutate: createAttachment,
    data: createAttachmentData,
  } = useCreateAttachment();

  useEffect(() => {
    if (createAttachmentData) {
      sharedHelper.successToast(`Photos uploaded`);
      setCreateModal(false);
      onRefresh();
    }
  }, [createAttachmentData, onRefresh]);

  return (
    <>
      <Card className="w-100 px-0 box-shadow-none mb-3">
        <CardBody>
          <div className="mb-n1 d-flex align-items-center justify-content-between">
            <h3>Photos</h3>
            <div className="d-flex align-items-center">
              <Button
                size="sm"
                color="primary"
                onClick={() => {
                  setCreateModal(true);
                }}
                data-testid="add-button"
              >
                Add
              </Button>
              <Button
                size="sm"
                className="ms-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                data-testid="refresh-button"
                onClick={onRefresh}
              >
                <Icon name="refresh-cw" />
              </Button>
            </div>
          </div>
          {isLoadingGetAttachments || isLoadingCreateAttachment ? (
            <div className="w-100 d-flex justify-content-center">
              <Loader size="sm" className="my-3" />
            </div>
          ) : attachments.filter((attachment) => attachment.url).length ? (
            <Photos attachments={attachments} onRefresh={onRefresh} />
          ) : (
            <div className="p-2 small text-muted text-center">No photos</div>
          )}
        </CardBody>
      </Card>
      {createModal ? (
        <UploadPhotosModal
          isMulti={true}
          title={"Add Photos"}
          onClose={() => setCreateModal(false)}
          includeDescription={true}
          onSubmit={(data) => {
            createAttachment({
              url: data.attachmentUrl,
              workOrderId: workOrderDetails.workOrder.id,
              description: data.description,
              crewId: crew.id,
              date: workOrderDetails.selectedDate,
            });
          }}
        />
      ) : null}
    </>
  );
};

const CrewWorkDaysPhotosWrapper = (props) => {
  const [authContext] = useAuth();

  if (
    !sharedHelper.isPackageEnabled(
      authContext.userData?.packages,
      "attachments"
    )
  ) {
    return null;
  }

  return <CrewWorkDaysPhotos {...props} />;
};

export default CrewWorkDaysPhotosWrapper;
