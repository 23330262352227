import React, { useEffect, useState } from "react";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";

import { useGetPackages, useUpdatePackage } from "../../api/Packages.hooks";

const { useAuth, AUTH_ACTIONS } = data;

const {
  Icon,
  AdvanceTable,
  AdvanceTablePagination,
  ConfirmationModal,
  CustomCheckbox,
} = components;

const columns = (onChangePackageStatus) => [
  {
    accessor: "name",
    header: "Module",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.name;
    },
  },
  {
    accessor: "status",
    header: "Status",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return (
        <CustomCheckbox
          disabled={rowData.row.isCore}
          checked={rowData.row.isActive}
          onClick={() =>
            onChangePackageStatus(rowData.row, !rowData.row.isActive)
          }
          data-testid="status-switch"
          id={rowData.row.id}
          name={rowData.row.id}
          className="d-inline-flex align-items-center"
          small
        />
      );
    },
  },
  {
    accessor: "parent",
    header: "Parent",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.parent?.name || "-";
    },
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const Packages = () => {
  const [, setAuthContext] = useAuth();
  const [packages, setPackages] = useState([]);
  const [refresh, setRefresh] = useState();
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: getPackagesData,
    isLoading: isLoadingGetPackages,
    get: getPackages,
  } = useGetPackages();

  useEffect(() => {
    getPackages({
      page,
      pageSize: sizePerPage,
    });
  }, [getPackages, refresh, page, sizePerPage]);

  useEffect(() => {
    if (getPackagesData) {
      setPackages(getPackagesData);
    }
  }, [getPackagesData, setPackages]);

  const { data: updatePackageData, update: updatePackage } = useUpdatePackage();

  useEffect(() => {
    if (updatePackageData) {
      setAuthContext({
        action: AUTH_ACTIONS.REFRESH,
      });
      sharedHelper.successToast(`Setting saved`);
    }
  }, [updatePackageData, setAuthContext]);

  const onChangePackageStatus = (pkg, isActive) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        await updatePackage({ ...pkg, isActive });
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Update module",
      body: `Are you sure you want to change this module status?`,
      confirmText: "Update",
    });
  };

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Modules</h2>
            <small className="text-muted ms-2 pt-1">
              ({packages.data?.length || 0})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              data-testid="refresh-button"
              onClick={() => setRefresh((prev) => !prev)}
            >
              <Icon name="refresh-cw" />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(onChangePackageStatus)}
              data={packages.data || []}
              pageSize={sizePerPage}
              isLoading={isLoadingGetPackages}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
                size: "lg",
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={packages.count}
            pageCount={packages.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {confirmationModal ? <ConfirmationModal {...confirmationModal} /> : null}
    </Container>
  );
};

export default Packages;
