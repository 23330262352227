import React, { forwardRef, useCallback, useState, useEffect } from "react";

const SIZE_INPUT = {
  SMALL: 8,
  MEDIUM: 14,
  LARGE: 18,
};

const InputEditable = forwardRef((props, ref) => {
  const { onChange, className, size = "medium", value, errors, ...otherProps } = props;

  const [width, setWidth] = useState(0);

  const multiplier = SIZE_INPUT[size.toUpperCase()] || SIZE_INPUT.MEDIUM;
  const inputClassName = `form-control-editable font-size-${size} ${
    className || ""
  } ${errors?.length ? "is-invalid" : ""} `;

  const handleUpdate = useCallback(
    ($event) => {
      const inputWidth = Math.max(110, $event.target.value.length * multiplier);
      setWidth(inputWidth);
      if (onChange) {
        onChange($event);
      }
    },
    [setWidth, onChange, multiplier]
  );

  useEffect(() => {
    if (value) {
      const inputWidth = Math.max(110, value.length * multiplier);
      setWidth(inputWidth);
    }
  }, [value, multiplier]);

  useEffect(() => {
    const initialWidth = Math.max(110, value?.length * multiplier);
    setWidth(initialWidth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <input
      ref={ref}
      onChange={handleUpdate}
      className={inputClassName}
      {...otherProps}
      value={value}
      style={{ width: width ? width : "auto" }}
    />
  );
});

export default InputEditable;
