import TemplatesWorkOrder from "./TemplatesWorkOrder";
import { useGetTemplateWorkOrder } from "../api/Inspections.hooks";
import React, { useCallback, useEffect, useMemo } from "react";
import InspectionsWrapper from "./Inspections";
import { components } from "@crewos/shared";
import { Card, CardBody } from "reactstrap";

const { Loader } = components;

const AllInspections = ({ workOrder, needsUpdate, search }) => {
  const {
    data: templateWOData,
    isLoading: isLoadingTemplateWO,
    get: getTemplateWO,
  } = useGetTemplateWorkOrder();

  const handleGetTemplateWO = useCallback(() => {
    const payload = {
      workOrderId: workOrder.id,
      search,
      page: 0,
      pageSize: 100,
    };
    getTemplateWO(payload);
  }, [workOrder.id, getTemplateWO, search]);

  useEffect(() => {
    handleGetTemplateWO();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsUpdate, search]);

  const templateWOFiltered = useMemo(() => {
    return templateWOData && templateWOData.data ? templateWOData.data : [];
  }, [templateWOData]);

  if (isLoadingTemplateWO) {
    return <Loader size="sm" />;
  }

  return templateWOFiltered.length ? (
    templateWOFiltered.map((templateWO) => (
      <TemplatesWorkOrder key={templateWO.id} templateWorkOrder={templateWO}>
        <InspectionsWrapper
          inspections={templateWO.inspections}
          onInspectionDelete={handleGetTemplateWO}
        />
      </TemplatesWorkOrder>
    ))
  ) : (
    <Card className="w-100 px-0 box-shadow-none mb-3">
      <CardBody className="py-3">
        <div className="text-center text-muted small">No data to display</div>
      </CardBody>
    </Card>
  );
};

export default AllInspections;
