import { useCallback } from "react";

import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const expenseAttachmentEndpoint = `/api/expense-attachment`;

export const useGetExpenseAttachments = () => {
  return useApiGet(expenseAttachmentEndpoint);
};

export const useCreateExpenseAttachment = () => {
  return useApiMutation(HttpMethod.POST, expenseAttachmentEndpoint);
};

export const useUpdateExpenseAttachment = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    expenseAttachmentEndpoint
  );

  const update = useCallback(
    async (expenseAttachment) => {
      await mutate(expenseAttachment);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteExpenseAttachment = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    expenseAttachmentEndpoint
  );

  const deleteExpenseAttachment = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteExpenseAttachment };
};
