import React from "react";

import { CrewWorkDayExpenses } from "@crewos/expenses";
import { CrewWorkDayWorkTimes } from "@crewos/worktimes";
import { CrewWorkDayTravelTimes } from "@crewos/traveltimes";

import { CrewWorkDayNotes } from "@crewos/notes";
import { CrewWorkDaysPhotos } from "@crewos/attachments";
import CrewWorkDayCrew from "./CrewWorkDayCrew";

import { data } from "@crewos/shared";

const { useWorkOrderDetails } = data;

const WorkPerformedWorkDay = ({ crew }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  if (!crew) {
    return null;
  }

  return (
    <CrewWorkDayCrew crew={crew} key={workOrderDetails.selectedDate}>
      <CrewWorkDayWorkTimes crew={crew} />
      <CrewWorkDayTravelTimes crew={crew} />
      <CrewWorkDayExpenses crew={crew} isReadOnly={true} />
      <CrewWorkDayNotes crew={crew} />
      <CrewWorkDaysPhotos crew={crew} />
    </CrewWorkDayCrew>
  );
};

export default WorkPerformedWorkDay;
