import { api } from "@crewos/shared";

const { useApiGet } = api;

const employeesScheduleEndpoint = `/api/employee-schedule`;
const jobsScheduleEndpoint = `api/job-schedule`;

export const useGetJobSchedules = () => {
  return useApiGet(jobsScheduleEndpoint);
};

export const useGetEmployeesSchedules = () => {
  return useApiGet(employeesScheduleEndpoint);
};
