import React from "react";

import { DebounceInput } from "react-debounce-input";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components } from "@crewos/shared";

import TemplatesList from "../../../components/TemplatesList";
import { filters, useListTemplateLogic } from "./ListTemplate.logic";

const { StatusFilter, Icon } = components;

const ListTemplates = () => {
  const {
    search,
    selectedFilter,
    templatesGrouped,
    handleRefresh,
    handleSelectFilter,
    handleDeleteItem,
    handleCopyItem,
    handleSearch,
  } = useListTemplateLogic();

  return (
    <Container fluid className="inspections-container">
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Inspection Templates</h2>
            <small className="text-muted ms-2 pt-1">
              ({templatesGrouped.flatMap((t) => t.templates).length})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <DebounceInput
                className="search border-0 form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search"
                value={search}
                onChange={handleSearch}
              />
            </div>
            <div className="d-flex gap-2 align-items-center position-relative">
              <span className="flex-shrink-0">Filter by status:</span>
              <StatusFilter
                statuses={filters}
                selectedStatus={selectedFilter}
                setSelectedStatus={handleSelectFilter}
              />
              <Button
                size="sm"
                className="ms-1 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                data-testid="refresh-button"
                onClick={handleRefresh}
              >
                <Icon name="refresh-cw" />
              </Button>
            </div>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          {templatesGrouped.map((group) => (
            <TemplatesList
              key={group.title}
              title={group.title}
              templates={group.templates}
              onDeleteItem={handleDeleteItem}
              onCopyItem={handleCopyItem}
            />
          ))}
        </CardBody>
      </Card>
    </Container>
  );
};

export default ListTemplates;
