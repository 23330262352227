import React, { useState } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Form,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import Loader from "./Loader";
import CustomCheckbox from "./CustomCheckbox";

const ConfirmationModalWithInput = ({
  onSubmit,
  onClose,
  title,
  body,
  confirmColor,
  confirmText,
  confirmTextPlaceholder,
  confirmType = "text",
  btnCancelText = "Cancel",
  btnConfirmText = "Confirm",
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmInput, setConfirmInput] = useState(false);

  const doSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    onSubmit(confirmInput);
  };

  const isDisabled =
    loading ||
    (confirmType !== "check" &&
      confirmText &&
      (confirmInput || "").toLowerCase() !== (confirmText || "").toLowerCase());

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {title}
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {loading ? (
            <Loader size="sm" />
          ) : (
            <div>
              <p dangerouslySetInnerHTML={{ __html: body }}></p>
              <FormGroup className="col-12">
                {confirmType === "check" ? (
                  <ListGroup className="mt-3">
                    <ListGroupItem
                      className="d-flex justify-content-between align-items-center cursor-pointer"
                      onClick={() => setConfirmInput(!confirmInput)}
                    >
                      <div className="flex-shrink-0">{confirmText}</div>
                      <div className="min-width-50 d-flex justify-content-end align-items-center">
                        <CustomCheckbox
                          checked={confirmInput}
                          color={confirmColor}
                        />
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                ) : (
                  <input
                    className="form-control-redesign"
                    type={confirmType}
                    placeholder={confirmTextPlaceholder}
                    required={true}
                    maxLength="50"
                    value={confirmInput || ""}
                    onChange={(evt) => setConfirmInput(evt.target.value)}
                  />
                )}
              </FormGroup>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            {btnCancelText}
          </Button>{" "}
          <Button
            disabled={isDisabled}
            color={confirmColor || "primary"}
            type="submit"
          >
            {btnConfirmText}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ConfirmationModalWithInput;
