import axios from "axios";

export const awsApi = {
  signDocuments: (data) => {
    return axios(`/api/aws/sign`, {
      baseURL: process.env.REACT_APP_API_BASE_URL,
      withCredentials: true,
      method: "post",
      data,
    }).then((result) => result.data);
  },
  uploadFile: (data) => {
    return axios(`/api/aws/upload`, {
      baseURL: process.env.REACT_APP_API_BASE_URL,
      withCredentials: true,
      method: "post",
      data,
    }).then((result) => result.data);
  },
  putDocumentsToS3: (data, type) => {
    return axios(data.url, {
      method: "put",
      data: data.file,
      headers: {
        "Content-Type": type,
      },
    }).then(() => {
      return { name: data.file.name, url: data.fileUrl };
    });
  },
};
