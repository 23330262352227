import React, { useMemo, useState } from "react";
import { Col, Row } from "reactstrap";

import axios from "axios";

import { components, data, sharedHelper } from "@crewos/shared";

import SectionDetail from "./SectionDetail";

const { Icon } = components;

const { useWorkOrderDetails } = data;

const InspectionDetail = ({ inspection, status }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [isLoading, setIsLoading] = useState();

  const templateWorkOrder = useMemo(() => {
    return inspection?.inspectionTemplateWorkOrder || {};
  }, [inspection]);

  const template = useMemo(() => {
    return templateWorkOrder?.template || {};
  }, [templateWorkOrder]);

  const sections = useMemo(() => {
    return template?.sections || [];
  }, [template]);

  const totalQuestionsBySection = useMemo(() => {
    return sections?.reduce((acc, section) => {
      acc[section?.id] = section?.groups.reduce(
        (acc, group) => acc + group?.questions?.length,
        0
      );
      return acc;
    }, {});
  }, [sections]);

  const totalQuestions = useMemo(() => {
    return Object.values(totalQuestionsBySection).reduce(
      (acc, total) => acc + total,
      0
    );
  }, [totalQuestionsBySection]);

  if (!inspection) {
    return null;
  }

  const onDownloadPdf = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    sharedHelper.loadingToast();
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/api/inspection/${inspection.id}/report`,
      {
        responseType: "blob",
        withCredentials: true,
      }
    );
    const woNumber = workOrderDetails.workOrder.workOrderNumber;
    const assetName = inspection.inspectionTemplateWorkOrder.name;
    const templateName = inspection.inspectionTemplateWorkOrder.template.name;
    const date = inspection.crewWorkDay.date;

    const fileName = `${woNumber}-${assetName}-${templateName}-${date}.pdf`
      .replaceAll(" ", "_")
      .toLowerCase();
    const report = new Blob([response.data], { type: "application/pdf" });
    sharedHelper.downloadFile(report, fileName);
    sharedHelper.dismissLoadingToast();
    setIsLoading(false);
  };

  return (
    <div>
      <Row className="border-0 align-items-start w-100 mt-n4">
        <Col sm={10} className="d-flex align-items-center">
          <span className="d-block fw-semibold font-size-1-1">
            {templateWorkOrder.name}
          </span>
          <Icon
            onClick={onDownloadPdf}
            name="download"
            className="ms-2 cursor-pointer"
          />
        </Col>
        <Col
          sm={2}
          className="d-flex align-items-center justify-content-center"
        >
          <small style={status?.color} className="p-2 flex-shrink-0 rounded">
            {status?.item?.name}
          </small>
        </Col>
        <Col sm={12}>
          <small className="text-gray">
            {template.name} - {totalQuestions} questions
          </small>
        </Col>
      </Row>
      <Row sm={3} className="mt-3">
        <Col>
          <h5>Inspector</h5>
          <p>
            {inspection.createdByUser
              ? `${inspection.createdByUser.firstName} ${inspection.createdByUser.lastName}`
              : "N/A"}
          </p>
        </Col>
        <Col>
          <h5>Contact Name</h5>
          <p>
            {inspection.employee
              ? `${inspection.employee.firstName} ${inspection.employee.lastName}`
              : "N/A"}
          </p>
        </Col>
        <Col>
          <h5>Date</h5>
          <p>{inspection.date}</p>
        </Col>
      </Row>
      <hr />
      <div className="height-vh-60 overflow-y-auto hidden-scrollbar">
        {sections.map((section) => (
          <SectionDetail section={section} key={section.id} />
        ))}
      </div>
    </div>
  );
};

export default InspectionDetail;
