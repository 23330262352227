import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormGroup,
  Label,
  Form,
  Row,
} from "reactstrap";

import { useUpdateEquipment } from "../api/Equipments.hooks";

import { components, sharedHelper } from "@crewos/shared";
const { Loader } = components;

const EquipmentQuantityModal = ({ equipment, onSubmit, onClose }) => {
  const [quantity, setQuantity] = useState(equipment.quantity);

  const {
    isLoading: isUpdatingEquipment,
    update: updateEquipment,
    data: updateEquipmentData,
  } = useUpdateEquipment();

  useEffect(() => {
    if (updateEquipmentData) {
      sharedHelper.successToast(`Equipment quantity saved`);
      onSubmit();
    }
  }, [updateEquipmentData, onSubmit]);

  const doSubmit = async (e) => {
    e.preventDefault();
    await updateEquipment({
      ...equipment,
      quantity,
    });
  };

  return (
    <Modal isOpen={true} onClosed={onClose} size="sm">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Edit Equipment Quantity
      </ModalHeader>
      <Form onSubmit={doSubmit}>
        <ModalBody className="text-center">
          {isUpdatingEquipment ? (
            <Loader size="sm" />
          ) : (
            <Row>
              <FormGroup className="text-start col-12">
                <Label>
                  Quantity<small className="text-danger ms-1">*</small>
                </Label>
                <input
                  className="form-control-redesign"
                  type="number"
                  step={1}
                  max={999}
                  min={1}
                  required={true}
                  value={quantity}
                  onChange={(evt) => setQuantity(evt.target.value)}
                />
              </FormGroup>
            </Row>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>{" "}
          <Button color="primary" type="submit">
            Confirm
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EquipmentQuantityModal;
