import { useContext, useCallback } from "react";
import { v1 as uuidv1 } from "uuid";
import { FormTemplateContext, ELEMENT_STATUS } from "../index";
import ACTIONS from "../actions";

const useOptions = () => {
  const context = useContext(FormTemplateContext);
  const { state, dispatch, getOptions } = context;

  const addOption = useCallback(
    (questionId) => {
      const options = getOptions(questionId);
      const option = {
        id: uuidv1(),
        content: "",
        status: ELEMENT_STATUS.CREATE,
        order: options.length + 1,
        questionId,
      };
      dispatch({ type: ACTIONS.ADD_OPTION, payload: option });
    },
    [dispatch, getOptions]
  );

  const updateOptionContent = useCallback(
    (id, content) => {
      const isNewOption =
        state.options[id] && state.options[id].status === ELEMENT_STATUS.CREATE;
      const status = isNewOption
        ? ELEMENT_STATUS.CREATE
        : ELEMENT_STATUS.UPDATE;
      if (content === state.options[id].content) {
        return;
      }
      dispatch({
        type: ACTIONS.UPDATE_OPTION_CONTENT,
        payload: { id, content, status },
      });
    },
    [dispatch, state.options]
  );

  const deleteOption = useCallback(
    (id) => {
      const options = { ...state.options };
      const isNewOption =
        options[id] && options[id].status === ELEMENT_STATUS.CREATE;
      if (isNewOption) {
        delete options[id];
      } else {
        options[id].status = ELEMENT_STATUS.DELETE;
      }

      const reorderedOptions = Object.values(options)
        .filter((option) => option.status !== ELEMENT_STATUS.DELETE)
        .sort((a, b) => a.order - b.order)
        .reduce((acc, option, index) => {
          acc[option.id] = {
            ...option,
            order: index + 1,
            status:
              option.status !== ELEMENT_STATUS.CREATE
                ? ELEMENT_STATUS.UPDATE
                : option.status,
          };
          return acc;
        }, {});
      dispatch({
        type: ACTIONS.DELETE_OPTION,
        payload: { ...options, ...reorderedOptions },
      });
    },
    [dispatch, state.options]
  );

  const deleteOptions = useCallback(
    (options) => {
      if (options.length === 0) return;
      const optionsMap = { ...state.options };

      options.forEach((option) => {
        const isNewOption =
          optionsMap[option.id] &&
          optionsMap[option.id].status === ELEMENT_STATUS.CREATE;
        if (isNewOption) {
          delete optionsMap[option.id];
        } else {
          optionsMap[option.id].status = ELEMENT_STATUS.DELETE;
        }
      });

      dispatch({ type: ACTIONS.DELETE_OPTION, payload: { ...optionsMap } });
    },
    [dispatch, state.options]
  );

  const copyOption = useCallback(
    (option, { keepOrder = false } = {}) => {
      const options = getOptions(option.questionId);
      const newOption = {
        ...option,
        id: uuidv1(),
        status: ELEMENT_STATUS.CREATE,
        order: keepOrder ? option.order : options.length + 1,
      };
      dispatch({ type: ACTIONS.COPY_OPTION, payload: newOption });
    },
    [dispatch, getOptions]
  );

  return {
    ...context,
    addOption,
    updateOptionContent,
    deleteOption,
    deleteOptions,
    copyOption,
  };
};

export default useOptions;
