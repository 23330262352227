import React, { useEffect, useMemo, useState } from "react";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { api, components, data, sharedHelper } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { useGetSettings, useUpdateSetting } = api;

const {
  Icon,
  Select,
  TooltipItem,
  AdvanceTable,
  ConfirmationModal,
  DynamicAttributesModal,
  CustomCheckbox,
} = components;

const { useAuth, AUTH_ACTIONS } = data;

const isTest = typeof jest !== "undefined";

const columns = (
  onChangeSettingStatus,
  onChangeSettingValue,
  onSeeAttributes,
  workOrderStatus = []
) => [
  {
    accessor: "name",
    header: "Setting",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const value = rowData.row.name;
      return (
        <div data-value={value} className="d-flex align-items-center">
          {value}
          {rowData.row.description ? (
            <div className="ms-2">
              <TooltipItem
                id={`${rowData.row.id}-tooltip`}
                title={rowData.row.description}
              >
                <Icon
                  name="info"
                  className="text-primary"
                  style={{ paddingBottom: "1px" }}
                />
              </TooltipItem>
            </div>
          ) : null}
        </div>
      );
    },
  },
  {
    accessor: "status",
    header: "Status",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) =>
      !rowData.row.valueOnly ? (
        <CustomCheckbox
          checked={rowData.row.status}
          onClick={() =>
            onChangeSettingStatus(rowData.row, !rowData.row.status)
          }
          data-testid="status-switch"
          id={rowData.row.id}
          name={rowData.row.id}
          className="d-inline-flex align-items-center"
          small
        />
      ) : null,
  },
  {
    accessor: "value",
    header: "Value",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { value, status } = rowData.row;

      if (!rowData.row.statusOnly) {
        const options = workOrderStatus.map((status) => ({
          value: status.id,
          label: status.name,
        }));
        const defaultValue = options.find((status) => status.value === value);

        return rowData.row.valueType === "WORK_ORDER_STATUS" ? (
          <Select
            id="statusSelect"
            name="statusSelect"
            data-testid="status-select"
            className="bg-transparent"
            placeholder="Select status"
            defaultValue={defaultValue}
            options={options}
            onChange={(selected) =>
              onChangeSettingValue(rowData.row, selected.value)
            }
          />
        ) : rowData.row.valueType === "DYNAMIC_ATTRIBUTES" ? (
          rowData.row.value?.length ? (
            <Button
              color="primary"
              onClick={() =>
                onSeeAttributes(rowData.row.name, rowData.row.value)
              }
            >
              See attributes
            </Button>
          ) : null
        ) : (
          <DebounceInput
            debounceTimeout={900}
            disabled={rowData.row.valueReadOnly}
            placeholder={`${rowData.row.valuePlaceholder}...`}
            className="form-control"
            data-testid={`setting-value-${rowData.row.id}`}
            maxLength="50"
            min={0}
            type={rowData.row.valueType.toLowerCase()}
            value={value}
            onChange={(e) => onChangeSettingValue(rowData.row, e.target.value)}
          />
        );
      }
      return status ? value || "-" : "-";
    },
  },
];

const Settings = () => {
  const [authContext, setAuthContext] = useAuth();

  const [settings, setSettings] = useState([]);
  const [refresh, setRefresh] = useState();
  const [dynamicAttributesModal, setDynamicAttributesModal] = useState();
  const [confirmationModal, setConfirmationModal] = useState();

  const workOrdersPackage = authContext.userData?.packages.find(
    (pkg) => pkg.name === "Work Orders"
  );

  const {
    data: settingsData,
    isLoading: isLoadingSettings,
    get: getSettings,
  } = useGetSettings();

  useEffect(() => {
    if (workOrdersPackage) {
      getSettings({
        packageId: workOrdersPackage.id,
      });
    }
  }, [getSettings, workOrdersPackage, refresh]);

  useEffect(() => {
    if (settingsData) {
      setSettings(settingsData);
    }
  }, [settingsData, setSettings]);

  const {
    data: updateSettingData,
    isLoading: isLoadingUpdateSetting,
    update: updateSettings,
  } = useUpdateSetting();

  useEffect(() => {
    if (updateSettingData) {
      if (!isTest) {
        setAuthContext({
          action: AUTH_ACTIONS.REFRESH,
        });
      }
      sharedHelper.successToast(`Setting saved`);
    }
  }, [updateSettingData, setAuthContext]);

  const onSeeAttributes = (setting, dynamicAttributes) =>
    setDynamicAttributesModal({ setting, dynamicAttributes });

  const onChangeSettingStatus = (setting, status) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        await updateSettings({
          ...setting,
          status,
        });
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Update setting status",
      body: `Are you sure you want to change this setting status?`,
      confirmText: "Update",
    });
  };

  const onChangeSettingValue = (setting, value) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        await updateSettings({
          ...setting,
          value,
        });
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Update setting value",
      body: `Are you sure you want to change this setting value?`,
      confirmText: "Update",
    });
  };

  const nonInternalSettings = useMemo(
    () => settings.filter((setting) => !setting.isInternal),
    [settings]
  );

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Work Orders Settings</h2>
            <small className="text-muted ms-2 pt-1">({settings.length})</small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <AdvanceTable
            columns={columns(
              onChangeSettingStatus,
              onChangeSettingValue,
              onSeeAttributes,
              authContext.userData.workOrderStatus
            )}
            data={nonInternalSettings}
            pageSize={nonInternalSettings.length || 1}
            isLoading={isLoadingSettings || isLoadingUpdateSetting}
            headerClassName="text-muted small"
            tableProps={{
              striped: true,
            }}
          />
        </CardBody>
      </Card>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : dynamicAttributesModal ? (
        <DynamicAttributesModal
          dynamicAttributes={dynamicAttributesModal.dynamicAttributes}
          setting={dynamicAttributesModal.setting}
          onSubmit={(settingName, value) => {
            const setting = nonInternalSettings.find(
              (s) => s.name === settingName
            );
            onChangeSettingValue(setting, JSON.stringify(value));
            setDynamicAttributesModal();
          }}
          onClose={() => setDynamicAttributesModal()}
        />
      ) : null}
    </Container>
  );
};

export default Settings;
