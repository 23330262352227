import moment from "moment";

export const relevantDiffDates = (startTime, endTime) => {
  const startTimeMoment = moment.utc(startTime);
  const endTimeMoment = moment.utc(endTime);

  if (startTimeMoment.diff(endTimeMoment, "seconds") === 0) {
    return "";
  }

  const diffYears = endTimeMoment.diff(startTimeMoment, "years");
  if (diffYears > 0) {
    return `${diffYears} years`;
  }
  const diffMonths = endTimeMoment.diff(startTimeMoment, "months");
  if (diffMonths > 0) {
    return `${diffMonths} months`;
  }
  const diffDays = endTimeMoment.diff(startTimeMoment, "days");
  if (diffDays > 0) {
    return `${diffDays} days`;
  }
  const diffHours = endTimeMoment.diff(startTimeMoment, "hours");
  if (diffHours > 0) {
    return `${diffHours} hours`;
  }
  const diffMinutes = endTimeMoment.diff(startTimeMoment, "minutes");
  if (diffMinutes > 0) {
    return `${diffMinutes} minutes`;
  }
  const diffSeconds = endTimeMoment.diff(startTimeMoment, "seconds");
  if (diffSeconds > 0) {
    return `${diffSeconds} seconds`;
  }
  return "few seconds";
};
