import brand from "../../assets/img/logoPrimary.png";
import { useAuth } from "../../providers/authProvider";
import {
  ACTIONS as SIDEBAR_ACTIONS,
  useSidebar,
} from "../../providers/sidebarProvider";
import Icon from "../Icon";
import useElementHeight from "./../../hooks/useElementHeight";

const SidebarBrand = () => {
  const [authContext] = useAuth();
  const [sidebarContext, setSidebarContext] = useSidebar();

  const height = useElementHeight("section-header");
  const top = height - 10;

  const onToggle = () => {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.TOGGLE,
    });
  };

  return (
    <div
      className={`ps-sidebar-brand d-flex flex-column align-items-center justify-content-center gap-3 ${
        sidebarContext.isOpen ? "py-4" : "pb-2 pt-4"
      } px-3`}
    >
      <img
        src={authContext.userData?.logo || brand}
        alt={process.env.REACT_APP_PROJECT_NAME}
        className="object-fit-cover"
      />
      <span
        className={`${sidebarContext.isOpen ? "fs-4" : ""} text-black mb-0`}
      >
        {process.env.REACT_APP_PROJECT_NAME}
      </span>

      <div
        className={`sidebar-toggle rotate ${
          !sidebarContext.isOpen ? "rotate-180" : ""
        }`}
        onClick={onToggle}
        style={{ top }}
      >
        <Icon name="chevron-left" />
      </div>
    </div>
  );
};

export default SidebarBrand;
