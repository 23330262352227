import React, { useCallback, useEffect } from "react";
import { Button, Col, Row } from "reactstrap";

import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  verticalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";

import SectionItem from "./SectionItem";

import { components } from "@crewos/shared";

import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";
import SortableWrapper from "./SortableWrapper";

const { Icon } = components;

const Sections = ({ isReadOnly }) => {
  const { getSections, addSection, updateSectionsOrder, updateUnsavedChanges } =
    useInspectionTemplate();
  const sections = getSections();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    ($event) => {
      const { active, over } = $event;
      if (active?.id !== over?.id) {
        const oldIndex = sections.findIndex(
          (section) => section.id === active.id
        );
        const newIndex = sections.findIndex(
          (section) => section.id === over.id
        );
        const newSections = arrayMove(sections, oldIndex, newIndex);
        updateSectionsOrder(newSections);
      }
    },
    [updateSectionsOrder, sections]
  );

  const handleAddSection = useCallback(() => {
    addSection();
  }, [addSection]);

  useEffect(() => {
    if (!sections.length) {
      handleAddSection();
      updateUnsavedChanges(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row className="mt-4 border-bottom-0 mb-0 gap-3 ms-1" sm={1}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis]}
      >
        <SortableContext
          items={sections.map((section) => section.id)}
          strategy={verticalListSortingStrategy}
        >
          {sections.map((section) => (
            <SortableWrapper
              isReadOnly={isReadOnly}
              key={section.id}
              id={section.id}
              className="nav-item rounded-4 rounded-end-0 m-0 d-flex align-items-center gap-4 px-3 py-2"
            >
              <SectionItem section={section} isReadOnly={isReadOnly} />
            </SortableWrapper>
          ))}
          {!isReadOnly && (
            <Col className="nav-item p-0">
              <Button
                size="sm"
                className="py-2 px-3 border-1 border-end-0 btn-dashed rounded-4 rounded-end-0 w-100"
                color="secondary"
                onClick={handleAddSection}
              >
                <Icon name="plus-circle" className="fs-4" /> Add Section
              </Button>
            </Col>
          )}
        </SortableContext>
      </DndContext>
    </Row>
  );
};

export default Sections;
