import React from "react";

const Default = ({ children }) => (
  <React.Fragment>
    <div className="d-flex min-width-lg h-100">
      <div className="min-width-lg d-flex flex-column justify-content-center">
        <div className="p-2 flex-grow-1 d-flex flex-column">{children}</div>
      </div>
    </div>
  </React.Fragment>
);

export default Default;
