import React, { useState, useEffect } from "react";

import ReactQuill from "react-quill";

import {
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
} from "reactstrap";

import { useSendFeedback } from "../api/Contact.hooks";

import Loader from "../components/Loader";
import { sharedHelper } from "../helpers/sharedHelper";

const FeedbackModal = ({ onClose }) => {
  const [body, setBody] = useState();

  const {
    isLoading,
    mutate: sendFeedback,
    data: feedbackData,
  } = useSendFeedback();

  const onSubmit = (evt) => {
    evt.preventDefault();
    if (!body) {
      return sharedHelper.warningToast("Please enter a message");
    }
    sendFeedback({ body });
  };

  useEffect(() => {
    if (feedbackData) {
      sharedHelper.successToast("Thank you for your feedback!");
      onClose();
    }
  }, [feedbackData, onClose]);

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <ModalHeader
        toggle={onClose}
        tag="div"
        className="align-items-start pt-4"
      >
        <div className="d-flex flex-column">
          <h2>We value your feedback</h2>
          <span style={{ fontSize: "1rem" }}>
            Tell us about your experience
          </span>
        </div>
      </ModalHeader>
      {isLoading ? (
        <ModalBody>
          <Loader size="sm" />
        </ModalBody>
      ) : (
        <Form onSubmit={onSubmit}>
          <ModalBody>
            <ReactQuill
              theme="snow"
              value={body}
              onChange={setBody}
              className="border rounded"
              required={true}
            />
          </ModalBody>
          <ModalFooter className="justify-content-between">
            <Button color="secondary" onClick={onClose}>
              Discard
            </Button>
            <Button color="primary" type={"submit"}>
              Send
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Modal>
  );
};

export default FeedbackModal;
