import React from "react";

import { CrewWorkDayWorkTimes } from "@crewos/worktimes";
import { CrewWorkDayTravelTimes } from "@crewos/traveltimes";
import CrewWorkDayCrew from "./CrewWorkDayCrew";

import { data } from "@crewos/shared";

const { useWorkOrderDetails } = data;

const CrewWorkDayTimes = ({ crew }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  if (!crew) {
    return null;
  }

  return (
    <CrewWorkDayCrew crew={crew} key={workOrderDetails.selectedDate}>
      <CrewWorkDayWorkTimes crew={crew} />
      <CrewWorkDayTravelTimes crew={crew} />
    </CrewWorkDayCrew>
  );
};

export default CrewWorkDayTimes;
