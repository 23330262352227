import ACTIONS from "../actions";

const guidanceReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.UPDATE_GUIDANCE_TEXT:
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.questionId]: {
            ...state.questions[action.payload.questionId],
            guidance: {
              ...state.questions[action.payload.questionId].guidance,
              text: action.payload.text,
              status: action.payload.status,
            },
          },
        },
      };
    case ACTIONS.ADD_GUIDANCE_ATTACHMENT:
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.questionId]: {
            ...state.questions[action.payload.questionId],
            guidance: {
              ...state.questions[action.payload.questionId].guidance,
              attachment: action.payload.attachment,
              status: action.payload.status,
            },
          },
        },
      };
    case ACTIONS.REMOVE_GUIDANCE_ATTACHMENT:
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.questionId]: {
            ...state.questions[action.payload.questionId],
            guidance: {
              ...state.questions[action.payload.questionId].guidance,
              attachment: null,
              status: action.payload.status,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default guidanceReducer;
