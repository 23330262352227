import { useCallback } from "react";
import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/work-order-status`;

export const useGetAllWorkOrderStatus = () => {
  return useApiGet(`${endpoint}/all`);
};

export const useGetWorkOrderStatus = () => {
  return useApiGet(endpoint);
};

export const useCreateWorkOrderStatus = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateWorkOrderStatus = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (workOrderStatus) => {
      await mutate(workOrderStatus);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteWorkOrderStatus = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteWorkOrderStatus = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteWorkOrderStatus };
};
