import React, { useCallback, useMemo, useState } from "react";
import { Collapse, NavItem, NavLink } from "reactstrap";

import classnames from "classnames";

import { components } from "@crewos/shared";

const { Icon } = components;

const NavItemTab = ({ workOrderDetails, toggle, label, tab, childs }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasChilds = useMemo(() => {
    return childs;
  }, [childs]);

  const handleToggle = useCallback(
    (assignedTab) => {
      if (!hasChilds) {
        toggle(assignedTab);
      } else {
        setIsOpen((prev) => !prev);
      }
    },
    [setIsOpen, toggle, hasChilds]
  );

  const handleToggleChild = useCallback(
    (assignedTab) => {
      toggle(assignedTab);
    },
    [toggle]
  );

  const isActive = useMemo(() => {
    return (
      workOrderDetails.activeTab === tab ||
      (childs &&
        childs.some((child) => child.tab === workOrderDetails.activeTab))
    );
  }, [workOrderDetails.activeTab, childs, tab]);

  return (
    <NavItem
      className={classnames({
        "border-radius-default bg-white overflow-hidden": isActive,
      })}
    >
      <NavLink
        className={classnames({
          "active fw-bold": isActive,
          "d-flex align-items-center justify-content-between": true,
        })}
        onClick={() => handleToggle(tab)}
      >
        {label}
        {hasChilds && (
          <Icon
            name="chevron-right"
            className={`ms-2 rotate ${isOpen ? "rotate-90" : ""}`}
            style={{ width: "12px", height: "12px" }}
          />
        )}
      </NavLink>
      {hasChilds && (
        <Collapse isOpen={isOpen} tag="ul" className="ps-0">
          {childs.length ? (
            childs.map((child, index) => (
              <NavItem
                key={index}
                className={classnames({
                  "btn overflow-hidden mx-3 mb-2 d-block border-0": true,
                  "bg-primarylight": workOrderDetails.activeTab === child.tab,
                  "bg-transparent": workOrderDetails.activeTab !== child.tab,
                })}
              >
                <NavLink
                  className={classnames({
                    "p-1 text-start": true,
                    "active fw-bold bg-primarylight":
                      workOrderDetails.activeTab === child.tab,
                  })}
                  onClick={() => handleToggleChild(child.tab)}
                >
                  {child.label}
                </NavLink>
              </NavItem>
            ))
          ) : (
            <NavItem
              className={classnames({
                "btn overflow-hidden mx-3 mb-2 d-block border-0 cursor-default": true,
              })}
            >
              <NavLink
                className={classnames({
                  "p-0 text-start small text-muted": true,
                })}
              >
                No {label.toLowerCase()} to show
              </NavLink>
            </NavItem>
          )}
        </Collapse>
      )}
    </NavItem>
  );
};

export default NavItemTab;
