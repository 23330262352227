import ACTIONS from "../actions";

const sectionsReducer = (state, action) => {
  const errors = { ...state.errors };
  switch (action.type) {
    case ACTIONS.UPDATE_SECTION_NAME:
      delete errors[action.payload.id];
      return {
        ...state,
        hasUnsavedChanges: true,
        sections: {
          ...state.sections,
          [action.payload.id]: {
            ...state.sections[action.payload.id],
            name: action.payload.name,
            status: action.payload.status,
          },
        },
        errors,
      };
    case ACTIONS.UPDATE_SECTIONS_ORDER:
      return {
        ...state,
        hasUnsavedChanges: true,
        sections: action.payload,
      };
    case ACTIONS.ADD_SECTION:
      return {
        ...state,
        hasUnsavedChanges: true,
        sections: {
          ...state.sections,
          [action.payload.id]: action.payload,
        },
      };
    case ACTIONS.DELETE_SECTION:
      delete errors[action.payload.id];
      return {
        ...state,
        hasUnsavedChanges: true,
        sections: action.payload,
        errors,
      };
    case ACTIONS.COPY_SECTION:
      return {
        ...state,
        hasUnsavedChanges: true,
        sections: { ...state.sections, [action.payload.id]: action.payload },
      };
    case ACTIONS.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload,
      };
    default:
      return state;
  }
};

export default sectionsReducer;
