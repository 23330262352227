import { useCallback } from "react";

import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const employeeEndpoint = `/api/employee`;

export const useGetEmployees = () => {
  return useApiGet(employeeEndpoint);
};

export const useCreateEmployee = () => {
  return useApiMutation(HttpMethod.POST, employeeEndpoint);
};

export const useUpdateEmployee = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${employeeEndpoint}`
  );

  const update = useCallback(
    async (employee) => {
      await mutate(employee);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteEmployee = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${employeeEndpoint}`
  );

  const deleteEmployee = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteEmployee };
};
