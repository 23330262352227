import poweredDesktop from "../../assets/img/powered_by-lg.png";
import poweredMobile from "../../assets/img/powered_by-xs.png";
import { useSidebar } from "../../providers/sidebarProvider";

const SidebarPoweredBy = () => {
  const [sidebarContext] = useSidebar();

  return sidebarContext.isOpen ? (
    <div className="ps-sidebar-powered d-flex flex-column align-items-center">
      <span className="text-gray text-uppercase">Powered by</span>
      <img width={80} src={poweredDesktop} alt="CrewOS" />
    </div>
  ) : (
    <div className="ps-sidebar-powered d-flex flex-column align-items-center">
      <img width={35} src={poweredMobile} alt="CrewOS" />
    </div>
  );
};

export default SidebarPoweredBy;
