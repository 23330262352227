import React, { useCallback } from "react";
import { Row, Col, Button } from "reactstrap";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { useFormQuestions } from "../../providers/FormTemplateProvider";
import SortableWrapper from "../SortableWrapper";
import FormTemplateQuestionItem from "./FormTemplateQuestionItem";
import Icon from "../Icon";

const FormTemplateQuestions = ({ groupId, isReadOnly, questions }) => {
  const { updateQuestionsOrder, addQuestion } = useFormQuestions();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = useCallback(
    ($event) => {
      const { active, over } = $event;
      if (active && over && active.id !== over.id) {
        const oldIndex = questions.findIndex((item) => item.id === active.id);
        const newIndex = questions.findIndex((item) => item.id === over.id);
        const newItems = arrayMove(questions, oldIndex, newIndex);
        updateQuestionsOrder(newItems);
      }
    },
    [updateQuestionsOrder, questions]
  );

  const handleAddQuestion = useCallback(() => {
    addQuestion(groupId);
  }, [addQuestion, groupId]);
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToVerticalAxis]}
    >
      <SortableContext
        items={questions?.map((question) => question.id)}
        strategy={verticalListSortingStrategy}
      >
        {questions?.map((question) => (
          <SortableWrapper
            id={question.id}
            key={question.id}
            className="question-item sortable-wrapper"
            isReadOnly={isReadOnly}
          >
            <FormTemplateQuestionItem question={question} isReadOnly={isReadOnly} />
          </SortableWrapper>
        ))}
        {!isReadOnly && (
          <Row>
            <Col className="d-grid gap-2 add-btn-container">
              <Button
                size="sm"
                color="white"
                className="btn-dashed"
                onClick={handleAddQuestion}
              >
                <Icon name="circle-plus" /> Add Question
              </Button>
            </Col>
          </Row>
        )}
      </SortableContext>
    </DndContext>
  );
};

export default FormTemplateQuestions;
