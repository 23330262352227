import {createContext, useContext, useReducer} from "react";

const initialState = {
  inspection: null,
  attachments: [],
  isOpenInspectionModal: false,
  isOpenPreviewModal: false,
}

const INSPECTION_DETAIL_ACTIONS = {
  SET_INSPECTION: "SET_INSPECTION",
  SET_ATTACHMENTS: "SET_ATTACHMENTS",
  OPEN_INSPECTION_MODAL: "OPEN_INSPECTION_MODAL",
  CLOSE_INSPECTION_MODAL: "CLOSE_INSPECTION_MODAL",
  OPEN_PREVIEW_MODAL: "OPEN_PREVIEW_MODAL",
  CLOSE_PREVIEW_MODAL: "CLOSE_PREVIEW_MODAL",
};

const InspectionDetailContext = createContext(initialState);

const inspectionDetailReducer = (state, action) => {
  switch (action.type) {
    case INSPECTION_DETAIL_ACTIONS.SET_INSPECTION:
      return {
        ...state,
        inspection: action.payload,
      };
    case INSPECTION_DETAIL_ACTIONS.SET_ATTACHMENTS:
      return {
        ...state,
        attachments: action.payload,
      };
    case INSPECTION_DETAIL_ACTIONS.OPEN_INSPECTION_MODAL:
      return {
        ...state,
        isOpenInspectionModal: true,
        isOpenPreviewModal: false,
      };
    case INSPECTION_DETAIL_ACTIONS.CLOSE_INSPECTION_MODAL:
      return {
        ...state,
        isOpenInspectionModal: false,
      };
    case INSPECTION_DETAIL_ACTIONS.OPEN_PREVIEW_MODAL:
      return {
        ...state,
        isOpenPreviewModal: true,
        isOpenInspectionModal: false,
      };
    case INSPECTION_DETAIL_ACTIONS.CLOSE_PREVIEW_MODAL:
      return {
        ...state,
        isOpenPreviewModal: false,
      };
    default:
      return state;
  }
};

const InspectionDetailProvider = ({ children }) => {
  const [state, dispatch] = useReducer(inspectionDetailReducer, initialState);

  return (
    <InspectionDetailContext.Provider value={{ state, dispatch }}>
      {children}
    </InspectionDetailContext.Provider>
  );
};

const useInspectionDetail = () => {
  const context = useContext(InspectionDetailContext);

  if (!context) {
    throw new Error("useInspectionDetail must be used within an InspectionDetailProvider");
  }

  return context;
}

export {
  InspectionDetailProvider,
  useInspectionDetail,
  INSPECTION_DETAIL_ACTIONS,
};
