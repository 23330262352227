import React, { useCallback, useMemo, useState } from "react";

import { Collapse } from "reactstrap";

import { components } from "@crewos/shared";

import QuestionDetail from "./QuestionDetail";

const { Icon } = components;

const GroupDetail = ({ group }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleCollapse = useCallback(($event) => {
    $event.preventDefault();
    setIsOpen((prev) => !prev);
  }, []);

  const totalAnsweredQuestions = useMemo(() => {
    return group.questions.reduce((acc, question) => {
      if (question.answers.length > 0) {
        return acc + 1;
      }
      return acc;
    }, 0);
  }, [group]);

  return (
    <div
      className={`group-container m-3 border-radius-md border ${
        isOpen ? "border-primarylight" : "border-white"
      }`}
    >
      <div
        className="cursor-pointer p-2 d-flex justify-content-between align-items-center"
        onClick={handleCollapse}
      >
        <div
          data-testid="group-title"
          className="w-100 d-flex justify-content-between align-items-center"
        >
          <span className="fw-semibold">{group.name}</span>
          <small className="ms-1 text-gray">
            {totalAnsweredQuestions}/{group.questions.length}
          </small>
        </div>
        <div className="ms-2">
          <Icon
            name="chevron-right"
            className={`mx-2 rotate ${isOpen ? "rotate-90" : ""}`}
          />
        </div>
      </div>
      <Collapse isOpen={isOpen}>
        {group.questions.map((question) => (
          <QuestionDetail key={question.id} question={question} />
        ))}
      </Collapse>
    </div>
  );
};

export default GroupDetail;
