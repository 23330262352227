import React, { useEffect, useMemo, useState } from "react";

import { Button, Label } from "reactstrap";
import CurrencyInput from "react-currency-input-field";

import { sharedHelper } from "@crewos/shared";
import { crewHelper } from "@crewos/crews";
import { components, data } from "@crewos/shared";

import { useGetAllExpenseTypes } from "../api/ExpenseTypes.hooks";

const { Loader, Icon, Select } = components;

const { useAuth } = data;

const PAYMENT_TYPE_CASH = "cash";
const PAYMENT_TYPE_CARD = "card";

const ENABLE_EMPLOYEES_IN_CREW_PAST_DATES =
  "ENABLE_EMPLOYEES_IN_CREW_PAST_DATES";

const CREWS_PACKAGE = "crews";

const CrewMembers = ({
  crew,
  selectedDate,
  paymentTypeToAdd,
  setPaymentTypeToAdd,
  descriptionToAdd,
  setDescriptionToAdd,
  expenseTypeToAdd,
  setExpenseTypeToAdd,
  employeeEnabled,
  setEmployeeEnabled,
  amountToAdd,
  setAmountToAdd,
}) => {
  const [authContext] = useAuth();
  const [expenseTypes, setExpenseTypes] = useState([]);

  const {
    isLoading: isLoadingGetExpenseTypes,
    get: getExpenseTypes,
    data: expenseTypesData,
  } = useGetAllExpenseTypes();

  useEffect(() => {
    getExpenseTypes();
  }, [getExpenseTypes]);

  useEffect(() => {
    if (expenseTypesData) {
      setExpenseTypes(expenseTypesData);
    }
  }, [expenseTypesData]);

  const employeeCrewPastDatesEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    ENABLE_EMPLOYEES_IN_CREW_PAST_DATES
  );

  const activeCrew = useMemo(
    () =>
      crewHelper.filterCrew(crew, employeeCrewPastDatesEnabled, selectedDate),
    [crew, employeeCrewPastDatesEnabled, selectedDate]
  );

  const crewLead = useMemo(
    () => activeCrew.find((ec) => ec.isLead),
    [activeCrew]
  );

  return (
    <div className="mt-3">
      <div className="fw-bold small py-2 px-3 d-flex justify-content-between align-items-center bg-graylight border-radius-default">
        {crewLead
          ? `${crewLead.employee.firstName} ${crewLead.employee.lastName}`
          : "No Lead"}{" "}
      </div>
      {activeCrew.map((employeeCrew) => {
        const expenseTypesSelect = expenseTypes.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        const defaultExpenseType = expenseTypesSelect.find(
          (item) => item.value === expenseTypeToAdd[employeeCrew.employee.id]
        );

        const paymentTypeSelect = [
          { value: PAYMENT_TYPE_CASH, label: "Cash" },
          { value: PAYMENT_TYPE_CARD, label: "Card" },
        ];

        const defaultPaymentType = paymentTypeSelect.find(
          (item) => item.value === paymentTypeToAdd[employeeCrew.employee.id]
        );

        return (
          <div
            key={employeeCrew.id}
            className="mt-1 d-flex justify-content-between align-items-start p-2 border-bottom col-12"
            tag="div"
          >
            <div className="d-flex flex-column align-items-start col-6 pe-3">
              <small>{`${employeeCrew.employee.firstName} ${employeeCrew.employee.lastName}`}</small>
              <small className="text-muted text-left">{`${employeeCrew.role.name}`}</small>
            </div>
            <div className="ps-3 col-6 d-flex justify-content-end align-items-center align-self-center">
              {employeeEnabled[employeeCrew.employee.id] ? (
                <div className="col-12 px-0 d-flex flex-column align-items-start">
                  <div
                    className="d-flex justify-content-center align-items-center pb-2 col-12 px-0 position-relative"
                    tag="div"
                  >
                    {isLoadingGetExpenseTypes ? (
                      <Loader size="sm" />
                    ) : (
                      <div
                        className="d-flex align-items-start flex-column col-12 px-0"
                        tag="div"
                      >
                        <Label
                          className="d-flex"
                          htmlFor={`expenseTypesSelect-${employeeCrew.employee.id}`}
                        >
                          <small>Type</small>
                          <small className="ms-1 text-danger">*</small>
                        </Label>
                        <Select
                          id={`expenseTypesSelect-${employeeCrew.employee.id}`}
                          name={`expenseTypesSelect-${employeeCrew.employee.id}`}
                          defaultValue={defaultExpenseType}
                          onChange={(selected) =>
                            setExpenseTypeToAdd({
                              ...expenseTypeToAdd,
                              [employeeCrew.employee.id]: selected.value,
                            })
                          }
                          placeholder="Select type..."
                          options={expenseTypesSelect}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: 999,
                      }}
                      onClick={() =>
                        setEmployeeEnabled({
                          ...employeeEnabled,
                          [employeeCrew.employee.id]: false,
                        })
                      }
                    >
                      <Icon
                        name="x-circle"
                        className="cursor-pointer text-danger bg-white"
                      />
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center py-2 col-12 px-0"
                    tag="div"
                  >
                    <div
                      className="d-flex align-items-start flex-column col-12 px-0"
                      tag="div"
                    >
                      <Label
                        className="d-flex"
                        htmlFor={`paymentTypeSelect-${employeeCrew.employee.id}`}
                      >
                        <small>Payment Method</small>
                        <small className="ms-1 text-danger">*</small>
                      </Label>
                      <Select
                        id={`paymentTypeSelect-${employeeCrew.employee.id}`}
                        name={`paymentTypeSelect-${employeeCrew.employee.id}`}
                        data-testid={`paymentTypeSelect-${employeeCrew.employee.id}`}
                        defaultValue={defaultPaymentType}
                        onChange={(selected) =>
                          setPaymentTypeToAdd({
                            ...paymentTypeToAdd,
                            [employeeCrew.employee.id]: selected.value,
                          })
                        }
                        placeholder="Select payment..."
                        options={paymentTypeSelect}
                        required
                      />
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center py-2 col-12 px-0"
                    tag="div"
                  >
                    <div
                      className="d-flex align-items-start flex-column col-12 px-0"
                      tag="div"
                    >
                      <Label
                        className="d-flex"
                        htmlFor={`amount-${employeeCrew.employee.id}`}
                      >
                        <small>Amount</small>
                        <small className="ms-1 text-danger">*</small>
                      </Label>
                      <CurrencyInput
                        data-testid={`amount-${employeeCrew.employee.id}`}
                        className="form-control-redesign"
                        required={true}
                        id={`amount-${employeeCrew.employee.id}`}
                        name={`amount-${employeeCrew.employee.id}`}
                        defaultValue={
                          amountToAdd[employeeCrew.employee.id] || ""
                        }
                        prefix="$"
                        decimalsLimit={2}
                        max={999999999}
                        allowNegativeValue={false}
                        placeholder="Enter the amount.."
                        onValueChange={(value) =>
                          setAmountToAdd({
                            ...amountToAdd,
                            [employeeCrew.employee.id]: parseFloat(value),
                          })
                        }
                      />
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-center align-items-center py-2 col-12 px-0"
                    tag="div"
                  >
                    <div
                      className="d-flex align-items-start flex-column col-12 px-0"
                      tag="div"
                    >
                      <Label
                        className="d-flex"
                        htmlFor={`description-${employeeCrew.employee.id}`}
                      >
                        <small>Description</small>
                        <small className="ms-1 text-danger">*</small>
                      </Label>
                      <input
                        className="form-control-redesign"
                        required={true}
                        rows={4}
                        placeholder="Enter the description .."
                        type="textarea"
                        id={`description-${employeeCrew.employee.id}`}
                        value={descriptionToAdd[employeeCrew.employee.id] || ""}
                        onChange={(event) =>
                          setDescriptionToAdd({
                            ...descriptionToAdd,
                            [employeeCrew.employee.id]:
                              event.currentTarget.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <Button
                  color="primary"
                  size="sm"
                  onClick={() =>
                    setEmployeeEnabled({
                      ...employeeEnabled,
                      [employeeCrew.employee.id]: true,
                    })
                  }
                >
                  Add Expense
                </Button>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CrewMembers;
