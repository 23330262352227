import React, { forwardRef, useEffect, useRef, useCallback } from "react";
import classNames from "classnames";
import "../assets/scss/inputEditable.scss";

const SIZE_INPUT = {
  SMALL: 8,
  MEDIUM: 14,
  LARGE: 18,
};

const InputEditable = forwardRef((props, ref) => {
  const {
    className,
    size = "medium",
    defaultValue,
    errors,
    onChange,
    ...otherProps
  } = props;

  const inputRef = useRef(null);

  const multiplier = SIZE_INPUT[size.toUpperCase()] || SIZE_INPUT.MEDIUM;

  const handleChange = useCallback(
    (event) => {
      inputRef.current.style.width = `${Math.max(
        110,
        event.currentTarget?.value?.length * multiplier
      )}px`;
      if (onChange) {
        onChange(event);
      }
    },
    [onChange, inputRef, multiplier]
  );

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = `${Math.max(
        110,
        inputRef.current?.value?.length * multiplier
      )}px`;
    }
  }, [inputRef, multiplier]);

  return (
    <input
      ref={(el) => {
        inputRef.current = el;
        if (ref) {
          ref.current = el;
        }
      }}
      className={classNames(className, "form-control-editable", {
        [`font-size-${size}`]: size,
        "is-invalid": errors?.length,
      })}
      onChange={handleChange}
      {...otherProps}
      defaultValue={defaultValue}
    />
  );
});

export default InputEditable;
