import ReactSelect from "react-select";

import makeAnimated from "react-select/animated";

import classNames from "classnames";

const animatedComponents = makeAnimated();

const styles = {
  control: (base) => ({
    ...base,
    border: 0,
    boxShadow: "none",
  }),
};

const Select = ({
  className,
  customStyles,
  isMulti = false,
  closeMenuOnSelect = true,
  defaultValue,
  placeholder = "Select...",
  ...attrs
}) => {
  return (
    <ReactSelect
      styles={customStyles ? customStyles : styles}
      classNamePrefix="react-select"
      className={classNames("react-select text-start bg-white px-0", className)}
      components={animatedComponents}
      isMulti={isMulti}
      placeholder={placeholder}
      closeMenuOnSelect={closeMenuOnSelect}
      defaultValue={defaultValue}
      {...attrs}
    />
  );
};

export default Select;
