import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/crew-work-day`;

export const useCreateCrewWorkDay = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useGetCrewWorkDays = (id) => {
  return useApiGet(id ? `${endpoint}/${id}` : `${endpoint}`);
};
