import moment from "moment";

const getClosestDateInRangeToToday = (startDateStr, endDateStr) => {
  const startDate = moment(startDateStr, "YYYY-MM-DD");
  const endDate = moment(endDateStr, "YYYY-MM-DD");
  const today = moment().startOf("day");

  // Si hoy está dentro del rango, devolver hoy
  if (today.isBetween(startDate, endDate, null, "[]")) {
    return today.format("YYYY-MM-DD");
  }

  // Calcular la diferencia en días entre hoy y las fechas de inicio y fin
  const diffToStart = Math.abs(today.diff(startDate, "days"));
  const diffToEnd = Math.abs(today.diff(endDate, "days"));

  // Devolver la fecha más cercana a hoy
  if (diffToStart < diffToEnd) {
    return startDate.format("YYYY-MM-DD");
  } else {
    return endDate.format("YYYY-MM-DD");
  }
};

const getClosestDate = (dates, startDateStr, endDateStr) => {
  const startDate = moment(startDateStr, "YYYY-MM-DD");
  const endDate = moment(endDateStr, "YYYY-MM-DD");
  const today = moment().startOf("day");

  return dates
    .map(function (dateStr) {
      const date = moment(dateStr, "YYYY-MM-DD");
      let distanceToRange = 0;

      if (date.isBefore(startDate)) {
        distanceToRange = startDate.diff(date, "days");
      } else if (date.isAfter(endDate)) {
        distanceToRange = date.diff(endDate, "days");
      }

      const distanceToToday = Math.abs(date.diff(today, "days"));
      return { date: dateStr, distanceToRange, distanceToToday };
    })
    .sort(function (a, b) {
      if (a.distanceToRange !== b.distanceToRange) {
        return a.distanceToRange - b.distanceToRange;
      }
      return a.distanceToToday - b.distanceToToday;
    })[0].date;
};

export const getSelectedDateWorkOrder = (workOrder, crewWorkDays) => {
  /*
	if data: closest day to today that has data
	if no data: closest WO day to today
  */
  let date;
  if (crewWorkDays.length) {
    date = getClosestDate(
      crewWorkDays.map((cwd) => cwd.date),
      workOrder.startDate,
      workOrder.endDate
    );
    return date;
  }
  date = getClosestDateInRangeToToday(workOrder.startDate, workOrder.endDate);
  return date;
};
