const ADD = "ADD";
const SUBTRACT = "SUBTRACT";

const calculateException = (time) => {
  const multiplier =
    time.action === ADD ? 1 : time.action === SUBTRACT ? -1 : 0;
  return time.amount * multiplier;
};

export const calculateExceptions = (time) =>
  (time.exceptions || []).reduce((p, c) => p + calculateException(c), 0);

export const calculateHoursWithExceptions = (time) =>
  time.hours + calculateExceptions(time);
