import React from "react";
import { Outlet } from "react-router-dom";

import Sidebar from "../components/sidebar/Sidebar";

const Admin = () => {
  return (
    <React.Fragment>
      <div className="d-flex min-width-lg h-100">
        <Sidebar />
        <div className="admin-layout d-flex flex-column justify-content-between flex-grow-1">
          <div className="flex-grow-1 d-flex flex-column">
            <Outlet />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Admin;
