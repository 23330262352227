import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const RestrictDeleteTemplateModal = ({
  isOpen,
  onClose,
  template,
  onAccept,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} size="sm">
      <ModalHeader toggle={onClose}>Restrict Delete Template</ModalHeader>
      <ModalBody>
        <p>
          This template has been used in {template.totalInspections}{" "}
          inspections.
        </p>
        <p>For this reason, you cannot delete it.</p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onAccept}>
          Accept
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default RestrictDeleteTemplateModal;
