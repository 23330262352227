import React from "react";

import CrewWorkDayEquipments from "./CrewWorkDayEquipments";
import { CrewWorkDayCrew } from "@crewos/crews";

import { data } from "@crewos/shared";

const { useWorkOrderDetails } = data;

const CrewWorkDay = ({ crew }) => {
  const [workOrderDetails] = useWorkOrderDetails();

  if (!crew) {
    return null;
  }

  return (
    <CrewWorkDayCrew crew={crew} key={workOrderDetails.selectedDate}>
      <CrewWorkDayEquipments crew={crew} />
    </CrewWorkDayCrew>
  );
};

export default CrewWorkDay;
