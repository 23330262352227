import ACTIONS from "../actions";

const optionsReducer = (state, action) => {
  const errors = { ...state.errors };
  switch (action.type) {
    case ACTIONS.ADD_OPTION:
      return {
        ...state,
        hasUnsavedChanges: true,
        options: {
          ...state.options,
          [action.payload.id]: action.payload,
        },
      };
    case ACTIONS.UPDATE_OPTION_CONTENT:
      delete errors[action.payload.questionId];
      return {
        ...state,
        hasUnsavedChanges: true,
        options: {
          ...state.options,
          [action.payload.id]: {
            ...state.options[action.payload.id],
            content: action.payload.content,
            status: action.payload.status,
          },
        },
        errors,
      };
    case ACTIONS.DELETE_OPTION:
      delete errors[action.payload.questionId];
      return {
        ...state,
        hasUnsavedChanges: true,
        options: action.payload,
        errors,
      };
    case ACTIONS.COPY_OPTION:
      return {
        ...state,
        hasUnsavedChanges: true,
        options: {
          ...state.options,
          [action.payload.id]: action.payload,
        },
      };
    default:
      return state;
  }
};

export default optionsReducer;
