import React, { useEffect, useState } from "react";

import {
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Table,
} from "reactstrap";

import { useUpdateSmartReport } from "../api/Reports.hooks";
import { sharedHelper, components, data } from "@crewos/shared";

import { DebounceInput } from "react-debounce-input";

const { CustomCheckbox, Select } = components;

const { useAuth, AUTH_ACTIONS } = data;

const replaceAESDecrypt = (inputString) => {
  const regex = /CONVERT\(AES_DECRYPT\(([^,]+), '([^']+)'\) USING 'UTF8MB4'\)/g;
  return inputString.replace(regex, "$1");
};

const SmartReportDetailModal = ({ report, isReadOnly, onClose, onSubmit }) => {
  const [authContext, setAuthContext] = useAuth();

  const { data: updateReportData, update: updateReport } =
    useUpdateSmartReport();

  const [columns, setColumns] = useState(report.columns);
  const [showSidebar, setShowSidebar] = useState(report.showSidebar);

  useEffect(() => {
    if (updateReportData) {
      const userData = { ...authContext.userData };
      const showSidebar = report.showSidebar && report.showSidebar !== "false";
      const index = userData.smartReports.findIndex((r) => r.id === report.id);
      if (showSidebar) {
        if (index === -1) {
          //add
          userData.smartReports.push(report);
        } else {
          //replace
          userData.smartReports.splice(
            userData.smartReports.findIndex((r) => r.id === report.id),
            1,
            report
          );
        }
      } else if (index !== -1) {
        //remove
        userData.smartReports.splice(index, 1);
      }
      setAuthContext({
        action: AUTH_ACTIONS.SET_USER_DATA,
        payload: { userData },
      });
      sharedHelper.successToast(`Report saved`);
      onSubmit();
    }
  }, [
    updateReportData,
    onSubmit,
    columns,
    report,
    showSidebar,
    authContext.userData,
    setAuthContext,
  ]);

  const doSubmit = () =>
    updateReport({ ...report, showSidebar: `${Boolean(showSidebar)}` });

  const columnTypes = [
    "TEXT",
    "NUMBER",
    "PERCENTAGE",
    "CURRENCY",
    "DATE",
    "DATETIME",
  ].map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <Modal isOpen={true} onClosed={onClose} size="lg">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        Smart Report
      </ModalHeader>
      <ModalBody>
        <Table className="col-12 px-0 mb-0" striped>
          <thead>
            <tr className="bg-graylight small">
              <th>Name</th>
              {!isReadOnly ? <th>Prompt</th> : null}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{report.name}</td>
              {!isReadOnly ? (
                <td>
                  <i>{report.prompt || "-"}</i>
                </td>
              ) : null}
            </tr>
          </tbody>
        </Table>
        <Table className="col-12 px-0 mb-0" striped>
          <thead>
            <tr className="bg-graylight small">
              <th>Description</th>
              {!isReadOnly ? <th>Sidebar Link</th> : null}
            </tr>
          </thead>
          <tbody className="small">
            <tr>
              <td>{report.description || "-"}</td>
              {!isReadOnly ? (
                <td onClick={() => setShowSidebar(!showSidebar)}>
                  <div className="d-flex">
                    <CustomCheckbox checked={showSidebar} />
                  </div>
                </td>
              ) : null}
            </tr>
          </tbody>
        </Table>
        {!isReadOnly ? (
          <Table className="col-12 px-0 mb-0" striped>
            <thead>
              <tr className="bg-graylight small">
                <th>Query</th>
              </tr>
            </thead>
            <tbody className="small">
              <tr>
                <td>
                  <code>{replaceAESDecrypt(report.query)}</code>
                </td>
              </tr>
            </tbody>
          </Table>
        ) : null}
        {!isReadOnly ? (
          <ListGroup flush className="col-12 px-0">
            <ListGroupItem
              className="p-2 bg-graylight border-0 border-radius-default fw-bolder small"
              tag="div"
              key="0"
            >
              Columns
            </ListGroupItem>
            <Table bordered className="col-12 px-0 mb-0 mt-2 small" size="sm">
              <thead>
                <tr className="bg-graylight">
                  <th className="text-start">Label</th>
                  <th className="text-center">Accessor</th>
                  <th className="text-center">Order</th>
                  <th className="text-center">Type</th>
                  <th className="text-end">Enabled</th>
                </tr>
              </thead>
              <tbody>
                {columns
                  .sort((x, y) => x.order - y.order)
                  .map((column) => (
                    <tr key={column.accessor}>
                      <DebounceInput
                        debounceTimeout={900}
                        placeholder="Enter label..."
                        className="form-control form-control-sm py-1 border-radius-0"
                        maxLength="50"
                        type="text"
                        size="sm"
                        value={column.label}
                        onChange={(e) => {
                          column.label = e.target.value;
                          setColumns([...columns]);
                        }}
                      />
                      <td className="text-center">{column.accessor}</td>
                      <td className="text-center">{column.order}</td>
                      <td className="text-center">
                        <Select
                          className="small"
                          id="typeSelect"
                          name="typeSelect"
                          data-testid="type-select"
                          defaultValue={
                            column.type
                              ? { value: column.type, label: column.type }
                              : null
                          }
                          options={columnTypes}
                          onChange={(selected) => {
                            column.type = selected.value;
                            setColumns([...columns]);
                          }}
                        />
                      </td>
                      <td className="text-end">
                        <div
                          className="min-width-50 d-flex justify-content-end"
                          onClick={() => {
                            column.enabled = !column.enabled;
                            setColumns([...columns]);
                          }}
                        >
                          <CustomCheckbox checked={column.enabled} />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </ListGroup>
        ) : null}
      </ModalBody>
      <ModalFooter className="justify-content-between">
        <Button color="secondary" onClick={onClose}>
          Close
        </Button>
        {!isReadOnly ? (
          <Button color="primary" onClick={doSubmit}>
            Save
          </Button>
        ) : null}
      </ModalFooter>
    </Modal>
  );
};

export default SmartReportDetailModal;
