import React from "react";

import { useListTemplateLogic, filters } from "./ListTemplate.logic";

import { components } from "@crewos/shared";

const { ListTemplateWrapper, ListTemplatesGrouped, ListTemplatesItem } = components;
const ListTemplates = () => {
  const {
    templatesGrouped,
    handleRefresh,
    handleSelectFilter,
    handleDeleteItem,
    handleCopyItem,
    handleSearch,
    handleEdit,
    handleView,
  } = useListTemplateLogic();

  return (
    <ListTemplateWrapper title="Safety Templates" caption={`(${templatesGrouped.flatMap((t) => t.templates).length})`} filters={filters} onRefresh={handleRefresh} onSelectFilter={handleSelectFilter} onSearch={handleSearch}>
      {templatesGrouped.map((group) => (
        <ListTemplatesGrouped key={group.title} title={group.title} hasTemplates={group.templates.length > 0}>
          {group.templates.map((template) => (
            <ListTemplatesItem
              key={template.id}
              template={template}
              onDeleteItem={handleDeleteItem}
              onCopyItem={handleCopyItem}
              onEdit={handleEdit}
              onView={handleView}
            />
          ))}
        </ListTemplatesGrouped>
      ))}
    </ListTemplateWrapper>
  );
};

export default ListTemplates;
