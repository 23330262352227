import { useCallback } from "react";
import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/equipment`;

export const useGetEquipments = () => {
  return useApiGet(endpoint);
};

export const useCreateEquipment = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateEquipment = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (equipment) => {
      await mutate(equipment);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteEquipment = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteEquipment = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteEquipment };
};
