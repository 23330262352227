import React, { useMemo, useState, useEffect, useCallback } from "react";

import { Button, Row, Card, CardBody } from "reactstrap";

import { components, sharedHelper, data } from "@crewos/shared";
import { useCrewWorkDays, CREW_WORK_DAY_ACTIONS } from "@crewos/crews";

import Expense from "./Expense";

import { useGetExpenses } from "../api/Expenses.hooks";

const { useAuth, useWorkOrderDetails } = data;

const { Loader, Icon } = components;

const CrewWorkDayExpenses = ({ crew, isReadOnly }) => {
  const [workOrderDetails] = useWorkOrderDetails();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();

  const [refresh, setRefresh] = useState();
  const [expenses, setExpenses] = useState([]);

  //only one crew work day per date/crew
  const crewWorkDay = useMemo(
    () =>
      crewWorkDaysContext.crewWorkDays[workOrderDetails.selectedDate]?.find(
        (cwd) => cwd.crewId === crew.id
      ),
    [workOrderDetails.selectedDate, crewWorkDaysContext.crewWorkDays, crew]
  );

  const {
    data: getExpensesData,
    isLoading: isLoadingGetExpenses,
    get: getExpenses,
  } = useGetExpenses();

  useEffect(() => {
    if (crewWorkDay) {
      getExpenses({
        crewWorkDayId: crewWorkDay.id,
      });
    }
  }, [getExpenses, refresh, crewWorkDay]);

  useEffect(() => {
    if (getExpensesData) {
      setExpenses(getExpensesData);
    }
  }, [getExpensesData, setExpenses]);

  const onRefresh = useCallback(() => {
    if (!crewWorkDay) {
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.REFRESH,
      });
    } else {
      setRefresh((prev) => !prev);
    }
  }, [crewWorkDay, setCrewWorkDaysContext]);

  useEffect(() => {
    if (
      crewWorkDaysContext.refreshCrewSection.crewId === crew.id &&
      crewWorkDaysContext.refreshCrewSection.section === "EXPENSES"
    ) {
      onRefresh();
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.REFRESH_CREW_SECTION_SUCCESS,
      });
    }
  }, [
    crewWorkDaysContext.refreshCrewSection,
    setCrewWorkDaysContext,
    crew,
    onRefresh,
  ]);

  return (
    <Card className="w-100 px-0 box-shadow-none mb-3">
      <CardBody>
        <div className="mb-n1 d-flex align-items-center justify-content-between">
          <h3>Expenses</h3>
          <div className="d-flex align-items-center">
            <Button
              size="sm"
              className="ms-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={onRefresh}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
          </div>
        </div>
        {isLoadingGetExpenses ? (
          <Loader size="sm" className="my-3" />
        ) : expenses.length ? (
          <Row className="mt-3 d-flex justify-content-start align-items-start">
            {expenses.map((expense) => (
              <div key={expense.id} className="col-3 my-1">
                <Expense
                  expense={expense}
                  isReadOnly={isReadOnly}
                  onRefresh={onRefresh}
                />
              </div>
            ))}
          </Row>
        ) : (
          <div className="p-2 small text-muted text-center">No expenses</div>
        )}
      </CardBody>
    </Card>
  );
};

const CrewWorkDayExpensesWrapper = (props) => {
  const [authContext] = useAuth();

  if (
    !sharedHelper.isPackageEnabled(authContext.userData?.packages, "expenses")
  ) {
    return null;
  }

  return <CrewWorkDayExpenses {...props} />;
};

export default CrewWorkDayExpensesWrapper;
