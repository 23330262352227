import React from "react";
import { Container, Spinner } from "reactstrap";

const Loader = ({
  size,
  align = "center",
  color = "primary",
  className = "",
}) => (
  <Container
    fluid
    className={`${size !== "sm" ? "vh-75" : ""} d-flex ${className}`}
  >
    <div
      data-testid="loader"
      className={`h-100 d-flex justify-content-${align} align-items-center w-100`}
    >
      <Spinner size={size} color={color} />
    </div>
  </Container>
);

export default Loader;
