import { useCallback } from "react";
import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/work-order`;

export const useGetWorkOrderWorkPerformed = (id) => {
  return useApiGet(`${endpoint}/${id}/work-performed`);
};

export const useGetWorkOrderForStatus = (id) => {
  return useApiGet(`${endpoint}/${id}/status`);
};

export const useGetWorkOrders = () => {
  return useApiGet(endpoint);
};

export const useCreateWorkOrder = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateWorkOrder = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (wo) => {
      await mutate(wo);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useUpdateWorkOrderStatus = (id) => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PATCH,
    `${endpoint}/${id}/status`
  );

  const update = useCallback(
    async (wo) => {
      await mutate(wo);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteWorkOrder = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteWorkOrder = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteWorkOrder };
};
