import React from "react";

import classNames from "classnames";

import Icon from "./Icon";

const CustomCheckboxWithLabel = ({
  label,
  onClick,
  onChange,
  checked,
  color = "primary",
  className = "text-muted",
  reverse,
  ...attrs
}) => {
  const onClickHandler = () => {
    onClick?.();
    onChange?.(!checked);
  };

  return (
    <div
      role="checkbox"
      aria-checked={checked}
      className={classNames(
        "d-inline-flex cursor-pointer w-100 align-items-center",
        { "justify-content-between": !reverse },
        className
      )}
      onClick={onClickHandler}
      {...attrs}
    >
      {label ? (
        <div
          className={`m${reverse ? "s" : "e"}-2`}
          style={{ order: reverse ? 2 : 1 }}
        >
          {label}
        </div>
      ) : null}
      <div
        style={{ order: reverse ? 1 : 2 }}
        className={`bg-${color} p-1 rounded d-flex align-items-center`}
      >
        {checked ? (
          <Icon
            name="check"
            className="text-white"
            size="sm"
            data-testid="checked-icon"
          />
        ) : (
          <Icon
            name="check"
            className={`text-${color}`}
            size="sm"
            data-testid="unchecked-icon"
          />
        )}
      </div>
    </div>
  );
};

export default CustomCheckboxWithLabel;
