const ACTIONS = {
  UPDATE_SECTION_NAME: "UPDATE_SECTION_NAME",
  UPDATE_SECTIONS_ORDER: "UPDATE_SECTIONS_ORDER",
  ADD_SECTION: "ADD_SECTION",
  DELETE_SECTION: "DELETE_SECTION",
  COPY_SECTION: "COPY_SECTION",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
};

export default ACTIONS;
