import React, { useEffect, useState } from "react";

import {
  useDeleteWorkTimeType,
  useGetWorkTimeTypes,
} from "../../api/WorkTimeTypes.hooks";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components } from "@crewos/shared";

import WorkTimeTypeModal from "../../components/WorkTimeTypeModal";

const { AdvanceTable, AdvanceTablePagination, ConfirmationModal, Icon } =
  components;

const columns = (onDelete, onEdit) => [
  {
    accessor: "name",
    header: "Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.name;
    },
  },
  {
    accessor: "shortName",
    header: "Short Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.shortName;
    },
  },
  {
    accessor: "isBulkEnabled",
    header: "Bulk Enabled",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.isBulkEnabled ? "Yes" : "No";
    },
  },
  {
    accessor: "isAppEnabled",
    header: "App Enabled",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      return rowData.row.isAppEnabled ? "Yes" : "No";
    },
  },
  {
    accessor: "order",
    header: "Order",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    type: "number",
    Cell: (rowData) => {
      return rowData.row.order;
    },
  },
  {
    accessor: "employeeRoleWorkTimeTypes",
    header: "Employee Roles",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    type: "number",
    Cell: (rowData) => {
      return rowData.row.employeeRoleWorkTimeTypes.length;
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    cellProps: {
      className: "text-end",
    },
    headerProps: {
      className: "text-truncate text-end",
      style: { width: 300 },
    },
    Cell: (rowData) => (
      <>
        <Button
          size="sm"
          color="none"
          className="mx-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        <Button
          size="sm"
          className="text-danger"
          color="none"
          onClick={() => onDelete(rowData.row)}
        >
          Delete
        </Button>
      </>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const WorkTimeTypes = () => {
  const [workTimeTypes, setWorkTimeTypes] = useState({});
  const [refresh, setRefresh] = useState();
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);

  const [createModal, setCreateModal] = useState();
  const [updateModal, setUpdateModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: getWorkTimeTypesData,
    isLoading: isLoadingGetWorkTimeTypes,
    get: getWorkTimeTypes,
  } = useGetWorkTimeTypes();

  useEffect(() => {
    getWorkTimeTypes({
      page,
      pageSize: sizePerPage,
    });
  }, [getWorkTimeTypes, refresh, page, sizePerPage]);

  useEffect(() => {
    if (getWorkTimeTypesData) {
      setWorkTimeTypes(getWorkTimeTypesData);
    }
  }, [getWorkTimeTypesData, setWorkTimeTypes]);

  const { data: deleteWorkTimeTypeData, deleteWorkTimeType } =
    useDeleteWorkTimeType();

  useEffect(() => {
    if (deleteWorkTimeTypeData) {
      setRefresh((prev) => !prev);
    }
  }, [deleteWorkTimeTypeData]);

  const onEdit = (workTimeType) => {
    setUpdateModal(workTimeType);
  };

  const onDelete = (workTimeType) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteWorkTimeType(workTimeType.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete Work Time Type",
      body: `Are you sure you want to delete ${workTimeType.name}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Work Time Types</h2>
            <small className="text-muted ms-2 pt-1">
              ({workTimeTypes.count})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
              data-testid="refresh-button"
            >
              <Icon name="refresh-cw" />
            </Button>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(onDelete, onEdit)}
              data={workTimeTypes.data || []}
              pageSize={sizePerPage}
              isLoading={isLoadingGetWorkTimeTypes}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={workTimeTypes.count}
            pageCount={workTimeTypes.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : createModal ? (
        <WorkTimeTypeModal
          onClose={() => setCreateModal()}
          onSubmit={() => {
            setCreateModal();
            setRefresh((prev) => !prev);
          }}
        />
      ) : updateModal ? (
        <WorkTimeTypeModal
          workTimeType={updateModal}
          onClose={() => setUpdateModal()}
          onSubmit={() => {
            setUpdateModal();
            setRefresh((prev) => !prev);
          }}
        />
      ) : null}
    </Container>
  );
};

export default WorkTimeTypes;
