import { useContext, useCallback } from "react";
import { FormTemplateContext, ELEMENT_STATUS } from "../index";
import ACTIONS from "../actions";

const useGuidance = () => {
  const context = useContext(FormTemplateContext);
  const { state, dispatch } = context;

  const updateGuidanceText = useCallback((questionId, text) => {
    const isNewGuidance = state.questions[questionId].guidance.status === ELEMENT_STATUS.CREATE;
    const status = isNewGuidance ? ELEMENT_STATUS.CREATE : ELEMENT_STATUS.UPDATE;
    if (text === state.questions[questionId].guidance.text) {
      return;
    }
    dispatch({ type: ACTIONS.UPDATE_GUIDANCE_TEXT, payload: { questionId, text, status } });
  }, [dispatch, state.questions]);

  const addGuidanceAttachment = useCallback((questionId, attachment) => {
    const isNewGuidance = state.questions[questionId].guidance.status === ELEMENT_STATUS.CREATE;
    const status = isNewGuidance ? ELEMENT_STATUS.CREATE : ELEMENT_STATUS.UPDATE;
    dispatch({ type: ACTIONS.ADD_GUIDANCE_ATTACHMENT, payload: { questionId, attachment, status } });
  }, [dispatch, state.questions]);

  const removeGuidanceAttachment = useCallback((questionId) => {
    const isNewGuidance = state.questions[questionId].guidance.status === ELEMENT_STATUS.CREATE;
    const status = isNewGuidance ? ELEMENT_STATUS.CREATE : ELEMENT_STATUS.UPDATE;
    dispatch({ type: ACTIONS.REMOVE_GUIDANCE_ATTACHMENT, payload: { questionId, status } });
  }, [dispatch, state.questions]);

  return { ...context, updateGuidanceText, addGuidanceAttachment, removeGuidanceAttachment };
};

export default useGuidance;
