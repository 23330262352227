import { sharedHelper, data } from "@crewos/shared";
import { Outlet } from "react-router-dom";
import React from "react";
import { InspectionTemplateProvider } from "../providers/inspectionTemplateProvider";

const { useAuth } = data;

const InspectionWrapper = () => {
  const [authContext] = useAuth();

  const packageEnabled = sharedHelper.isPackageEnabled(
    authContext.userData?.packages,
    "inspections"
  );
  if (!packageEnabled || !authContext.userData) {
    return null;
  }

  return (
    <InspectionTemplateProvider>
      <Outlet />
    </InspectionTemplateProvider>
  );
};

export default InspectionWrapper;
