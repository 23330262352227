import { v1 as uuidv1 } from "uuid";
import { createContext, useReducer, useCallback } from "react";
import templateReducer from "./reducers/template.reducer";
import sectionsReducer from "./reducers/sections.reducer";
import groupsReducer from "./reducers/groups.reducer";
import questionsReducer from "./reducers/questions.reducer";
import optionsReducer from "./reducers/options.reducer";
import guidanceReducer from "./reducers/guidance.reducer";

import useFormSections from "./hooks/sections.hook";
import useFormTemplate from "./hooks/template.hook";
import useFormGroups from "./hooks/groups.hook";
import useFormQuestions from "./hooks/questions.hook";
import useFormOptions from "./hooks/options.hook";
import useGuidance from "./hooks/guidance.hook";

import { sharedHelper } from "../../helpers/sharedHelper";

import ACTIONS from "./actions";

const ELEMENT_STATUS = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  WITHOUT_CHANGE: "WITHOUT_CHANGE",
};

const ORIGIN_PAGE = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  VIEW: "VIEW",
};

const initialState = {
  template: {
    id: uuidv1(),
    name: "",
  },
  sections: {},
  groups: {},
  questions: {},
  options: {},
  activeTab: null,
  questionTypes: [],
  origin: ORIGIN_PAGE.CREATE,
  hasUnsavedChanges: false,
  errors: {},
};

const FormTemplateContext = createContext(initialState);

const combineReducers = (...reducers) => {
  return (state, action) => {
    return reducers.reduce((acc, reducer) => {
      return reducer(acc, action);
    }, state);
  };
};

const formTemplateReducer = combineReducers(
  templateReducer,
  sectionsReducer,
  groupsReducer,
  questionsReducer,
  optionsReducer,
  guidanceReducer
);

const FormTemplateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(formTemplateReducer, initialState);

  const getErrors = useCallback(
    (id) => {
      return state.errors[id] || [];
    },
    [state.errors]
  );

  const getQuestionTypes = useCallback(() => {
    return state.questionTypes;
  }, [state.questionTypes]);

  const getDefaultQuestionType = useCallback(() => {
    return state.questionTypes.find(
      (questionType) => questionType.slug === "MULTIPLE_CHOICE_FIELD"
    );
  }, [state.questionTypes]);

  const getOptions = useCallback(
    (questionId, options = {}) => {
      const { includeDeleted = false } = options;
      return Object.values(state.options)
        .filter(
          (option) =>
            option.questionId === questionId &&
            (includeDeleted ? true : option.status !== ELEMENT_STATUS.DELETE)
        )
        .sort((a, b) => a.order - b.order);
    },
    [state.options]
  );

  const getQuestions = useCallback(
    (groupId, options = {}) => {
      const { includeDeleted = false, verifyData = false } = options;
      return Object.values(state.questions)
        .filter(
          (question) =>
            question.groupId === groupId &&
            (includeDeleted ? true : question.status !== ELEMENT_STATUS.DELETE)
        )
        .sort((a, b) => a.order - b.order)
        .map((question) => {
          const errors = [];
          if (verifyData) {
            if (!question.content) {
              errors.push("Question content is required");
            }
            if (!question.questionType) {
              errors.push("Question type is required");
            }

            if (question.questionType.hasOptions) {
              const options = getOptions(question.id, {
                includeDeleted: false,
              });
              const allOptionsEmpty = options.every(
                (option) => option.content === ""
              );
              if (allOptionsEmpty || options.length === 0) {
                errors.push("At least one option is required");
              }
              const someOptionsEmpty = options.some(
                (option) => option.content === ""
              );
              if (someOptionsEmpty) {
                errors.push("Some options are empty");
              }
              const optionsContent = options.map(({ content }) => content);
              const uniqueContent = new Set(optionsContent);
              if (uniqueContent.size !== optionsContent.length) {
                errors.push("Options must be unique");
              }
            }
            if (
              question.guidance &&
              question.guidance.status !== ELEMENT_STATUS.DELETE
            ) {
              const isEmptyGuidanceText =
                (question.guidance.text || "")
                  .replaceAll(/(<[/]?[\w]+[/]?>)/g, "")
                  .trim() === "";
              if (!question.guidance.attachment && isEmptyGuidanceText) {
                errors.push("Guidance attachment or text is required");
              }
            }
          }
          return { ...question, errors };
        });
    },
    [state.questions, getOptions]
  );

  const getGroups = useCallback(
    (sectionId, options = {}) => {
      const { includeDeleted = false, verifyData = false } = options;
      return Object.values(state.groups)
        .filter(
          (group) =>
            group.sectionId === sectionId &&
            (includeDeleted ? true : group.status !== ELEMENT_STATUS.DELETE)
        )
        .sort((a, b) => a.order - b.order)
        .map((group) => {
          const errors = [];
          if (verifyData && group.name === "") {
            errors.push("Group name is required");
          }
          return { ...group, errors };
        });
    },
    [state.groups]
  );

  const getSections = useCallback(
    (options = {}) => {
      const { includeDeleted = false, verifyData = false } = options;
      return Object.values(state.sections)
        .filter((section) =>
          includeDeleted ? true : section.status !== ELEMENT_STATUS.DELETE
        )
        .sort((a, b) => a.order - b.order)
        .map((section) => {
          const errors = [];
          if (verifyData && section.name === "") {
            errors.push("Section name is required");
          }
          return { ...section, errors };
        });
    },
    [state.sections]
  );

  const getTotalQuestionsBySection = useCallback(
    (sectionId) => {
      return getGroups(sectionId, { includeDeleted: false }).reduce(
        (acc, group) => {
          return acc + getQuestions(group.id, { includeDeleted: false }).length;
        },
        0
      );
    },
    [getGroups, getQuestions]
  );

  const uploadGuidanceAttachments = useCallback(async () => {
    const guidanceAttachments = Object.values(state.questions).filter(
      (question) => question.guidance && question.guidance.attachment
    );
    const attachments = guidanceAttachments.filter(
      (question) => question.guidance.attachment instanceof File
    );
    if (attachments.length === 0) {
      return [];
    }
    const uploadedAttachments = new Map(
      attachments.map((question) => [question.id, question.guidance.attachment])
    );
    const files = Array.from(uploadedAttachments.values());
    try {
      const { urls } = await sharedHelper.uploadFile(files);
      const questions = Object.values(state.questions);
      const questionsUpdated = questions.map((question) => {
        if (uploadedAttachments.has(question.id)) {
          return {
            ...question,
            guidance: {
              ...question.guidance,
              attachment: urls.find((url) =>
                url.endsWith(uploadedAttachments.get(question.id).name)
              ),
            },
          };
        }
        return question;
      });
      dispatch({ type: ACTIONS.UPDATE_QUESTIONS, payload: questionsUpdated });
      return urls;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [dispatch, state.questions]);

  const getFullTemplate = useCallback(
    async (options = {}) => {
      const { verifyData = false } = options;
      const urls = await uploadGuidanceAttachments();
      if (!urls) {
        return {
          data: null,
          failedUploads: true,
          errors: state.errors,
        };
      }
      const errors = { ...state.errors };
      if (verifyData && state.template.name === "") {
        errors[state.template.id] = ["Template name is required"];
      }
      const data = {
        ...state.template,
        configuration: state.configuration,
        sections: getSections({ includeDeleted: true, verifyData }).map(
          ({ errors: sectionErrors, ...section }) => {
            if (sectionErrors.length > 0) {
              errors[section.id] = sectionErrors;
            }
            return {
              ...section,
              groups: getGroups(section.id, {
                includeDeleted: true,
                verifyData,
              }).map(({ errors: groupErrors, ...group }) => {
                if (groupErrors.length > 0) {
                  errors[group.id] = groupErrors;
                }
                return {
                  ...group,
                  questions: getQuestions(group.id, {
                    includeDeleted: true,
                    verifyData,
                  }).map(({ errors: questionErrors, ...question }) => {
                    if (questionErrors.length > 0) {
                      errors[question.id] = questionErrors;
                    }
                    const guidance = question.guidance
                      ? {
                          ...question.guidance,
                          attachment:
                            question.guidance.attachment instanceof File
                              ? urls.find((url) =>
                                  url.endsWith(
                                    question.guidance.attachment.name
                                  )
                                )
                              : question.guidance.attachment,
                        }
                      : null;
                    return {
                      ...question,
                      guidance,
                      options: getOptions(question.id, {
                        includeDeleted: true,
                      }),
                    };
                  }),
                };
              }),
            };
          }
        ),
      };
      dispatch({ type: "SET_ERRORS", payload: errors });
      return { data, errors, failedUploads: false };
    },
    [
      state.configuration,
      state.template,
      getSections,
      getGroups,
      getQuestions,
      getOptions,
      state.errors,
      uploadGuidanceAttachments,
    ]
  );

  return (
    <FormTemplateContext.Provider
      value={{
        state,
        dispatch,
        getErrors,
        getSections,
        getGroups,
        getQuestions,
        getOptions,
        getFullTemplate,
        getQuestionTypes,
        getDefaultQuestionType,
        getTotalQuestionsBySection,
      }}
    >
      {children}
    </FormTemplateContext.Provider>
  );
};

export {
  ELEMENT_STATUS,
  ORIGIN_PAGE,
  FormTemplateContext,
  FormTemplateProvider,
  useFormSections,
  useFormTemplate,
  useFormGroups,
  useFormQuestions,
  useFormOptions,
  formTemplateReducer,
  useGuidance,
};
