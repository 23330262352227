import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { useCreateSmartReport } from "../api/Reports.hooks";

import { components, data, sharedHelper } from "@crewos/shared";

const { useAuth, AUTH_ACTIONS } = data;

const { Icon, TooltipItem, CustomCheckboxWithLabel } = components;

const SaveSmartReportModal = ({ report, workOrderId, onClose, onSubmit }) => {
  const [authContext, setAuthContext] = useAuth();

  const [name, setName] = useState("");
  const [description, setDescription] = useState();
  const [showSidebar, setShowSidebar] = useState();

  const doSubmit = async (evt) => {
    evt.preventDefault();
    const { columns, prompt, query } = report;
    await createReport({
      columns,
      description,
      name,
      prompt,
      query,
      showSidebar,
      workOrderId,
    });
  };

  const { mutate: createReport, data: createReportData } =
    useCreateSmartReport();

  useEffect(() => {
    if (createReportData) {
      const report = createReportData.data;
      const userData = { ...authContext.userData };
      const showSidebar = report.showSidebar && report.showSidebar !== "false";
      const index = userData.smartReports.findIndex((r) => r.id === report.id);
      if (showSidebar) {
        if (index === -1) {
          //add
          userData.smartReports.push(report);
        } else {
          //replace
          userData.smartReports.splice(
            userData.smartReports.findIndex((r) => r.id === report.id),
            1,
            report
          );
        }
      } else if (index !== -1) {
        //remove
        userData.smartReports.splice(index, 1);
      }
      setAuthContext({
        action: AUTH_ACTIONS.SET_USER_DATA,
        payload: { userData },
      });
      sharedHelper.successToast(`Report saved`);
      onSubmit(createReportData.data);
    }
  }, [
    createReportData,
    onSubmit,
    authContext.userData,
    report,
    setAuthContext,
  ]);

  return (
    <Modal isOpen={true} size="sm">
      <Form onSubmit={doSubmit}>
        <ModalHeader
          className="d-flex justify-content-between"
          toggle={onClose}
        >
          <div className="d-flex align-items-center">
            <span>Save Smart Report</span>
            <TooltipItem
              className="font-size-regular fw-regular"
              id="context-tooltip"
              title={`${workOrderId ? "Work Order" : "Global"} Context`}
            >
              <Icon
                name="info"
                className="text-primary text-warning ms-2"
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          </div>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>
              <span>Name</span>
              <span className="text-danger ms-1">*</span>
            </Label>
            <input
              className="form-control-redesign"
              maxLength="15"
              type="text"
              placeholder="Enter the report name..."
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label>
              <span>Description</span>
            </Label>
            <input
              className="form-control-redesign"
              maxLength="1000"
              type="textarea"
              placeholder="Enter the report description..."
              name="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <div
              className="d-flex"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <CustomCheckboxWithLabel
                className="mt-2"
                checked={showSidebar}
                label="Sidebar Link"
              />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color="secondary" onClick={onClose} className="text-dark">
            Cancel
          </Button>
          <Button color="primary" type="submit" data-testid="submit-button">
            Save
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default SaveSmartReportModal;
