import React, { useEffect, useMemo, useCallback } from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { components } from "@crewos/shared";
import { useGetInspectionsTemplates } from "../api/Inspections.hooks";

const { SelectAdvance } = components;

const AssignTemplateWOForm = ({ onSelectTemplate, error }) => {
  // Use the custom hook to fetch inspection templates
  const {
    data: templatesData,
    isLoading: isLoadingTemplates,
    get: fetchTemplates,
  } = useGetInspectionsTemplates();

  // Effect to fetch templates on component mount
  useEffect(() => {
    fetchTemplates({ search: "", pageSize: 30, status: "PUBLISHED" });
  }, [fetchTemplates]);

  // Handler for searching templates
  const handleSearchTemplates = useCallback(
    (searchText) => {
      fetchTemplates({ search: searchText, pageSize: 30, status: "PUBLISHED" });
    },
    [fetchTemplates]
  );

  // Memoize the options for SelectAdvance
  const templateOptions = useMemo(() => {
    if (templatesData && templatesData.data) {
      return templatesData.data.map((template) => ({
        value: template.id,
        label: template.name, // Assuming 'name' is the label key
      }));
    }
    return [];
  }, [templatesData]);

  // Handler for when a template is selected
  const handleSelect = useCallback(
    (selectedOption) => {
      onSelectTemplate(selectedOption);
    },
    [onSelectTemplate]
  );

  return (
    <>
      <FormGroup>
        <Label data-testid="asset-name">
          <span>Asset Name</span>
          <span className="text-danger ms-1">*</span>
        </Label>
        <Input
          className="form-control-line"
          type="text"
          placeholder="Type an asset name"
          required
          name="name"
        />
      </FormGroup>
      <FormGroup>
        <Label data-testid="select-template-label">
          <span>Select an Inspection Template</span>
          <span className="text-danger ms-1">*</span>
        </Label>
        <SelectAdvance
          entity="template"
          name="template"
          className={`form-control-redesign ${
            error?.template?.length > 0 ? "border-warning color-warning" : ""
          }`}
          wrapperClassName={error?.template?.length > 0 ? "is-invalid" : ""}
          placeholder="Search a template..."
          noOptionsMessage="No templates found"
          options={templateOptions} // Pass the transformed options
          isLoading={isLoadingTemplates} // Pass the loading state
          onSelected={handleSelect} // Pass the selection handler
          onSearch={handleSearchTemplates} // Pass the search handler
          aria-invalid={error?.template?.length > 0}
        />
        {error?.template?.length > 0 &&
          error.template.map((errorLine, index) => (
            <FormFeedback key={index}>{errorLine}</FormFeedback>
          ))}
      </FormGroup>
    </>
  );
};

export default AssignTemplateWOForm;
