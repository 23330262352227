import { api } from "@crewos/shared";

const { useApiGet, useApiMutation, HttpMethod } = api;

export const useGetInspections = () => {
  return useApiGet(`/api/inspection`);
};

export const useGetInspectionsTemplates = () => {
  return useApiGet(`/api/inspection-template`);
};

export const useGetQuestionTypes = () => {
  return useApiGet(`/api/inspection-question-type`);
};

export const useUpdateOrCreateTemplateFull = (id) => {
  const method = !id ? HttpMethod.POST : HttpMethod.PUT;
  return useApiMutation(method, `/api/inspection-template/full`);
};

export const useGetTemplate = (id) => {
  return useApiGet(`/api/inspection-template/${id}`);
};

export const useDeleteTemplate = () => {
  return useApiMutation(HttpMethod.DELETE, `/api/inspection-template`);
};

export const useDuplicateTemplate = (id) => {
  return useApiMutation(
    HttpMethod.POST,
    `/api/inspection-template/${id}/duplicate`
  );
};

export const useGetTemplateWorkOrder = () => {
  return useApiGet(`/api/inspection-template-work-order`);
};

export const useCreateTemplateWorkOrder = () => {
  return useApiMutation(HttpMethod.POST, `/api/inspection-template-work-order`);
};

export const useDeleteInspectionTemplateWorkOrder = () => {
  return useApiMutation(
    HttpMethod.DELETE,
    `/api/inspection-template-work-order`
  );
};

export const useDeleteInspection = () => {
  return useApiMutation(HttpMethod.DELETE, `/api/inspection`);
};

export const useGetInspection = () => {
  return useApiGet(`/api/inspection`);
};
