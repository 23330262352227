import React, { useCallback, useState } from "react";

import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { components } from "@crewos/shared";

import { useInspectionTemplate } from "../providers/inspectionTemplateProvider";
import DeleteModal from "./DeleteModal";
import InputEditable from "./InputEditable";

const { Icon } = components;

const SectionItem = ({ section, isReadOnly }) => {
  const {
    updateSection,
    copySection,
    removeSection,
    setActiveTab,
    getTotalQuestionsBySection,
    getErrors,
    state: { activeTab },
  } = useInspectionTemplate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const totalQuestions = getTotalQuestionsBySection(section.id);

  const handleUpdateName = useCallback(
    (section, $event) => {
      const value = $event.target.value;
      updateSection({ ...section, name: value });
    },
    [updateSection]
  );

  const handleCopySection = useCallback(() => {
    copySection(section);
  }, [copySection, section]);

  const handleDeleteSection = useCallback(() => {
    removeSection(section);
  }, [removeSection, section]);

  const handleDeleteSectionModal = useCallback(() => {
    setIsModalOpen((prev) => !prev);
  }, [setIsModalOpen]);

  return (
    <div onClick={() => setActiveTab(section.id)}>
      <Row
        className={`w-100 align-items-center bg-transparent p-0 m-0 border-0 rounded-0 ${
          activeTab === section.id ? "active" : ""
        }`}
      >
        <Col sm={10} className="d-flex justify-content-start flex-column p-0">
          <InputEditable
            value={section.name}
            disabled={isReadOnly}
            onChange={($event) => handleUpdateName(section, $event)}
            placeholder="Section Name"
            size="small"
            className="max-width-200"
            data-testid="section-name"
            errors={getErrors(section.id)}
          />
          <small className="text-muted fst-italic">
            {totalQuestions || 0} questions
          </small>
        </Col>
        {!isReadOnly && (
          <Col
            sm={1}
            className="d-flex justify-content-end align-items-center p-0"
          >
            <UncontrolledDropdown group className="ms-2">
              <DropdownToggle caret={false} color="white">
                <Icon name="more-vertical" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={handleCopySection}
                  className="d-flex align-items-center gap-2"
                >
                  <Icon name="copy" />
                  <span className="ms-1">Copy</span>
                </DropdownItem>
                <DropdownItem
                  onClick={handleDeleteSectionModal}
                  className="d-flex align-items-center gap-2 text-danger"
                >
                  <Icon name="trash" />
                  <span className="ms-1">Delete</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Col>
        )}
      </Row>
      <DeleteModal
        title="Delete Section"
        name={section.name}
        isOpen={isModalOpen}
        onDelete={handleDeleteSection}
        onClose={() => setIsModalOpen((prev) => !prev)}
      />
    </div>
  );
};

export default SectionItem;
