import { createContext, useReducer, useContext } from "react";

const SafetyTemplateContext = createContext(null);

const initialState = {
  template: null,
};

const safetyTemplateReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const SafetyTemplateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(safetyTemplateReducer, initialState);

  return (
    <SafetyTemplateContext.Provider value={{ state, dispatch }}>
      {children}
    </SafetyTemplateContext.Provider>
  );
};

const useSafetyTemplate = () => {
  const context = useContext(SafetyTemplateContext);
  if (context === undefined) {
    throw new Error(
      "useSafetyTemplate must be used within a SafetyTemplateProvider"
    );
  }
  return context;
};

export { SafetyTemplateProvider, useSafetyTemplate };
