import { useCallback } from "react";

import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const expenseEndpoint = `/api/expense`;

export const useGetExpenses = () => {
  return useApiGet(expenseEndpoint);
};

export const useCreateExpense = () => {
  return useApiMutation(HttpMethod.POST, expenseEndpoint);
};

export const useUpdateExpense = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, expenseEndpoint);

  const update = useCallback(
    async (expense) => {
      await mutate(expense);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useDeleteExpense = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    expenseEndpoint
  );

  const deleteExpense = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteExpense };
};
