import React from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { components } from "@crewos/shared";

const { Icon } = components;

const SortableWrapper = ({ id, children, className, isReadOnly }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
    isDragging,
  } = useSortable({ id, disabled: isReadOnly });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: "unset",
  };
  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      className={`${className} d-flex gap-3 ${isDragging ? "is-dragging" : ""}`}
    >
      <div className={` py-4 px-0 ${isReadOnly ? "opacity-0" : ""}`}>
        <Icon
          name="grip-vertical"
          ref={setActivatorNodeRef}
          {...listeners}
          className="draggable-icon"
          aria-label={`Drag ${id}`}
          style={{ cursor: "grab" }}
        />
      </div>
      <div className="w-100">{children}</div>
    </div>
  );
};

export default SortableWrapper;
