import React from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ImagesViewer from "./ImagesViewer";

const ImagesViewerModal = ({ title, items, onClose, onDelete, showCaption = true, showActions = true, slide = true }) => {

  return (
    <Modal isOpen={true} onClosed={onClose} size="xl">
      <ModalHeader toggle={onClose} className="d-flex justify-content-between">
        {title}
      </ModalHeader>
      <ModalBody>
        <ImagesViewer onDelete={onDelete} items={items} showCaption={showCaption} showActions={showActions} slide={slide} />
      </ModalBody>
      <ModalFooter className="justify-content-end">
        <Button color="primary" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImagesViewerModal;
