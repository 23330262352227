const ACTIONS = {
  UPDATE_QUESTION_NAME: "UPDATE_QUESTION_NAME",
  UPDATE_QUESTIONS_ORDER: "UPDATE_QUESTIONS_ORDER",
  ADD_QUESTION: "ADD_QUESTION",
  DELETE_QUESTION: "DELETE_QUESTION",
  COPY_QUESTION: "COPY_QUESTION",
  UPDATE_QUESTION_TYPE: "UPDATE_QUESTION_TYPE",
  UPDATE_QUESTION_REQUIRED: "UPDATE_QUESTION_REQUIRED",
  ADD_GUIDANCE: "ADD_GUIDANCE",
  DELETE_GUIDANCE: "DELETE_GUIDANCE",
};

export default ACTIONS;
