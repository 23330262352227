import { useCallback } from "react";
import { api } from "@crewos/shared";

const { HttpMethod, useApiMutation, useApiGet } = api;

const endpoint = `/api/non-job-time`;

export const useGetNonJobTimes = () => {
  return useApiGet(endpoint);
};

export const useCreateNonJobTime = () => {
  return useApiMutation(HttpMethod.POST, endpoint);
};

export const useUpdateNonJobTime = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, endpoint);

  const update = useCallback(
    async (nonJobTime) => {
      await mutate(nonJobTime);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useRecalculateNonJobTime = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.PUT, `${endpoint}/re`);

  const update = useCallback(
    async (nonJobTime) => {
      await mutate(nonJobTime);
    },
    [mutate]
  );

  return { ...rest, update };
};

export const useBulkUpdateNonJobTime = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.PUT,
    `${endpoint}/bulk`
  );

  const bulkUpdate = useCallback(
    async (nonJobTimes) => {
      await mutate(nonJobTimes);
    },
    [mutate]
  );

  return { ...rest, bulkUpdate };
};

export const useDeleteNonJobTime = () => {
  const { mutate, ...rest } = useApiMutation(HttpMethod.DELETE, endpoint);

  const deleteNonJobTime = useCallback(
    async (id) => {
      await mutate({ id });
    },
    [mutate]
  );

  return { ...rest, deleteNonJobTime };
};

export const useBulkDeleteNonJobTime = () => {
  const { mutate, ...rest } = useApiMutation(
    HttpMethod.DELETE,
    `${endpoint}/bulk`
  );

  const bulkDeleteNonJobTimes = useCallback(
    async (nonJobTimes) => {
      await mutate(nonJobTimes);
    },
    [mutate]
  );

  return { ...rest, bulkDeleteNonJobTimes };
};
