import sectionsActions from "./sections.actions";
import groupsActions from "./groups.actions";
import questionsActions from "./questions.actions";
import optionsActions from "./options.actions";
import guidanceActions from "./guidance.actions";

const ACTIONS = {
  UPDATE_TEMPLATE_NAME: "UPDATE_TEMPLATE_NAME",
  SET_ERRORS: "SET_ERRORS",
  SET_QUESTION_TYPES: "SET_QUESTION_TYPES",
  INITIALIZE_TEMPLATE: "INITIALIZE_TEMPLATE",
  UPDATE_QUESTIONS: "UPDATE_QUESTIONS",
  SET_CONFIGURATION: "SET_CONFIGURATION",
  ...sectionsActions,
  ...groupsActions,
  ...questionsActions,
  ...optionsActions,
  ...guidanceActions,
};

export default ACTIONS;
